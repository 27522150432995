import Types from './Types';

const INITIAL_STATE = {
    visits: [],
    visit: {},
    isLoading: false,
    count: ''
};

export default function plans(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_VISITS_BY_SPID_SUCCESS: {
            return {
                ...state,
                visits: payload?.message ? [] : payload.data?.data,
                count: payload?.message ? 0 : payload.data?.pagination?.total_records
            };
        }

        case Types.GET_VISIT_DETAILS_SUCCESS: {
            return {
                ...state,
                visit: payload
            };
        }

        case Types.CREATE_VISIT_SUCCESS: {
            return {
                ...state
            };
        }
        case Types.GET_VISIT_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        default: {
            return state;
        }
    }
}
