import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    Select,
    ModalCloseButton,
    ModalBody,
    Flex,
    Text,
    Button
} from '@chakra-ui/react';
import { closeAddPlanToUserlanModel } from '../../modules/models/Actions';
import { Formik, Field, Form } from 'formik';

import { getAllGovernmentsRequest } from '../../modules/governments/Actions';

import { addPlanToUserRequest, getAllPlansRequest } from '../../modules/plans/Actions';

const AddPlanToUser = ({ data, addEdit }) => {
    const model = useSelector((state) => state.model);
    const dispatch = useDispatch();
    const governments = useSelector((state) => state.governments.governments);
    const plans = useSelector((state) => state.plans.plans);
    const isLoading = useSelector((state) => state.books.isLoading);
    const [govID, setGovID] = useState('');
    useEffect(() => {
        dispatch(getAllGovernmentsRequest());
    }, []);

    useEffect(() => {
        if (data?.gov_id) setGovID(data?.gov_id);
    }, [data?.gov_id]);
    useEffect(() => {
        if (govID) dispatch(getAllPlansRequest(govID));
    }, [govID]);

    return (
        <Modal
            isOpen={model.isOpen16}
            onClose={() => dispatch(closeAddPlanToUserlanModel())}
            size="2xl"
            closeOnOverlayClick={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader padding="15px" bg="main_2" color={'white'}>
                    اضافة
                </ModalHeader>
                <ModalCloseButton
                    color={'white'}
                    right="90%"
                    onClick={() => dispatch(closeAddPlanToUserlanModel())}
                />
                <ModalBody>
                    <Formik
                        initialValues={{
                            id: ''
                        }}
                        onSubmit={(values) => {
                            const action = () => {
                                dispatch(closeAddPlanToUserlanModel());
                            };

                            dispatch(
                                addPlanToUserRequest(
                                    {
                                        id: values.id,
                                        formData: {
                                            sale_person: data
                                        }
                                    },
                                    action
                                )
                            );
                        }}>
                        {({ values, errors, touched, handleSubmit }) => (
                            <Form>
                                <Flex flexDirection="column" justify="space-between" width={'100%'}>
                                    <Flex flexDirection="column" w="100%">
                                        <label htmlFor="gov_id" style={{ marginBlock: '10px' }}>
                                            المحافظة
                                        </label>
                                        <Select
                                            onChange={(e) => setGovID(e.target.value)}
                                            dir="ltr"
                                            defaultValue={govID}>
                                            <option>selecte option ...</option>
                                            {governments?.map((government, index) => {
                                                return (
                                                    <option value={government?.id}>
                                                        {government?.name}
                                                    </option>
                                                );
                                            })}
                                        </Select>

                                        {errors.gov_id ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>

                                    <Flex flexDirection="column">
                                        <label htmlFor="id" style={{ marginBlock: '10px' }}>
                                            خط السير
                                        </label>
                                        <Field
                                            style={{
                                                border: '1px solid gray',
                                                padding: '5px',
                                                borderRadius: '5px',
                                                width: '100%',
                                                direction: 'ltr'
                                            }}
                                            component="select"
                                            id="id"
                                            name="id"
                                            multiple={false}>
                                            <option>selecte option ...</option>

                                            {plans?.map((plan, index) => {
                                                return (
                                                    <option value={plan?.id}>{plan?.name}</option>
                                                );
                                            })}
                                        </Field>

                                        {errors.id ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>

                                    <Button
                                        mt="20px"
                                        w="25%"
                                        type="submit"
                                        style={{ marginBlock: '10px' }}
                                        bg="main_2"
                                        isLoading={isLoading}
                                        alignSelf={'flex-end'}
                                        color="white">
                                        حفظ التغيرات
                                    </Button>
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default AddPlanToUser;
