import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllTeacherTypes({ payload }) {
    try {
        yield put(actions.addTeacherTypesLoading(true));
        const result = yield call(api.getAllTeacherTypes, payload);
        yield put(actions.getAllTeacherTypesSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        console.log(error, 'error');
        yield put(actions.getAllTeacherTypesFail());
    } finally {
        yield put(actions.addTeacherTypesLoading(false));
    }
}


// create
function* createTeacherTypes(formData) {
    try {
        yield put(actions.addTeacherTypesLoading(true));
        const result = yield call(api.createTeacherTypes, formData.payload);
        yield put(
            actions.createTeacherTypesSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.createTeacherTypesFail());
    } finally {
        yield put(actions.addTeacherTypesLoading(false));
    }
}

function* deleteTeacherTypes({ payload }) {
    try {
        yield put(actions.addTeacherTypesLoading(true));
        const result = yield call(api.deleteTeacherTypes, payload);

        yield put(actions.deleteTeacherTypesSuccess(payload));

        toast.success('Deleted Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteTeacherTypesFail());
    } finally {
        yield put(actions.addTeacherTypesLoading(false));
    }
}

export default function* TeachersSaga() {
    yield takeLatest(Types.GET_TEACHER_TYPE_REQUEST, getAllTeacherTypes);
    yield takeLatest(Types.CREATE_TEACHER_TYPE_REQUEST, createTeacherTypes);
    yield takeLatest(Types.DELETE_TEACHER_TYPE_REQUEST, deleteTeacherTypes);
}
