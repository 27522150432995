import axiosInstance from '../../helpers/api';

export const getAllTeachers = (payload) => {
    console.log(payload, 'payload')
    return axiosInstance.post(`/teachers/search?page=${payload.page}`, payload.search);
};



export const editTeachers = (formData) => {

    return axiosInstance.put(`/teachers/${formData.id}`, formData.formData);
};


export const createTeachers = (formData) => {
    return axiosInstance.post('/teachers', formData);
};

export const deleteTeacher = (id) => {
    return axiosInstance.delete(`/teachers/${id}`);
};
