import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllAreaVillages({ payload }) {
    try {
        yield put(actions.addAreaVillageLoading(true));
        const result = yield call(api.getAllAreaVillages, payload);
        yield put(actions.getAllAreaVillagesSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        console.log(error, 'error');
        yield put(actions.getAllAreaVillagesFail());
    } finally {
        yield put(actions.addAreaVillageLoading(false));
    }
}



//Edit Tables
function* editAreaVillage(data) {
    try {
        yield put(actions.addAreaVillageLoading(true));
        const result = yield call(api.editAreaVillages, data?.payload);
        yield put(actions.editAreaVillageSuccess(result.data));
        toast.success('Edit Successfully');
        data?.action && data.action()
    } catch (error) {
        yield put(actions.editAreaVillageFail());
    } finally {
        yield put(actions.addAreaVillageLoading(false));
    }
}

// create
function* createAreaVillage(formData) {
    try {
        yield put(actions.addAreaVillageLoading(true));
        const result = yield call(api.createAreaVillages, formData.payload);
        yield put(
            actions.createAreaVillageSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData.action()

    } catch (error) {
        yield put(actions.createAreaVillageFail());
    } finally {
        yield put(actions.addAreaVillageLoading(false));
    }
}

function* deleteAreaVillage({ payload }) {
    try {
        yield put(actions.addAreaVillageLoading(true));
        const result = yield call(api.deleteAreaVillage, payload);

        yield put(actions.deleteAreaVillageSuccess(payload));

        toast.success('Deleted Successfully');

    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteAreaVillageFail());
    } finally {
        yield put(actions.addAreaVillageLoading(false));
    }
}

export default function* areaVillageSaga() {
    yield takeLatest(Types.GET_AREAVILLAGES_REQUEST, getAllAreaVillages);
    yield takeLatest(Types.EDIT_AREAVILLAGE_REQUEST, editAreaVillage);
    yield takeLatest(Types.CREATE_AREAVILLAGE_REQUEST, createAreaVillage);
    yield takeLatest(Types.DELETE_AREAVILLAGE_REQUEST, deleteAreaVillage);
}
