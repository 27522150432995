import { extendTheme } from '@chakra-ui/react';

export const theme = extendTheme({
    colors: {
        transparent: 'transparent',
        gradient_main_color: 'linear-gradient(90deg, rgba(70,3,75,0.8522759445575105) 0%, rgba(60,175,191,1) 66%)',
        main_1: '#46034b',
        main_2: '#3cafbf',
        main_font: '#888f91',
    },
    fonts: {
        heading: 'sans-serif',
        body: 'sans-serif'
    }
});
