import * as Yup from 'yup';

const createSchoolsSchema = Yup.object().shape({

    name: Yup.string().min(2, 'Too Short!').max(70, 'Too Long!').required('Required'),
    address: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    gov_id: Yup.string(),
    area_vil: Yup.string().required('Required'),

});

export default createSchoolsSchema;
