import Types from './Types';


export const getAllCentersRequest = (id) => ({
    type: Types.GET_CENTERS_REQUEST,
    payload: id
});

export const getAllCentersSuccess = (data) => ({
    type: Types.GET_CENTERS_SUCCESS,
    payload: data
});

export const getAllCentersFail = () => ({
    type: Types.GET_CENTERS_FAIL
});


export const editCenterRequest = (formData, action) => ({
    type: Types.EDIT_CENTER_REQUEST,
    payload: formData,
    action: action
});

export const editCenterSuccess = (data) => ({
    type: Types.EDIT_CENTER_SUCCESS,
    payload: data
});

export const editCenterFail = () => ({
    type: Types.EDIT_CENTER_FAIL
});

//LOADING
export const addCenterLoading = (isLoading) => ({
    type: Types.ADD_CENTERS_LOADING,
    payload: isLoading
});

// Create Centers
export const createCenterRequest = (formData, action) => ({
    type: Types.CREATE_CENTER_REQUEST,
    payload: { ...formData },
    action: action
});

export const createCenterSuccess = ({ data }) => ({
    type: Types.CREATE_CENTER_SUCCESS,
    payload: {
        data
    }
});

export const createCenterFail = () => ({
    type: Types.CREATE_CENTER_FAIL
});

export const deleteCenterRequest = (id) => ({
    type: Types.DELETE_CENTER_REQUEST,
    payload: id
});

export const deleteCenterSuccess = (id) => ({
    type: Types.DELETE_CENTER_SUCCESS,
    payload: id
});

export const deleteCenterFail = () => ({
    type: Types.DELETE_CENTER_FAIL
});
