import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getVisitsBySPId(payload) {
    try {
        yield put(actions.getVisitsLoading(true));
        const result = yield call(api.getVistisDySPId, payload);
        yield put(actions.getVisitsBySPIdSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        console.log(error, 'error');
        yield put(actions.getVisitsBySPIdFail());
    } finally {
        yield put(actions.getVisitsLoading(false));
    }
}

function* getVisitDetails({ payload }) {
    try {
        yield put(actions.getVisitsLoading(true));
        const result = yield call(api.getVisitDetails, payload);
        yield put(actions.getVisitDetailsSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        yield put(actions.getVisitDetailsFail());
    } finally {
        yield put(actions.getVisitsLoading(false));
    }
}

function* createVisit({ payload }) {
    try {
        yield put(actions.getVisitsLoading(true));
        const result = yield call(api.createVisit, payload?.values);
        yield put(actions.createVisitSuccess(result.data));
        payload?.action && payload?.action();
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        yield put(actions.createVisitFail());
    } finally {
        yield put(actions.getVisitsLoading(false));
    }
}

export default function* visitsSaga() {
    yield takeLatest(Types.GET_VISITS_BY_SPID_REQUEST, getVisitsBySPId);
    yield takeLatest(Types.GET_VISIT_DETAILS_REQUEST, getVisitDetails);
    yield takeLatest(Types.CREATE_VISIT_REQUEST, createVisit);
}
