import axiosInstance from '../../helpers/api';

export const getAllBookstores = (data) => {
    return axiosInstance.get(`/bookstores/${data.id}?page=${data.page}`,);
};



export const editBookstores = (formData) => {

    return axiosInstance.put(`/bookstores/${formData.id}`, formData.formData);
};


export const createBookstores = (formData) => {
    return axiosInstance.post('/bookstores', formData);
};

export const deleteBookstore = (id) => {
    return axiosInstance.delete(`/bookstores/${id}`);
};
