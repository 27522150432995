import * as Yup from 'yup';

const createBookSchema = Yup.object().shape({

    title: Yup.string().min(2, 'Too Short!').max(70, 'Too Long!').required('Required'),
    price: Yup.number().required('Required'),
    ISBN: Yup.string(),
    author_id: Yup.string(),
    subject_id: Yup.string().required('Required'),
    grade_id: Yup.string().required('Required'),
    book_year: Yup.string(),
    max_discount: Yup.number(),
    reg_date: Yup.string(),
    prints_num: Yup.number()
});

export default createBookSchema;
