import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getUserData({ payload }) {
    try {
        yield put(actions.userDataLoading(true));
        const result = yield call(api.getUserData);
        yield put(actions.getUserDataSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        yield put(actions.getUserDataFail());
    } finally {
        yield put(actions.userDataLoading(false));
    }
}



export default function* userDataSaga() {
    yield takeLatest(Types.GET_USER_DATA_REQUEST, getUserData);

}
