import Types from './Types';

const INITIAL_STATE = {
    roles: [],
    users: [],
    user: {},
    isLoading: false,
    count: ''
};

export default function governments(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_ROLES_SUCCESS: {
            return {
                ...state,
                roles: payload.data
            };
        }

        case Types.GET_USERS_SUCCESS: {
            return {
                ...state,
                users: payload?.message ? [] : payload.data?.data,
                count: payload?.message ? 0 : payload.data?.pagination?.total_records
            };
        }

        case Types.GET_USER_SUCCESS: {
            return {
                ...state,
                user: payload
            };
        }

        case Types.EDIT_USER_SUCCESS: {
            let data = payload.data;
            const users = state.users;
            let idx = 0;
            users.forEach((table, index) => {
                if (table.id === data.id) {
                    idx = index;
                    return;
                }
            });

            users[idx] = data;
            return {
                ...state,
                users: users
            };
        }

        // create
        case Types.CREATE_USER_SUCCESS: {
            const data = payload.data.data;
            return {
                ...state,
                users: state.users.concat(data)
            };
        }
        case Types.CREATE_SALESPERSON_SUCCESS: {
            const data = payload.data.data;
            return {
                ...state,
                users: state.users.concat(data)
            };
        }
        case Types.ADD_USERS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        case Types.DELETE_USER_SUCCESS: {
            const newUsers = state.users.filter((item) => item.id !== payload);
            return {
                ...state,
                users: newUsers
            };
        }
        default: {
            return state;
        }
    }
}
