import axiosInstance from '../../helpers/api';

export const getAllTeacherTypes = () => {

    return axiosInstance.get(`/teacher-type`,);
};




export const createTeacherTypes = (formData) => {
    return axiosInstance.post('/teacher-type', formData);
};

export const deleteTeacherTypes = (id) => {
    return axiosInstance.delete(`/teacher-type/${id}`);
};
