import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllTeachers(payload) {
    try {
        yield put(actions.addTeacherLoading(true));
        const result = yield call(api.getAllTeachers, payload);
        yield put(actions.getAllTeachersSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        console.log(error, 'error');
        yield put(actions.getAllTeachersFail());
    } finally {
        yield put(actions.addTeacherLoading(false));
    }
}



//Edit Tables
function* editTeacher(formData) {
    try {
        yield put(actions.addTeacherLoading(true));
        const result = yield call(api.editTeachers, formData.payload);
        yield put(actions.editTeacherSuccess(result.data));
        toast.success('Edit Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.editTeacherFail());
    } finally {
        yield put(actions.addTeacherLoading(false));
    }
}

// create
function* createTeacher(formData) {
    try {
        yield put(actions.addTeacherLoading(true));
        const result = yield call(api.createTeachers, formData.payload);
        yield put(
            actions.createTeacherSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.createTeacherFail());
    } finally {
        yield put(actions.addTeacherLoading(false));
    }
}

function* deleteTeacher({ payload }) {
    try {
        yield put(actions.addTeacherLoading(true));
        const result = yield call(api.deleteTeacher, payload);

        yield put(actions.deleteTeacherSuccess(payload));

        toast.success('Deleted Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteTeacherFail());
    } finally {
        yield put(actions.addTeacherLoading(false));
    }
}

export default function* TeachersSaga() {
    yield takeLatest(Types.GET_TEACHERS_REQUEST, getAllTeachers);
    yield takeLatest(Types.EDIT_TEACHER_REQUEST, editTeacher);
    yield takeLatest(Types.CREATE_TEACHER_REQUEST, createTeacher);
    yield takeLatest(Types.DELETE_TEACHER_REQUEST, deleteTeacher);
}
