import React from 'react';
import Dashboard from '../../pages/home';
import ProtectedRoute from '../Auth/ProtectedRouter';
import Governments from '../../pages/governments';
import Municipality from '../../pages/municipality';
import Grades from '../../pages/grade';
import Subjects from '../../pages/subjects';
import Authors from '../../pages/authors';
import SalePersons from '../../pages/users/salesPersons';
import Moderators from '../../pages/users/moderators';
import Books from '../../pages/books';
import AreaVillages from '../../pages/areaVillage';
import Schools from '../../pages/school';
import Bookstores from '../../pages/bookstores';
import Wholesalers from '../../pages/wholesalers';
import Teachers from '../../pages/teachers';
import Centers from '../../pages/centers';
import Plans from '../../pages/plans';
import Visits from '../../pages/visits';
import TeacherType from '../../pages/teacher-types';
import SalesmanVisitis from '../../pages/salesmanVisitis';
const UserLayout = () => {
    return (
        <>
            {/* //dashboard */}

            <ProtectedRoute exact path="/areaVillages" component={AreaVillages}></ProtectedRoute>
            <ProtectedRoute exact path="/schools" component={Schools}></ProtectedRoute>
            <ProtectedRoute exact path="/bookstores" component={Bookstores}></ProtectedRoute>
            <ProtectedRoute exact path="/teachers" component={Teachers}></ProtectedRoute>
            <ProtectedRoute exact path="/teacher-type" component={TeacherType}></ProtectedRoute>

            <ProtectedRoute exact path="/centers" component={Centers}></ProtectedRoute>
            <ProtectedRoute exact path="/plans" component={Plans}></ProtectedRoute>
            <ProtectedRoute exact path="/salesPerson" component={SalesmanVisitis}></ProtectedRoute>

            <ProtectedRoute exact path="/visits" component={Visits}></ProtectedRoute>
            <ProtectedRoute exact path="/wholesallers" component={Wholesalers}></ProtectedRoute>

            <ProtectedRoute exact path="/books" component={Books}></ProtectedRoute>
            <ProtectedRoute
                exact
                path="/users/salePersons"
                component={SalePersons}></ProtectedRoute>
            <ProtectedRoute exact path="/users/moderators" component={Moderators}></ProtectedRoute>
            <ProtectedRoute exact path="/authors" component={Authors}></ProtectedRoute>
            <ProtectedRoute exact path="/subjects" component={Subjects}></ProtectedRoute>
            <ProtectedRoute exact path="/grades" component={Grades}></ProtectedRoute>
            <ProtectedRoute exact path="/municipality" component={Municipality}></ProtectedRoute>
            <ProtectedRoute exact path="/governments" component={Governments}></ProtectedRoute>
            <ProtectedRoute exact path="/" component={Dashboard}></ProtectedRoute>
            {/* <ProtectedRoute path="*" component={ErrorPage}></ProtectedRoute> */}
        </>
    );
};

export default UserLayout;
