import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DashboardLayout from '../../components/Layout/DashboardLayout';
import { openCreateGovernmentModel } from '../../modules/models/Actions';
import { useSelector } from 'react-redux';
import {
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuItemOption,
    MenuList,
    MenuOptionGroup
} from '@chakra-ui/react';
import CTable from '../../components/table/table';
import { HiDotsVertical } from 'react-icons/hi';
import { RiDeleteBin6Line, RiFileEditLine } from 'react-icons/ri';
import CreateGovernment from '../../components/models/createGovernment';
import {
    deleteGovernmentRequest,
    getAllGovernmentsRequest
} from '../../modules/governments/Actions';
import AuthWrapper from '../../components/Auth/AuthWrapper';

const Governments = () => {
    const dispatch = useDispatch();
    const [addEdit, setAddEdit] = useState(true);
    const governments = useSelector((state) => state.governments.governments);
    const isLoading = useSelector((state) => state.governments.isLoading);
    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(100);
    const [pageNumber, setPageNumber] = useState(0);
    const [selected, setSelected] = useState({
        id: ''
    });

    useEffect(() => {
        dispatch(getAllGovernmentsRequest());
    }, []);

    const Data = React.useMemo(() => [
        governments?.map((data) => {
            return {
                ...data,
                id: data?.id,
                name: data?.name
            };
        })
    ]);

    const actions = (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HiDotsVertical />}
                size="sm"
                fontSize="20px"
                variant="outline"
                border="none"
            />
            <MenuList>
                <MenuItem
                    icon={<RiFileEditLine />}
                    onClick={() => {
                        setAddEdit(false);
                        dispatch(openCreateGovernmentModel());
                    }}>
                    تعديل
                </MenuItem>
                <MenuItem
                    onClick={() => dispatch(deleteGovernmentRequest(selected?.id))}
                    icon={<RiDeleteBin6Line />}>
                    حذف
                </MenuItem>
            </MenuList>
        </Menu>
    );
    const columns = [
        {
            Header: 'المحافظة',
            accessor: 'name'
        },
        {
            Header: '',
            accessor: 'Actions'
        }
    ];

    const handelFilter = (e) => {};
    const viewData = (data) => {
        setSelected(data);
    };

    const filterList = (
        <MenuOptionGroup type="checkbox" onChange={(e) => handelFilter(e)}>
            <MenuItemOption value="2"></MenuItemOption>
            <MenuItemOption value="3"></MenuItemOption>
        </MenuOptionGroup>
    );
    return (
        <AuthWrapper roles={['Super Admin']}>
            <DashboardLayout title={'المحافظات'}>
                <CTable
                    selectedData={viewData}
                    Columns={columns}
                    Data={Data[0]}
                    path={openCreateGovernmentModel}
                    Actions={actions}
                    Title=" "
                    subTitle={``}
                    btnTitle="hi"
                    placeHolder=""
                    noSearchBar={true}
                    noFilter={true}
                    footerBtnTitle
                    filterList
                    addButton
                    addButtonTitle="اضافة  "
                    setPage={setPage}
                    setPerPage={setPerPage}
                    currentpage={pageNumber}
                    setPageNumber={setPageNumber}
                    noSecondSearchBar={true}
                    isLoading={isLoading}
                    perPage={size}
                    totalPage={1}
                    setAddEdit={setAddEdit}
                />

                <CreateGovernment data={selected} addEdit={addEdit} />
            </DashboardLayout>
        </AuthWrapper>
    );
};

export default Governments;
