import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    Icon,
    ModalCloseButton,
    ModalBody,
    Flex,
    Select,
    Input,
    Box,
    Button
} from '@chakra-ui/react';
import { closeCreateMunicipalityModel } from '../../../modules/models/Actions';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { AiFillMinusCircle } from 'react-icons/ai';
import { createMunicipalityRequest } from '../../../modules/municipality/Actions';
import { getAllGovernmentsRequest } from '../../../modules/governments/Actions';
import { toast } from 'react-toastify';
const CreateMunicipality = () => {
    const model = useSelector((state) => state.model);
    const dispatch = useDispatch();
    const [fieldsCount, setFieldsCount] = useState(0);
    const [data, setData] = useState([]);
    const [govID, setGovID] = useState('');

    const governments = useSelector((state) => state.governments.governments);
    const isLoading = useSelector((state) => state.municipalitys.isLoading);

    useEffect(() => {
        dispatch(getAllGovernmentsRequest());
    }, []);
    const handelCreateMunicipality = () => {
        const action = () => {
            dispatch(closeCreateMunicipalityModel());
        };
        if (govID) {
            dispatch(
                createMunicipalityRequest(
                    {
                        formData: { municipalities: data },
                        id: govID
                    },
                    action
                )
            );
        } else {
            toast.error('من فضلك اختر المحافظة');
        }
    };
    return (
        <Modal
            isOpen={model.isOpen2}
            onClose={() => dispatch(closeCreateMunicipalityModel())}
            size="2xl"
            closeOnOverlayClick={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader padding="15px" bg="main_2" color={'white'}>
                    اضافة
                </ModalHeader>
                <ModalCloseButton
                    color={'white'}
                    right="90%"
                    onClick={() => dispatch(closeCreateMunicipalityModel())}
                />
                <ModalBody>
                    <Flex flexDirection="column" justify="space-between" width={'100%'}>
                        <Box display="flex" flexDirection="column" width={'100%'} mb="20px">
                            <Flex flexDirection="column">
                                <label htmlFor="gov_id" style={{ marginBlock: '10px' }}>
                                    المحافظة
                                </label>
                                <Select
                                    placeholder="اختار المحافظة"
                                    dir="ltr"
                                    onChange={(e) => setGovID(e.target.value)}>
                                    {governments?.map((government, index) => {
                                        return (
                                            <option value={government?.id}>
                                                {government?.name}
                                            </option>
                                        );
                                    })}
                                </Select>
                            </Flex>
                            <label htmlFor="name" style={{ marginBlock: '10px' }}>
                                الادارة
                            </label>
                            <Flex my="5px" alignItems={'center'} justifyContent={'center'}>
                                <Input
                                    onChange={(e) => {
                                        let newData = data;
                                        newData[0] = { name: e.target.value };
                                        setData(newData);
                                    }}
                                    style={{
                                        width: '100%',
                                        border: '1px solid #8080803b',
                                        padding: '5px',
                                        borderRadius: '5px'
                                    }}
                                />
                                <Icon
                                    as={BsFillPlusCircleFill}
                                    mx={'10px'}
                                    color="main_2"
                                    fontSize={'18px'}
                                    cursor="pointer"
                                    onClick={() => setFieldsCount(fieldsCount + 1)}
                                />
                            </Flex>

                            {[...Array(fieldsCount)].map((val, index) => {
                                return (
                                    <Flex my="5px" alignItems={'center'} justifyContent={'center'}>
                                        <Input
                                            onChange={(e) => {
                                                let newData = data;
                                                let newIndex = index + 1;
                                                newData[newIndex] = { name: e.target.value };
                                                setData(newData);
                                            }}
                                            style={{
                                                width: '100%',
                                                border: '1px solid #8080803b',
                                                padding: '5px',
                                                borderRadius: '5px'
                                            }}
                                        />

                                        <Icon
                                            as={AiFillMinusCircle}
                                            mx={'10px'}
                                            color="main_2"
                                            fontSize={'22px'}
                                            cursor="pointer"
                                            onClick={() => {
                                                setFieldsCount(fieldsCount - 1);
                                                let newData = data;
                                                const filteredData = newData.filter(
                                                    (val, idx) => idx != index + 1
                                                );
                                                setData(filteredData);
                                            }}
                                        />
                                    </Flex>
                                );
                            })}
                        </Box>

                        <Button
                            onClick={handelCreateMunicipality}
                            mt="20px"
                            w="25%"
                            type="submit"
                            isLoading={isLoading}
                            style={{ marginBlock: '10px' }}
                            bg="main_2"
                            alignSelf={'flex-end'}
                            color="white">
                            حفظ التغيرات
                        </Button>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CreateMunicipality;
