import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DashboardLayout from '../../components/Layout/DashboardLayout';
import { openCreatePlanModel } from '../../modules/models/Actions';
import { useSelector } from 'react-redux';
import {
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Text
} from '@chakra-ui/react';
import CTable from '../../components/table/table';
import { HiDotsVertical } from 'react-icons/hi';
import { RiDeleteBin6Line, RiFileEditLine } from 'react-icons/ri';

import AuthWrapper from '../../components/Auth/AuthWrapper';

import { getAllGovernmentsRequest } from '../../modules/governments/Actions';
import {
    deletePlanRequest,
    getAllPlansRequest,
    getPlansRequest
} from '../../modules/plans/Actions';
import CreatePlan from '../../components/models/createPlan';

const Plans = () => {
    const dispatch = useDispatch();
    const [addEdit, setAddEdit] = useState(true);
    const plans = useSelector((state) => state.plans.plans);
    const governments = useSelector((state) => state.governments.governments);
    const userData = useSelector((state) => state.userData.userData);

    const isLoading = useSelector((state) => state.plans.isLoading);
    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [govID, setGovID] = useState('');

    const [selected, setSelected] = useState({
        id: ''
    });
    useEffect(() => {
        if (governments) setGovID(governments[0]?.id);
    }, [governments]);

    useEffect(() => {
        if (govID) dispatch(getAllPlansRequest(govID));
    }, [govID]);

    useEffect(() => {
        dispatch(getAllGovernmentsRequest());
        dispatch(getPlansRequest());
    }, []);
    const Data = plans?.map((data) => {
        return {
            ...data,
            id: data?.id,
            name: data?.name,
            gov_id: data?.areas?.[0]?.gov_id,
            gov_name: data?.governorate,
            muni_name: data?.municipality,
            muni_id: data?.areas?.[0]?.muni_id,
            areas: data?.areas?.map((val) => {
                return {
                    value: val?.id,
                    label: val?.name
                };
            }),
            salePerson: data?.salePerson?.map((val) => {
                return {
                    value: val?.id,
                    label: val?.name
                };
            }),
            areasData:
                data?.areas?.length == 0 ? (
                    <Text fontSize={'12px'} color="gray">
                        لا توجد مدن/قري لهذا الخط
                    </Text>
                ) : (
                    data?.areas?.map((val) => {
                        return <Text>{val?.name}</Text>;
                    })
                ),
            salePersonData:
                data?.salePerson?.length == 0 ? (
                    <Text fontSize={'12px'} color="gray">
                        لا يوجد مندوبين لهذا الخط
                    </Text>
                ) : (
                    data?.salePerson?.map((val) => {
                        return <Text>{val?.name}</Text>;
                    })
                )
        };
    });

    const actions = (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HiDotsVertical />}
                size="sm"
                fontSize="20px"
                variant="outline"
                border="none"
            />
            <MenuList>
                <MenuItem
                    icon={<RiFileEditLine />}
                    onClick={() => {
                        setAddEdit(false);
                        dispatch(openCreatePlanModel());
                    }}>
                    تعديل
                </MenuItem>
                <MenuItem
                    onClick={() => dispatch(deletePlanRequest(selected?.id))}
                    icon={<RiDeleteBin6Line />}>
                    حذف
                </MenuItem>
            </MenuList>
        </Menu>
    );
    const columns = [
        {
            Header: 'اسم الخط',
            accessor: 'name'
        },
        {
            Header: 'القري/المدن',
            accessor: 'areasData'
        },
        {
            Header: 'مندوبي الخط',
            accessor: 'salePersonData'
        },

        {
            Header: '',
            accessor: 'Actions'
        }
    ];

    const handelFilter = (e) => {
        console.log(e);
        setGovID(e);
    };
    const viewData = (data) => {
        setSelected(data);
    };

    const filterList = (
        <MenuOptionGroup type="radio" value={govID}>
            {governments.map((government) => {
                return (
                    <MenuItemOption
                        onClick={() => handelFilter(government?.id)}
                        value={government?.id}>
                        {government?.name}
                    </MenuItemOption>
                );
            })}
        </MenuOptionGroup>
    );
    return (
        <AuthWrapper roles={['Super Admin', 'Moderator', 'Sale Person']}>
            <DashboardLayout title={' خطوط السير'}>
                <CTable
                    selectedData={viewData}
                    Columns={columns}
                    Data={Data}
                    path={openCreatePlanModel}
                    Actions={actions}
                    Title=" "
                    subTitle={``}
                    btnTitle="hi"
                    placeHolder=""
                    noSearchBar={true}
                    noFilter={false}
                    footerBtnTitle
                    filterList={filterList}
                    addButton={
                        userData?.role?.[0]?.name != 'Sale Person' &&
                        userData?.role?.[0]?.name != 'Super Admin'
                    }
                    addButtonTitle="اضافة  "
                    setPage={setPage}
                    setPerPage={setPerPage}
                    currentpage={pageNumber}
                    setPageNumber={setPageNumber}
                    noSecondSearchBar={true}
                    isLoading={isLoading}
                    perPage={size}
                    totalPage={Math.ceil(plans?.length / 100)}
                    setAddEdit={setAddEdit}
                />

                <CreatePlan data={selected} addEdit={addEdit} />
            </DashboardLayout>
        </AuthWrapper>
    );
};

export default Plans;
