import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Flex,
    Text,
    Box,
    Button
} from '@chakra-ui/react';
import { closeCreateUserModel } from '../../modules/models/Actions';
import { Formik, Field, Form } from 'formik';
import createUserSchema from '../Schemas/createUser';
import Select from 'react-select';

import {
    createSalePersonRequest,
    createUserRequest,
    editUserRequest
} from '../../modules/users/Actions';
import { getAllGovernmentsRequest } from '../../modules/governments/Actions';

const CreateUser = ({ data, addEdit, role }) => {
    const model = useSelector((state) => state.model);
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.users.isLoading);
    const governments = useSelector((state) => state.governments.governments);

    useEffect(() => {
        role == 'Sale Person' && dispatch(getAllGovernmentsRequest());
    }, [role]);
    const govOptions = governments?.map((data) => {
        return {
            label: data?.name,
            value: data?.id
        };
    });
    return (
        <Modal
            isOpen={model.isOpen7}
            onClose={() => dispatch(closeCreateUserModel())}
            size="2xl"
            closeOnOverlayClick={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader padding="15px" bg="main_2" color={'white'}>
                    {addEdit ? 'اضافة' : 'تعديل'}
                </ModalHeader>
                <ModalCloseButton
                    color={'white'}
                    right="90%"
                    onClick={() => dispatch(closeCreateUserModel())}
                />
                <ModalBody>
                    <Formik
                        validationSchema={createUserSchema}
                        initialValues={
                            !addEdit
                                ? { ...data }
                                : {
                                      name: '',
                                      user_name: '',
                                      email: '',
                                      password: '',
                                      phone: '',
                                      role: role
                                  }
                        }
                        onSubmit={(values) => {
                            const action = () => {
                                dispatch(closeCreateUserModel());
                            };
                            if (addEdit) {
                                role == 'Sale Person'
                                    ? dispatch(createSalePersonRequest(values, action))
                                    : dispatch(createUserRequest(values, action));
                            } else {
                                const formData = {
                                    ...values,
                                    role: role
                                };

                                dispatch(editUserRequest(data?.id, formData, action));
                            }
                        }}>
                        {({ values, errors, touched, handleSubmit, setValues }) => (
                            <Form>
                                <Flex flexDirection="column" justify="space-between" width={'100%'}>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        width={'100%'}
                                        mb="20px">
                                        <label htmlFor="name" style={{ marginBlock: '10px' }}>
                                            الاسم
                                        </label>
                                        <Field
                                            id="name"
                                            name="name"
                                            placeholder=""
                                            style={{
                                                width: '100%',
                                                border: '1px solid #8080803b',
                                                padding: '5px',
                                                borderRadius: '5px'
                                            }}
                                        />
                                        {errors.name ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Box>

                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        width={'100%'}
                                        mb="20px">
                                        <label htmlFor="phone" style={{ marginBlock: '10px' }}>
                                            رقم الهاتف
                                        </label>
                                        <Field
                                            id="phone"
                                            name="phone"
                                            placeholder=""
                                            style={{
                                                width: '100%',
                                                border: '1px solid #8080803b',
                                                padding: '5px',
                                                borderRadius: '5px'
                                            }}
                                        />
                                        {errors.phone ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Box>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        width={'100%'}
                                        mb="20px">
                                        <label htmlFor="user_name" style={{ marginBlock: '10px' }}>
                                            اسم المستخدم
                                        </label>
                                        <Field
                                            id="user_name"
                                            name="user_name"
                                            placeholder=""
                                            style={{
                                                width: '100%',
                                                border: '1px solid #8080803b',
                                                padding: '5px',
                                                borderRadius: '5px'
                                            }}
                                        />
                                        {errors.user_name ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Box>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        width={'100%'}
                                        mb="20px">
                                        <label htmlFor="user_name" style={{ marginBlock: '10px' }}>
                                            كلمة المرور
                                        </label>
                                        <Field
                                            style={{
                                                width: '100%',
                                                border: '1px solid #8080803b',
                                                padding: '5px',
                                                borderRadius: '5px'
                                            }}
                                            name="password"
                                        />
                                        {errors.password ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Box>

                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        width={'100%'}
                                        mb="20px">
                                        <label htmlFor="email" style={{ marginBlock: '10px' }}>
                                            الايميل
                                        </label>
                                        <Field
                                            id="email"
                                            name="email"
                                            placeholder=""
                                            style={{
                                                width: '100%',
                                                border: '1px solid #8080803b',
                                                padding: '5px',
                                                borderRadius: '5px'
                                            }}
                                        />
                                        {errors.email ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Box>

                                    {role == 'Sale Person' && (
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width={'100%'}
                                            mb="20px">
                                            <label htmlFor="nat_id" style={{ marginBlock: '10px' }}>
                                                الرقم القومي
                                            </label>
                                            <Field
                                                id="nat_id"
                                                name="nat_id"
                                                placeholder=""
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #8080803b',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                            {errors.nat_id ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Box>
                                    )}
                                    {role == 'Sale Person' && (
                                        <Flex flexDirection="column" w="100%">
                                            <label htmlFor="gov_id" style={{ marginBlock: '10px' }}>
                                                المحافظة
                                            </label>
                                            <Select
                                                options={govOptions}
                                                defaultValue={{
                                                    value: values?.gov_id,
                                                    label: values?.gov
                                                }}
                                                name="gov_id"
                                                onChange={(val) => {
                                                    setValues({ ...values, gov_id: val?.value });
                                                }}
                                            />

                                            {errors.gov_id ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Flex>
                                    )}
                                    <Button
                                        mt="20px"
                                        w="25%"
                                        type="submit"
                                        style={{ marginBlock: '10px' }}
                                        bg="main_2"
                                        isLoading={isLoading}
                                        alignSelf={'flex-end'}
                                        color="white">
                                        حفظ التغيرات
                                    </Button>
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CreateUser;
