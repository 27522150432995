import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Flex,
    Text,
    Box,
    Button
} from '@chakra-ui/react';
import { Formik, Field, Form } from 'formik';
import { closeEditMunicipalityModel } from '../../../modules/models/Actions';
import { editMunicipalityRequest } from '../../../modules/municipality/Actions';
import createMunicipalitySchema from '../../Schemas/createMunicipality';
import { getAllGovernmentsRequest } from '../../../modules/governments/Actions';

const EditMunicipality = ({ data, addEdit }) => {
    const model = useSelector((state) => state.model);
    const dispatch = useDispatch();

    const governments = useSelector((state) => state.governments.governments);
    const isLoading = useSelector((state) => state.municipalitys.isLoading);

    useEffect(() => {
        dispatch(getAllGovernmentsRequest());
    }, []);

    return (
        <Modal
            isOpen={model.isOpen3}
            onClose={() => dispatch(closeEditMunicipalityModel())}
            size="2xl"
            closeOnOverlayClick={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader padding="15px" bg="main_2" color={'white'}>
                    تعديل
                </ModalHeader>
                <ModalCloseButton
                    color={'white'}
                    right="90%"
                    onClick={() => dispatch(closeEditMunicipalityModel())}
                />
                <ModalBody>
                    <Formik
                        validationSchema={createMunicipalitySchema}
                        initialValues={
                            !addEdit
                                ? { ...data }
                                : {
                                      id: '',
                                      name: '',
                                      gov_id: ''
                                  }
                        }
                        onSubmit={(values) => {
                            const action = () => {
                                dispatch(closeEditMunicipalityModel());
                            };
                            dispatch(
                                editMunicipalityRequest(
                                    { formData: { name: values.name }, id: values.id },
                                    action
                                )
                            );
                        }}>
                        {({ values, errors, touched, handleSubmit }) => (
                            <Form>
                                <Flex flexDirection="column" justify="space-between" width={'100%'}>
                                    <Flex flexDirection="column">
                                        <label htmlFor="gov_id" style={{ marginBlock: '10px' }}>
                                            المحافظة
                                        </label>
                                        <Field
                                            style={{
                                                border: '1px solid gray',
                                                padding: '5px',
                                                borderRadius: '5px',
                                                width: '100%'
                                            }}
                                            component="select"
                                            id="gov_id"
                                            name="gov_id"
                                            multiple={false}>
                                            <option>selecte option ...</option>

                                            {governments?.map((government, index) => {
                                                return (
                                                    <option value={government?.id}>
                                                        {government?.name}
                                                    </option>
                                                );
                                            })}
                                        </Field>

                                        {errors.gov_id ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        width={'100%'}
                                        mb="20px">
                                        <label htmlFor="name" style={{ marginBlock: '10px' }}>
                                            الادارة
                                        </label>
                                        <Field
                                            id="name"
                                            name="name"
                                            placeholder=""
                                            style={{
                                                width: '100%',
                                                border: '1px solid #8080803b',
                                                padding: '5px',
                                                borderRadius: '5px'
                                            }}
                                        />
                                    </Box>
                                    <Button
                                        mt="20px"
                                        w="25%"
                                        type="submit"
                                        isLoading={isLoading}
                                        style={{ marginBlock: '10px' }}
                                        bg="main_2"
                                        alignSelf={'flex-end'}
                                        color="white">
                                        حفظ التغيرات
                                    </Button>
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default EditMunicipality;
