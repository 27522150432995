import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DashboardLayout from '../../components/Layout/DashboardLayout';
import { openCreateCenterModel } from '../../modules/models/Actions';
import { useSelector } from 'react-redux';
import {
    Button,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuItemOption,
    MenuList,
    MenuOptionGroup
} from '@chakra-ui/react';
import CTable from '../../components/table/table';
import { HiDotsVertical } from 'react-icons/hi';
import { RiDeleteBin6Line, RiFileEditLine } from 'react-icons/ri';
import AuthWrapper from '../../components/Auth/AuthWrapper';
import { getAllGovernmentsRequest } from '../../modules/governments/Actions';
import GovList from '../../components/UI/govList';

import { deleteCenterRequest, getAllCentersRequest } from '../../modules/centers/Actions';
import CreateCenter from '../../components/models/createCenter';

const Centers = () => {
    const dispatch = useDispatch();
    const [addEdit, setAddEdit] = useState(true);
    const centers = useSelector((state) => state.centers.centers);
    const governments = useSelector((state) => state.governments.governments);

    const isLoading = useSelector((state) => state.centers.isLoading);
    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(100);
    const [pageNumber, setPageNumber] = useState(0);
    const [govID, setGovID] = useState('');
    const [step, setStep] = useState(true);
    const [selected, setSelected] = useState({
        id: ''
    });
    useEffect(() => {
        if (governments) setGovID(governments[0]?.id);
    }, [governments]);

    useEffect(() => {
        if (govID) dispatch(getAllCentersRequest(govID));
    }, [govID]);

    useEffect(() => {
        dispatch(getAllGovernmentsRequest());
    }, []);
    const Data = centers?.map((data) => {
        return {
            ...data,
            id: data?.id,
            name: data?.name,
            gov_id: data?.areas?.gov_id,
            area_vil: data?.areas?.id,
            area_name: data?.areas?.name,
            muni_id: data?.areas?.muni_id,
            muni_name: data?.areas?.name
        };
    });

    const actions = (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HiDotsVertical />}
                size="sm"
                fontSize="20px"
                variant="outline"
                border="none"
            />
            <MenuList>
                <MenuItem
                    icon={<RiFileEditLine />}
                    onClick={() => {
                        setAddEdit(false);
                        dispatch(openCreateCenterModel());
                    }}>
                    تعديل
                </MenuItem>
                <MenuItem
                    onClick={() => dispatch(deleteCenterRequest(selected?.id))}
                    icon={<RiDeleteBin6Line />}>
                    حذف
                </MenuItem>
            </MenuList>
        </Menu>
    );
    const columns = [
        {
            Header: 'الاسم',
            accessor: 'name'
        },
        {
            Header: 'اسم صاحب المكتبة',
            accessor: 'owner_name'
        },

        {
            Header: 'الهاتف',
            accessor: 'phone'
        },
        {
            Header: 'المحافظة',
            accessor: 'gov'
        },
        {
            Header: 'قرية/مدينة',
            accessor: 'area_name'
        },
        {
            Header: '',
            accessor: 'Actions'
        }
    ];

    const handelFilter = (e) => {
        console.log(e);
        setGovID(e);
    };
    const viewData = (data) => {
        setSelected(data);
    };

    const filterList = (
        <MenuOptionGroup type="radio" value={govID}>
            {governments.map((government) => {
                return (
                    <MenuItemOption
                        onClick={() => handelFilter(government?.id)}
                        value={government?.id}>
                        {government?.name}
                    </MenuItemOption>
                );
            })}
        </MenuOptionGroup>
    );
    return (
        <AuthWrapper roles={['Super Admin']}>
            <DashboardLayout title={' سناتر'}>
                {step ? (
                    <GovList isLoading={isLoading} setStep={setStep} setGovID={setGovID} />
                ) : (
                    <>
                        <Button left="2" position={'fixed'} onClick={() => setStep(true)}>
                            الرجوع للمحافظات
                        </Button>
                        <CTable
                            selectedData={viewData}
                            Columns={columns}
                            Data={Data}
                            path={openCreateCenterModel}
                            Actions={actions}
                            Title=" "
                            subTitle={``}
                            btnTitle="hi"
                            placeHolder=""
                            noSearchBar={true}
                            noFilter={false}
                            footerBtnTitle
                            filterList={filterList}
                            addButton
                            addButtonTitle="اضافة  "
                            setPage={setPage}
                            setPerPage={setPerPage}
                            currentpage={pageNumber}
                            setPageNumber={setPageNumber}
                            noSecondSearchBar={true}
                            isLoading={isLoading}
                            perPage={size}
                            totalPage={Math.ceil(centers?.length / 100)}
                            setAddEdit={setAddEdit}
                        />
                    </>
                )}
                <CreateCenter data={selected} addEdit={addEdit} />
            </DashboardLayout>
        </AuthWrapper>
    );
};

export default Centers;
