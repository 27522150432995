import Types from './Types';

const INITIAL_STATE = {
    areaVillages: [],
    areaVillage: {},
    isLoading: false,
    count: ''
};

export default function areaVillages(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_AREAVILLAGES_SUCCESS: {
            console.log(payload, 'payload')
            return {
                ...state,
                areaVillages: payload?.message ? [] : payload.data?.data,
                count: payload?.message ? 0 : payload.data.pagination.total_records
            };
        }

        case Types.GET_AREAVILLAGE_SUCCESS: {
            return {
                ...state,
                areaVillage: payload
            };
        }

        case Types.EDIT_AREAVILLAGE_SUCCESS: {
            let data = payload.data;
            const areaVillages = state.areaVillages;
            let idx = 0;
            areaVillages.forEach((table, index) => {
                if (table.id === data.id) {
                    idx = index;
                    return;
                }
            });

            areaVillages[idx] = data;
            return {
                ...state,
                areaVillages: areaVillages
            };
        }

        // create
        case Types.CREATE_AREAVILLAGE_SUCCESS: {
            const data = payload.data.data;
            let newArray = state.areaVillages.slice();
            let index = state.areaVillages.length + 1;
            newArray.splice(index, 0, data);
            return {
                ...state,
                areaVillages: newArray
            };
        }
        case Types.ADD_AREAVILLAGES_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        case Types.DELETE_AREAVILLAGE_SUCCESS: {
            const newAreaVillages = state.areaVillages.filter((item) => item.id !== payload);
            return {
                ...state,
                areaVillages: newAreaVillages
            };
        }
        default: {
            return state;
        }
    }
}
