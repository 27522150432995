export default {

    GET_MUNICIPALITYS_REQUEST: 'GET_MUNICIPALITYS_REQUEST',
    GET_MUNICIPALITYS_SUCCESS: 'GET_MUNICIPALITYS_SUCCESS',
    GET_MUNICIPALITYS_FAIL: 'GET_MUNICIPALITYS_FAIL',

    GET_MUNICIPALITYS_BY_GOV_ID_REQUEST: 'GET_MUNICIPALITYS_BY_GOV_ID_REQUEST',
    GET_MUNICIPALITYS_BY_GOV_ID_SUCCESS: 'GET_MUNICIPALITYS_BY_GOV_ID_SUCCESS',
    GET_MUNICIPALITYS_BY_GOV_ID_FAIL: 'GET_MUNICIPALITYS_BY_GOV_ID_FAIL',


    EDIT_MUNICIPALITY_REQUEST: 'EDIT_MUNICIPALITY_REQUEST',
    EDIT_MUNICIPALITY_SUCCESS: 'EDIT_MUNICIPALITY_SUCCESS',
    EDIT_MUNICIPALITY_FAIL: 'EDIT_MUNICIPALITY_FAIL',


    CREATE_MUNICIPALITY_REQUEST: 'CREATE_MUNICIPALITY_REQUEST',
    CREATE_MUNICIPALITY_SUCCESS: 'CREATE_MUNICIPALITY_SUCCESS',
    CREATE_MUNICIPALITY_FAIL: 'CREATE_MUNICIPALITY_FAIL',

    DELETE_MUNICIPALITY_REQUEST: 'DELETE_MUNICIPALITY_REQUEST',
    DELETE_MUNICIPALITY_SUCCESS: 'DELETE_MUNICIPALITY_SUCCESS',
    DELETE_MUNICIPALITY_FAIL: 'DELETE_MUNICIPALITY_FAIL',

    GET_MUNICIPALITY_REQUEST: 'GET_MUNICIPALITY_REQUEST',
    GET_MUNICIPALITY_SUCCESS: 'GET_MUNICIPALITY_SUCCESS',
    GET_MUNICIPALITY_FAIL: 'GET_MUNICIPALITY_FAIL',

    //loading
    ADD_MUNICIPALITYS_LOADING: 'ADD_MUNICIPALITYS_LOADING'
};
