import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Flex,
    Box,
    Button
} from '@chakra-ui/react';
import { closeCreateGradeModel } from '../../modules/models/Actions';
import { Formik, Field, Form } from 'formik';
import createGovernmentSchema from '../Schemas/createGovernment';
import { createGradeRequest, editGradeRequest } from '../../modules/grades/Actions';

const CreateGrade = ({ data, addEdit }) => {
    const model = useSelector((state) => state.model);
    const dispatch = useDispatch();

    const isLoading = useSelector((state) => state.grades.isLoading);

    return (
        <Modal
            isOpen={model.isOpen4}
            onClose={() => dispatch(closeCreateGradeModel())}
            size="2xl"
            closeOnOverlayClick={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader padding="15px" bg="main_2" color={'white'}>
                    {addEdit ? 'اضافة' : 'تعديل'}
                </ModalHeader>
                <ModalCloseButton
                    color={'white'}
                    right="90%"
                    onClick={() => dispatch(closeCreateGradeModel())}
                />
                <ModalBody>
                    <Formik
                        validationSchema={createGovernmentSchema}
                        initialValues={
                            !addEdit
                                ? { ...data }
                                : {
                                      name: ''
                                  }
                        }
                        onSubmit={(values) => {
                            const action = () => {
                                dispatch(closeCreateGradeModel());
                            };
                            if (addEdit) {
                                dispatch(createGradeRequest(values, action));
                            } else {
                                dispatch(
                                    editGradeRequest(
                                        {
                                            id: data?.id,
                                            formData: {
                                                name: values.name
                                            }
                                        },
                                        action
                                    )
                                );
                            }
                        }}>
                        {({ values, errors, touched, handleSubmit }) => (
                            <Form>
                                <Flex flexDirection="column" justify="space-between" width={'100%'}>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        width={'100%'}
                                        mb="20px">
                                        <label htmlFor="name" style={{ marginBlock: '10px' }}>
                                            السنة الدراسية
                                        </label>
                                        <Field
                                            id="name"
                                            name="name"
                                            placeholder=""
                                            style={{
                                                width: '100%',
                                                border: '1px solid #8080803b',
                                                padding: '5px',
                                                borderRadius: '5px'
                                            }}
                                        />
                                    </Box>
                                    <Button
                                        mt="20px"
                                        w="25%"
                                        type="submit"
                                        style={{ marginBlock: '10px' }}
                                        bg="main_2"
                                        isLoading={isLoading}
                                        alignSelf={'flex-end'}
                                        color="white">
                                        حفظ التغيرات
                                    </Button>
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CreateGrade;
