import React, { useState } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import Header from '../Header';
const DashboardLayout = ({ children, title, custamHeight }) => {
    const [displayData, setDisplayData] = useState('block');

    return (
        <Box minH="100vh" w="100%">
            <Flex bg="whiteAlpha.800">
                <Header
                    displayData={displayData}
                    setDisplayData={setDisplayData}
                    title={title}
                    custamHeight={custamHeight}
                />
                <Box
                    w={{ base: '100%', md: displayData != 'none' ? '80%' : '100%' }}
                    mr={{ base: '0', md: displayData == 'none' ? '0' : '27%' }}
                    mt={custamHeight ? custamHeight : '160px'}>
                    {children}
                </Box>
            </Flex>
        </Box>
    );
};

export default DashboardLayout;
