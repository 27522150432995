import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllAuthors({ payload }) {
    try {
        yield put(actions.addAuthorLoading(true));
        const result = yield call(api.getAllAuthors);
        yield put(actions.getAllAuthorsSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        console.log(error, 'error');
        yield put(actions.getAllAuthorsFail());
    } finally {
        yield put(actions.addAuthorLoading(false));
    }
}



//Edit Tables
function* editAuthor(data) {
    try {
        yield put(actions.addAuthorLoading(true));
        const result = yield call(api.editAuthors, data.payload);
        yield put(actions.editAuthorSuccess(result.data));
        toast.success('Edit Successfully');
        data?.action && data?.action()
    } catch (error) {
        yield put(actions.editAuthorFail());
    } finally {
        yield put(actions.addAuthorLoading(false));
    }
}

// create
function* createAuthor(formData) {
    try {
        yield put(actions.addAuthorLoading(true));
        const result = yield call(api.createAuthors, formData.payload);
        yield put(
            actions.createAuthorSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.createAuthorFail());
    } finally {
        yield put(actions.addAuthorLoading(false));
    }
}

function* deleteAuthor({ payload }) {
    try {
        yield put(actions.addAuthorLoading(true));
        const result = yield call(api.deleteAuthor, payload);

        yield put(actions.deleteAuthorSuccess(payload));

        toast.success('Deleted Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteAuthorFail());
    } finally {
        yield put(actions.addAuthorLoading(false));
    }
}

export default function* authorSaga() {
    yield takeLatest(Types.GET_AUTHORS_REQUEST, getAllAuthors);
    yield takeLatest(Types.EDIT_AUTHOR_REQUEST, editAuthor);
    yield takeLatest(Types.CREATE_AUTHOR_REQUEST, createAuthor);
    yield takeLatest(Types.DELETE_AUTHOR_REQUEST, deleteAuthor);
}
