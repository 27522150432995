import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllWholesalers({ payload }) {
    try {
        yield put(actions.addWholesalerLoading(true));
        const result = yield call(api.getAllWholesalers, payload);
        yield put(actions.getAllWholesalersSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        console.log(error, 'error');
        yield put(actions.getAllWholesalersFail());
    } finally {
        yield put(actions.addWholesalerLoading(false));
    }
}



//Edit Tables
function* editWholesaler(formData) {
    try {
        yield put(actions.addWholesalerLoading(true));
        const result = yield call(api.editWholesalers, formData.payload);
        yield put(actions.editWholesalerSuccess(result.data));
        toast.success('Edit Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.editWholesalerFail());
    } finally {
        yield put(actions.addWholesalerLoading(false));
    }
}

// create
function* createWholesaler(formData) {
    try {
        yield put(actions.addWholesalerLoading(true));
        const result = yield call(api.createWholesalers, formData.payload);
        yield put(
            actions.createWholesalerSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.createWholesalerFail());
    } finally {
        yield put(actions.addWholesalerLoading(false));
    }
}

function* deleteWholesaler({ payload }) {
    try {
        yield put(actions.addWholesalerLoading(true));
        const result = yield call(api.deleteWholesaler, payload);

        yield put(actions.deleteWholesalerSuccess(payload));

        toast.success('Deleted Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteWholesalerFail());
    } finally {
        yield put(actions.addWholesalerLoading(false));
    }
}

export default function* wholesalersSaga() {
    yield takeLatest(Types.GET_WHOLESALERS_REQUEST, getAllWholesalers);
    yield takeLatest(Types.EDIT_WHOLESALER_REQUEST, editWholesaler);
    yield takeLatest(Types.CREATE_WHOLESALER_REQUEST, createWholesaler);
    yield takeLatest(Types.DELETE_WHOLESALER_REQUEST, deleteWholesaler);
}
