import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Textarea,
    FormLabel,
    ModalFooter,
    Button
} from '@chakra-ui/react';

import { closeAVisitModel } from '../../modules/models/Actions';
import axiosInstance from '../../helpers/api';

const VisitDetails = ({ data, getData }) => {
    const model = useSelector((state) => state.model);
    const dispatch = useDispatch();
    const animatedComponents = makeAnimated();
    const [body, setBody] = useState({});
    const [isLoading, setLoading] = useState(false);

    const visitStatus = [
        { value: 0, label: 'لديه مخزون' },
        { value: 1, label: 'زيارة ناجحة' },
        { value: 2, label: 'ليس لديه مخزون' },
        { value: 3, label: 'اسباب اخري' }
    ];
    const handelSubmit = () => {
        setLoading(true);
        try {
            const res = axiosInstance.put(`/visits/${data?.id}/moderator`, body);
        } catch (error) {
        } finally {
            setLoading(false);
            dispatch(closeAVisitModel());
            getData();
        }
    };
    return (
        <Modal
            isOpen={model.isOpen17}
            onClose={() => dispatch(closeAVisitModel())}
            size="2xl"
            closeOnOverlayClick={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader padding="15px" bg="main_2" color={'white'}>
                    تفاصيل الزيارة
                </ModalHeader>
                <ModalCloseButton
                    color={'white'}
                    right="90%"
                    onClick={() => dispatch(closeAVisitModel())}
                />
                <ModalBody p="40px">
                    <FormLabel htmlFor="followup_state">حالة الزيارة</FormLabel>
                    <Select
                        closeMenuOnSelect={true}
                        components={animatedComponents}
                        name="followup_state"
                        options={visitStatus}
                        onChange={(val) => setBody({ ...body, followup_state: val?.value })}
                    />

                    <FormLabel htmlFor="moderator_comment" mt="20px">
                        الملاحظات
                    </FormLabel>
                    <Textarea
                        name="moderator_comment"
                        placeholder=""
                        onChange={(e) => setBody({ ...body, moderator_comment: e.target.value })}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        mt="20px"
                        w="25%"
                        onClick={handelSubmit}
                        style={{ marginBlock: '10px' }}
                        bg="main_2"
                        isLoading={isLoading}
                        alignSelf={'flex-end'}
                        color="white">
                        حفظ التغيرات
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default VisitDetails;
