import { Button, Flex } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllGovernmentsRequest } from '../../modules/governments/Actions';
import AuthWrapper from '../Auth/AuthWrapper';

const GovList = ({ setStep, setGovID, isLoading, name }) => {
    const dispatch = useDispatch();
    const governments = useSelector((state) => state.governments.governments);

    useEffect(() => {
        dispatch(getAllGovernmentsRequest());
    }, []);
    return (
        <Flex flexWrap="wrap" alignItems="center" justifyContent="space-evenly">
            {governments?.map((government, index) => {
                return (
                    <Button
                        m="35px"
                        isLoading={isLoading}
                        onClick={() => {
                            setStep(false);
                            name ? setGovID(government?.name) : setGovID(government?.id);
                        }}>
                        {government?.name} | {government?.municipilty}
                    </Button>
                );
            })}
        </Flex>
    );
};

export default GovList;
