import Types from './Types';

// GETALL APPOINTMENTS


// 
export const getUserDataRequest = (id) => ({
    type: Types.GET_USER_DATA_REQUEST,
    payload: id
});

export const getUserDataSuccess = (data) => ({
    type: Types.GET_USER_DATA_SUCCESS,
    payload: data
});

export const getUserDataFail = () => ({
    type: Types.GET_USER_DATA_FAIL
});

//LOADING
export const userDataLoading = (isLoading) => ({
    type: Types.USER_DATA_LOADING,
    payload: isLoading
});

