export default {
    //get all users
    GET_TEACHERS_REQUEST: 'GET_TEACHERS_REQUEST',
    GET_TEACHERS_SUCCESS: 'GET_TEACHERS_SUCCESS',
    GET_TEACHERS_FAIL: 'GET_TEACHERS_FAIL',

    //edit users
    EDIT_TEACHER_REQUEST: 'EDIT_TEACHER_REQUEST',
    EDIT_TEACHER_SUCCESS: 'EDIT_TEACHER_SUCCESS',
    EDIT_TEACHER_FAIL: 'EDIT_TEACHER_FAIL',

    //create user
    CREATE_TEACHER_REQUEST: 'CREATE_TEACHER_REQUEST',
    CREATE_TEACHER_SUCCESS: 'CREATE_TEACHER_SUCCESS',
    CREATE_TEACHER_FAIL: 'CREATE_TEACHER_FAIL',

    DELETE_TEACHER_REQUEST: 'DELETE_TEACHER_REQUEST',
    DELETE_TEACHER_SUCCESS: 'DELETE_TEACHER_SUCCESS',
    DELETE_TEACHER_FAIL: 'DELETE_TEACHER_FAIL',

    GET_TEACHER_REQUEST: 'GET_TEACHER_REQUEST',
    GET_TEACHER_SUCCESS: 'GET_TEACHER_SUCCESS',
    GET_TEACHER_FAIL: 'GET_TEACHER_FAIL',

    //loading
    ADD_TEACHERS_LOADING: 'ADD_TEACHERS_LOADING'
};
