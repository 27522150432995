import axiosInstance from '../../helpers/api';

export const getAllCenters = (id) => {
    console.log(id)
    return axiosInstance.get(`/centers/${id}`,);
};



export const editCenters = (formData) => {

    return axiosInstance.put(`/centers/${formData.id}`, formData.formData);
};


export const createCenters = (formData) => {
    return axiosInstance.post('/centers', formData);
};

export const deleteCenter = (id) => {
    return axiosInstance.delete(`/centers/${id}`);
};
