export default {
    //get all users
    GET_TEACHER_TYPE_REQUEST: 'GET_TEACHER_TYPE_REQUEST',
    GET_TEACHER_TYPE_SUCCESS: 'GET_TEACHER_TYPE_SUCCESS',
    GET_TEACHER_TYPE_FAIL: 'GET_TEACHER_TYPE_FAIL',



    //create user
    CREATE_TEACHER_TYPE_REQUEST: 'CREATE_TEACHER_TYPE_REQUEST',
    CREATE_TEACHER_TYPE_SUCCESS: 'CREATE_TEACHER_TYPE_SUCCESS',
    CREATE_TEACHER_TYPE_FAIL: 'CREATE_TEACHER_TYPE_FAIL',

    DELETE_TEACHER_TYPE_REQUEST: 'DELETE_TEACHER_TYPE_REQUEST',
    DELETE_TEACHER_TYPE_SUCCESS: 'DELETE_TEACHER_TYPE_SUCCESS',
    DELETE_TEACHER_TYPE_FAIL: 'DELETE_TEACHER_TYPE_FAIL',



    //loading
    ADD_TEACHER_TYPE_LOADING: 'ADD_TEACHER_TYPE_LOADING'
};
