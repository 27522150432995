import axiosInstance from '../../helpers/api';

export const getAllAreaVillages = (data) => {
    return axiosInstance.post(`/areaVillage/search?page=${data?.page}`, data.body);
};



export const editAreaVillages = (formData) => {

    return axiosInstance.put(`/areaVillage/${formData.id}`, formData.formData);
};


export const createAreaVillages = (formData) => {
    return axiosInstance.post('/areaVillage', formData);
};

export const deleteAreaVillage = (id) => {
    return axiosInstance.delete(`/areaVillage/${id}`);
};
