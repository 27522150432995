import Types from './Types';

const INITIAL_STATE = {
    plans: [],
    plan: {},
    isLoading: false,
    count: ''
};

export default function plans(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_PLANS_SUCCESS: {
            console.log(payload);
            return {
                ...state,
                plans: payload.data,
                count: payload.data.length
            };
        }
        case Types.GET_PLANS_DATA_SUCCESS: {
            return {
                ...state,
                plans: payload.data,
                count: payload.data.length
            };
        }

        case Types.GET_PLAN_SUCCESS: {
            return {
                ...state,
                plan: payload
            };
        }

        case Types.EDIT_PLAN_SUCCESS: {
            let data = payload.data;
            const plans = state.plans;
            let idx = 0;
            plans.forEach((table, index) => {
                if (table.id === data.id) {
                    idx = index;
                    return;
                }
            });

            plans[idx] = data;
            return {
                ...state,
                plans: plans
            };
        }

        // create
        case Types.CREATE_PLAN_SUCCESS: {
            const data = payload.data.data;
            let newArray = state.plans.slice();
            let index = state.plans.length + 1;
            newArray.splice(index, 0, data);
            return {
                ...state,
                plans: newArray
            };
        }
        case Types.ADD_PLANS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        case Types.DELETE_PLAN_SUCCESS: {
            const newplans = state.plans.filter((item) => item.id !== payload);
            return {
                ...state,
                plans: newplans
            };
        }
        default: {
            return state;
        }
    }
}
