import Types from './Types';


export const getAllMunicipalitysRequest = (body) => ({
    type: Types.GET_MUNICIPALITYS_REQUEST,
    payload: body
});

export const getAllMunicipalitysSuccess = (data) => ({
    type: Types.GET_MUNICIPALITYS_SUCCESS,
    payload: data
});

export const getAllMunicipalitysFail = () => ({
    type: Types.GET_MUNICIPALITYS_FAIL
});

export const getMunicipalitytsByGovIDRequest = (body) => ({
    type: Types.GET_MUNICIPALITYS_BY_GOV_ID_REQUEST,
    payload: body
});

export const getMunicipalitytsByGovIDSuccess = (data) => ({
    type: Types.GET_MUNICIPALITYS_BY_GOV_ID_SUCCESS,
    payload: data
});

export const getMunicipalitytsByGovIDFail = () => ({
    type: Types.GET_MUNICIPALITYS_BY_GOV_ID_FAIL
});
export const editMunicipalityRequest = (formData, action) => ({
    type: Types.EDIT_MUNICIPALITY_REQUEST,
    payload: formData,
    action: action
});

export const editMunicipalitySuccess = (data) => ({
    type: Types.EDIT_MUNICIPALITY_SUCCESS,
    payload: data,

});

export const editMunicipalityFail = () => ({
    type: Types.EDIT_MUNICIPALITY_FAIL
});

//LOADING
export const addMunicipalityLoading = (isLoading) => ({
    type: Types.ADD_MUNICIPALITYS_LOADING,
    payload: isLoading
});

// Create Municipality
export const createMunicipalityRequest = (formData, action) => ({
    type: Types.CREATE_MUNICIPALITY_REQUEST,
    payload: { ...formData },
    action: action
});

export const createMunicipalitySuccess = ({ data }) => ({
    type: Types.CREATE_MUNICIPALITY_SUCCESS,
    payload: {
        data
    }
});

export const createMunicipalityFail = () => ({
    type: Types.CREATE_MUNICIPALITY_FAIL
});

export const deleteMunicipalityRequest = (id) => ({
    type: Types.DELETE_MUNICIPALITY_REQUEST,
    payload: id
});

export const deleteMunicipalitySuccess = (id) => ({
    type: Types.DELETE_MUNICIPALITY_SUCCESS,
    payload: id
});

export const deleteMunicipalityFail = () => ({
    type: Types.DELETE_MUNICIPALITY_FAIL
});
