import * as Yup from 'yup';

const createUserSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(70, 'Too Long!').required('Required'),
    phone: Yup.string().min(11, 'Too Short!').max(12, 'Too Long!').required('Required'),
    user_name: Yup.string().min(2, 'Too Short!').max(70, 'Too Long!').required('Required'),
    email: Yup.string().email('Not valid email').required('Required'),

});

export default createUserSchema;
