import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllSchools(data) {
    try {
        yield put(actions.addSchoolLoading(true));
        const result = yield call(api.getAllSchools, data);
        yield put(actions.getAllSchoolsSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        console.log(error, 'error');
        yield put(actions.getAllSchoolsFail());
    } finally {
        yield put(actions.addSchoolLoading(false));
    }
}



//Edit Tables
function* editSchool(formData) {
    try {
        yield put(actions.addSchoolLoading(true));
        const result = yield call(api.editSchools, formData.payload);
        yield put(actions.editSchoolSuccess(result.data));
        toast.success('Edit Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.editSchoolFail());
    } finally {
        yield put(actions.addSchoolLoading(false));
    }
}

// create
function* createSchool(formData) {
    try {
        yield put(actions.addSchoolLoading(true));
        const result = yield call(api.createSchools, formData.payload);
        yield put(
            actions.createSchoolSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.createSchoolFail());
    } finally {
        yield put(actions.addSchoolLoading(false));
    }
}

function* deleteSchool({ payload }) {
    try {
        yield put(actions.addSchoolLoading(true));
        const result = yield call(api.deleteSchool, payload);

        yield put(actions.deleteSchoolSuccess(payload));

        toast.success('Deleted Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteSchoolFail());
    } finally {
        yield put(actions.addSchoolLoading(false));
    }
}

export default function* SchoolSaga() {
    yield takeLatest(Types.GET_SCHOOLS_REQUEST, getAllSchools);
    yield takeLatest(Types.EDIT_SCHOOL_REQUEST, editSchool);
    yield takeLatest(Types.CREATE_SCHOOL_REQUEST, createSchool);
    yield takeLatest(Types.DELETE_SCHOOL_REQUEST, deleteSchool);
}
