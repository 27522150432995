import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DashboardLayout from '../../components/Layout/DashboardLayout';
import { openAddPlanToUserModel, openCreateUserModel } from '../../modules/models/Actions';
import { useSelector } from 'react-redux';
import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import CTable from '../../components/table/table';
import { HiDotsVertical } from 'react-icons/hi';
import { RiFileEditLine } from 'react-icons/ri';

import { getAllUsersRequest } from '../../modules/users/Actions';
import CreateUser from '../../components/models/createUser';
import AuthWrapper from '../../components/Auth/AuthWrapper';
import AddPlanToUser from '../../components/models/addPlanToUser';

const SalePersons = () => {
    const dispatch = useDispatch();
    const [addEdit, setAddEdit] = useState(true);

    const users = useSelector((state) => state.users.users);
    const count = useSelector((state) => state.users.count);
    const isLoading = useSelector((state) => state.users.isLoading);
    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(50);
    const [pageNumber, setPageNumber] = useState(0);
    const [selected, setSelected] = useState({
        id: ''
    });

    useEffect(() => {
        dispatch(getAllUsersRequest('Sale Person', pageNumber + 1));
    }, [pageNumber]);

    const Data = users?.map((data) => {
        return {
            ...data,
            id: data?.id,

            phone: data?.phone,
            email: data?.email,
            name: data?.name
        };
    });

    const actions = (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HiDotsVertical />}
                size="sm"
                fontSize="20px"
                variant="outline"
                border="none"
            />
            <MenuList>
                <MenuItem
                    icon={<RiFileEditLine />}
                    onClick={() => {
                        setAddEdit(false);
                        dispatch(openCreateUserModel());
                    }}>
                    تعديل
                </MenuItem>
                <MenuItem
                    onClick={() => dispatch(openAddPlanToUserModel())}
                    icon={<RiFileEditLine />}>
                    اضافة خط سير
                </MenuItem>
            </MenuList>
        </Menu>
    );
    const columns = [
        {
            Header: 'الاسم',
            accessor: 'name'
        },
        {
            Header: 'البريد الالكتروني',
            accessor: 'email'
        },

        {
            Header: ' رقم الهاتف',
            accessor: 'phone'
        },

        {
            Header: '',
            accessor: 'Actions'
        }
    ];
    const handelFilter = (e) => {};
    const viewData = (data) => {
        setSelected(data);
    };

    return (
        <AuthWrapper roles={['Super Admin', 'Moderator']}>
            <DashboardLayout title={'مندوبي المبيعات'}>
                <CTable
                    selectedData={viewData}
                    Columns={columns}
                    Data={Data}
                    path={openCreateUserModel}
                    Actions={actions}
                    Title=" "
                    subTitle={``}
                    btnTitle="hi"
                    placeHolder=""
                    noSearchBar={true}
                    noFilter={true}
                    footerBtnTitle
                    filterList
                    addButton
                    addButtonTitle="اضافة  "
                    setPage={setPage}
                    setPerPage={setPerPage}
                    currentpage={pageNumber}
                    setPageNumber={setPageNumber}
                    noSecondSearchBar={true}
                    isLoading={isLoading}
                    perPage={size}
                    totalPage={Math.ceil(count / 50)}
                    setAddEdit={setAddEdit}
                />

                <CreateUser data={selected} addEdit={addEdit} role="Sale Person" />
                <AddPlanToUser data={selected?.id} />
            </DashboardLayout>
        </AuthWrapper>
    );
};

export default SalePersons;
