import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllBookstores(payload) {
    try {
        yield put(actions.addBookstoreLoading(true));
        const result = yield call(api.getAllBookstores, payload);
        yield put(actions.getAllBookstoresSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        console.log(error, 'error');
        yield put(actions.getAllBookstoresFail());
    } finally {
        yield put(actions.addBookstoreLoading(false));
    }
}



//Edit Tables
function* editBookstore(formData) {
    try {
        yield put(actions.addBookstoreLoading(true));
        const result = yield call(api.editBookstores, formData.payload);
        yield put(actions.editBookstoreSuccess(result.data));
        toast.success('Edit Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.editBookstoreFail());
    } finally {
        yield put(actions.addBookstoreLoading(false));
    }
}

// create
function* createBookstore(formData) {
    try {
        yield put(actions.addBookstoreLoading(true));
        const result = yield call(api.createBookstores, formData.payload);
        yield put(
            actions.createBookstoreSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.createBookstoreFail());
    } finally {
        yield put(actions.addBookstoreLoading(false));
    }
}

function* deleteBookstore({ payload }) {
    try {
        yield put(actions.addBookstoreLoading(true));
        const result = yield call(api.deleteBookstore, payload);

        yield put(actions.deleteBookstoreSuccess(payload));

        toast.success('Deleted Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteBookstoreFail());
    } finally {
        yield put(actions.addBookstoreLoading(false));
    }
}

export default function* BookstoreSaga() {
    yield takeLatest(Types.GET_BOOKSTORES_REQUEST, getAllBookstores);
    yield takeLatest(Types.EDIT_BOOKSTORE_REQUEST, editBookstore);
    yield takeLatest(Types.CREATE_BOOKSTORE_REQUEST, createBookstore);
    yield takeLatest(Types.DELETE_BOOKSTORE_REQUEST, deleteBookstore);
}
