export default {
    //get all AREAVILLAGEs
    GET_AREAVILLAGES_REQUEST: 'GET_AREAVILLAGES_REQUEST',
    GET_AREAVILLAGES_SUCCESS: 'GET_AREAVILLAGES_SUCCESS',
    GET_AREAVILLAGES_FAIL: 'GET_AREAVILLAGES_FAIL',

    //edit AREAVILLAGEs
    EDIT_AREAVILLAGE_REQUEST: 'EDIT_AREAVILLAGE_REQUEST',
    EDIT_AREAVILLAGE_SUCCESS: 'EDIT_AREAVILLAGE_SUCCESS',
    EDIT_AREAVILLAGE_FAIL: 'EDIT_AREAVILLAGE_FAIL',

    //create AREAVILLAGE
    CREATE_AREAVILLAGE_REQUEST: 'CREATE_AREAVILLAGE_REQUEST',
    CREATE_AREAVILLAGE_SUCCESS: 'CREATE_AREAVILLAGE_SUCCESS',
    CREATE_AREAVILLAGE_FAIL: 'CREATE_AREAVILLAGE_FAIL',

    DELETE_AREAVILLAGE_REQUEST: 'DELETE_AREAVILLAGE_REQUEST',
    DELETE_AREAVILLAGE_SUCCESS: 'DELETE_AREAVILLAGE_SUCCESS',
    DELETE_AREAVILLAGE_FAIL: 'DELETE_AREAVILLAGE_FAIL',

    GET_AREAVILLAGE_REQUEST: 'GET_AREAVILLAGE_REQUEST',
    GET_AREAVILLAGE_SUCCESS: 'GET_AREAVILLAGE_SUCCESS',
    GET_AREAVILLAGE_FAIL: 'GET_AREAVILLAGE_FAIL',

    //loading
    ADD_AREAVILLAGES_LOADING: 'ADD_AREAVILLAGES_LOADING'
};
