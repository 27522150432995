import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DashboardLayout from '../../components/Layout/DashboardLayout';
import { openCreatePlanModel, openVisitodel } from '../../modules/models/Actions';
import DatePicker from 'react-datepicker';

import { useSelector } from 'react-redux';
import {
    Button,
    Flex,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Text
} from '@chakra-ui/react';
import CTable from '../../components/table/table';
import { HiDotsVertical } from 'react-icons/hi';
import { RiFileEditLine } from 'react-icons/ri';

import AuthWrapper from '../../components/Auth/AuthWrapper';

import { getAllUsersRequest } from '../../modules/users/Actions';
import { getVisitsBySPIdRequest } from '../../modules/visits/Actions';
import VisitDetails from '../../components/models/visitDetails';
import moment from 'moment';

const Visits = () => {
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.userData.userData);

    const [addEdit, setAddEdit] = useState(true);
    const users = useSelector((state) => state.users.users);
    const visits = useSelector((state) => state.visits.visits);
    const count = useSelector((state) => state.visits.count);
    const [startDate, setStartDate] = useState(new Date());

    const isLoading = useSelector((state) => state.visits.isLoading);
    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(100);
    const [pageNumber, setPageNumber] = useState(0);
    const [filterOptions, setFilterOptions] = useState({
        name: '',
        from: '',
        status: ''
    });

    const [selected, setSelected] = useState({
        id: ''
    });
    useEffect(() => {
        dispatch(getAllUsersRequest('Sale Person'));
    }, []);

    const getData = () => {
        dispatch(getVisitsBySPIdRequest(filterOptions, pageNumber + 1));
    };
    useEffect(() => {
        getData();
    }, [filterOptions, pageNumber]);

    const visitStatus = {
        0: 'لديه مخزون',
        1: 'زيارة ناجحة',
        2: 'ليس لديه مخزون',
        3: 'اسباب اخري'
    };
    const Data = visits?.map((data, index) => {
        return {
            ...data,
            idx: index + 1,
            name: `visit_${index + 1}`,
            salePersonName: data?.salePerson?.name,
            place: data?.teacher ? data?.teacher?.name : data?.bookStore?.name,
            contact: data?.teacher
                ? data?.teacher?.school
                    ? data?.teacher?.school
                    : data?.teacher?.center
                : data?.bookStore?.school,
            note: data?.notes ? data?.notes : 'لا يوجد',
            gifts:
                data?.books?.length == 0 ? (
                    <Text>لا يوجد</Text>
                ) : (
                    data?.books?.map((book) => {
                        return (
                            <Flex>
                                <Text>{book?.title}</Text> /
                                <Text mx="8px">{book?.pivot?.book_sale_quantity}</Text>
                            </Flex>
                        );
                    })
                ),
            status: visitStatus[data?.status],
            followup_state: data?.followup_state ? visitStatus[data?.followup_state] : 'لا يوجد',
            moderator_comment: data?.moderator_comment ? data?.moderator_comment : 'لا يوجد'
        };
    });
    const actions = userData?.role?.[0]?.name != 'Sale Person' && (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HiDotsVertical />}
                size="sm"
                fontSize="20px"
                variant="outline"
                border="none"
            />
            <MenuList>
                <MenuItem
                    icon={<RiFileEditLine />}
                    onClick={() => {
                        dispatch(openVisitodel());
                    }}>
                    متابعة الزيارة
                </MenuItem>
            </MenuList>
        </Menu>
    );
    const mainColumns = [
        {
            Header: '',
            accessor: 'idx'
        },

        {
            Header: 'اسم المندوب',
            accessor: 'salePersonName'
        },
        {
            Header: ' تاريخ الزيارة',
            accessor: 'date'
        },
        {
            Header: 'مكان الزيارة',
            accessor: 'place'
        },
        {
            Header: ' الهدايا/عددها',
            accessor: 'gifts'
        },
        {
            Header: ' الملاحظات',
            accessor: 'note'
        },
        {
            Header: ' حالة الزيارة',
            accessor: 'status'
        },
        {
            Header: ' المدرسة/السنتر',
            accessor: 'contact'
        }
    ];
    const moderatorColumns = [
        {
            Header: 'حالة الزيارة(للمدير)',
            accessor: 'followup_state'
        },
        {
            Header: ' التعليق علي الزيارد (المدير)',
            accessor: 'moderator_comment'
        },
        {
            Header: '',
            accessor: 'Actions'
        }
    ];
    const columns = [
        ...mainColumns,
        ...(userData?.role?.[0]?.name !== 'Sale Person' ? moderatorColumns : [])
    ];

    const handelFilter = (e, name) => {
        setFilterOptions({ ...filterOptions, [name]: e });
    };
    const viewData = (data) => {
        setSelected(data);
    };
    const secondFilterList = (
        <MenuOptionGroup type="radio">
            <DatePicker
                selected={startDate}
                onChange={(date) => handelFilter(moment(date).format('YYYY-MM-DD'), 'from')}
                inline
            />
        </MenuOptionGroup>
    );
    const filterList = (
        <MenuOptionGroup type="radio" value={filterOptions.name}>
            {users.map((user) => {
                return (
                    <MenuItemOption
                        onClick={() => handelFilter(user?.name, 'name')}
                        value={user?.name}>
                        {user?.name}
                    </MenuItemOption>
                );
            })}
        </MenuOptionGroup>
    );
    return (
        <AuthWrapper roles={['Super Admin', 'Moderator', 'Sale Person']}>
            <DashboardLayout title={' الزيارات'} custamHeight={{ base: '280px', md: '140px' }}>
                <Menu>
                    <MenuButton
                        as={Button}
                        bg="gradient_main_color"
                        color="white"
                        position="fixed"
                        top={{ base: '200px', md: '75px' }}
                        zIndex="200"
                        left={{ base: '10px', md: '340px' }}>
                        حالة الزيارة
                    </MenuButton>
                    <MenuList>
                        <MenuOptionGroup type="radio" value={filterOptions.status}>
                            <MenuItemOption onClick={() => handelFilter('', 'status')} value={''}>
                                الكل
                            </MenuItemOption>
                            <MenuItemOption onClick={() => handelFilter('0', 'status')}>
                                لديه مخزون
                            </MenuItemOption>
                            <MenuItemOption onClick={() => handelFilter('1', 'status')}>
                                زيارة ناجحة
                            </MenuItemOption>
                            <MenuItemOption onClick={() => handelFilter('2', 'status')}>
                                ليس لديه مخزون
                            </MenuItemOption>
                            <MenuItemOption onClick={() => handelFilter('3', 'status')}>
                                {' '}
                                اسباب اخري
                            </MenuItemOption>
                        </MenuOptionGroup>
                    </MenuList>
                </Menu>
                <CTable
                    selectedData={viewData}
                    Columns={columns}
                    Data={Data}
                    path={openCreatePlanModel}
                    Actions={actions}
                    Title=" "
                    subTitle={``}
                    btnTitle="hi"
                    placeHolder=""
                    noSearchBar={true}
                    noFilter={false}
                    footerBtnTitle
                    filterList={filterList}
                    addButton={false}
                    addButtonTitle
                    setPage={setPage}
                    setPerPage={setPerPage}
                    currentpage={pageNumber}
                    setPageNumber={setPageNumber}
                    noSecondSearchBar={true}
                    isLoading={isLoading}
                    perPage={size}
                    totalPage={Math.ceil(count / 100)}
                    setAddEdit={setAddEdit}
                    noSecondFilter={true}
                    secondFilterList={secondFilterList}
                    secondFilterTitle={'تنقية بتاريخ الزيارة'}
                />

                <VisitDetails data={selected} getData={getData} />
            </DashboardLayout>
        </AuthWrapper>
    );
};

export default Visits;
