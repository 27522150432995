import Types from './Types';


export const getAllAuthorsRequest = (body) => ({
    type: Types.GET_AUTHORS_REQUEST,
    payload: body
});

export const getAllAuthorsSuccess = (data) => ({
    type: Types.GET_AUTHORS_SUCCESS,
    payload: data
});

export const getAllAuthorsFail = () => ({
    type: Types.GET_AUTHORS_FAIL
});


export const editAuthorRequest = (formData, action) => ({
    type: Types.EDIT_AUTHOR_REQUEST,
    payload: formData,
    action: action
});

export const editAuthorSuccess = (data) => ({
    type: Types.EDIT_AUTHOR_SUCCESS,
    payload: data
});

export const editAuthorFail = () => ({
    type: Types.EDIT_AUTHOR_FAIL
});

//LOADING
export const addAuthorLoading = (isLoading) => ({
    type: Types.ADD_AUTHORS_LOADING,
    payload: isLoading
});

// Create Author
export const createAuthorRequest = (formData, action) => ({
    type: Types.CREATE_AUTHOR_REQUEST,
    payload: { ...formData },
    action: action
});

export const createAuthorSuccess = ({ data }) => ({
    type: Types.CREATE_AUTHOR_SUCCESS,
    payload: {
        data
    }
});

export const createAuthorFail = () => ({
    type: Types.CREATE_AUTHOR_FAIL
});

export const deleteAuthorRequest = (id) => ({
    type: Types.DELETE_AUTHOR_REQUEST,
    payload: id
});

export const deleteAuthorSuccess = (id) => ({
    type: Types.DELETE_AUTHOR_SUCCESS,
    payload: id
});

export const deleteAuthorFail = () => ({
    type: Types.DELETE_AUTHOR_FAIL
});
