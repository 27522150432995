import React from 'react';
import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure
} from '@chakra-ui/react';
import SideNavbar from '.';
const MobileNavbar = ({ isDisplay, setIsDisplay }) => {
    const { onClose } = useDisclosure();
    return (
        <Drawer isOpen={isDisplay} placement="right" onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton onClick={() => setIsDisplay(!isDisplay)} />
                <DrawerBody overflowY={'hidden'}>
                    <SideNavbar />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    );
};

export default MobileNavbar;
