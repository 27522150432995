export default {
    //get all users
    GET_CENTERS_REQUEST: 'GET_CENTERS_REQUEST',
    GET_CENTERS_SUCCESS: 'GET_CENTERS_SUCCESS',
    GET_CENTERS_FAIL: 'GET_CENTERS_FAIL',

    //edit users
    EDIT_CENTER_REQUEST: 'EDIT_CENTER_REQUEST',
    EDIT_CENTER_SUCCESS: 'EDIT_CENTER_SUCCESS',
    EDIT_CENTER_FAIL: 'EDIT_CENTER_FAIL',

    //create user
    CREATE_CENTER_REQUEST: 'CREATE_CENTER_REQUEST',
    CREATE_CENTER_SUCCESS: 'CREATE_CENTER_SUCCESS',
    CREATE_CENTER_FAIL: 'CREATE_CENTER_FAIL',

    DELETE_CENTER_REQUEST: 'DELETE_CENTER_REQUEST',
    DELETE_CENTER_SUCCESS: 'DELETE_CENTER_SUCCESS',
    DELETE_CENTER_FAIL: 'DELETE_CENTER_FAIL',

    GET_CENTER_REQUEST: 'GET_CENTER_REQUEST',
    GET_CENTER_SUCCESS: 'GET_CENTER_SUCCESS',
    GET_CENTER_FAIL: 'GET_CENTER_FAIL',

    //loading
    ADD_CENTERS_LOADING: 'ADD_CENTERS_LOADING'
};
