import Types from './Types';

export const getVisitsBySPIdRequest = (id, page) => ({
    type: Types.GET_VISITS_BY_SPID_REQUEST,
    payload: id,
    page: page
});

export const getVisitsBySPIdSuccess = (data) => ({
    type: Types.GET_VISITS_BY_SPID_SUCCESS,
    payload: data
});

export const getVisitsBySPIdFail = () => ({
    type: Types.GET_VISITS_BY_SPID_FAIL
});

export const getVisitDetailsRequest = (id) => ({
    type: Types.GET_VISIT_DETAILS_REQUEST,
    payload: id
});

export const getVisitDetailsSuccess = (data) => ({
    type: Types.GET_VISIT_DETAILS_SUCCESS,
    payload: data
});

export const getVisitDetailsFail = () => ({
    type: Types.GET_VISIT_DETAILS_FAIL
});

export const createVisitRequest = (data) => ({
    type: Types.CREATE_VISIT_REQUEST,
    payload: data
});

export const createVisitSuccess = (data) => ({
    type: Types.CREATE_VISIT_SUCCESS,
    payload: data
});

export const createVisitFail = () => ({
    type: Types.CREATE_VISIT_FAIL
});
//LOADING
export const getVisitsLoading = (isLoading) => ({
    type: Types.GET_VISIT_LOADING,
    payload: isLoading
});
