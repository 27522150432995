import axiosInstance from '../../helpers/api';

export const getRoles = () => {
    return axiosInstance.get(`/roles`);
};

export const getAllUsers = (data) => {
    return axiosInstance.get(`/users/${data?.role}?page=${data?.page}`);
};

export const editUsers = (data) => {
    return axiosInstance.put(`/users/update/${data.id}`, data.formData);
};

export const createUsers = (formData) => {
    return axiosInstance.post('/users/set', formData);
};

export const createSalePerson = (formData) => {
    return axiosInstance.post('/users/register_salePerson', formData);
};
export const deleteUser = (id) => {
    return axiosInstance.delete(`/users/delete/${id}`);
};
