export default {
    //get all books
    GET_BOOKS_REQUEST: 'GET_BOOKS_REQUEST',
    GET_BOOKS_SUCCESS: 'GET_BOOKS_SUCCESS',
    GET_BOOKS_FAIL: 'GET_BOOKS_FAIL',

    //edit books
    EDIT_BOOK_REQUEST: 'EDIT_BOOK_REQUEST',
    EDIT_BOOK_SUCCESS: 'EDIT_BOOK_SUCCESS',
    EDIT_BOOK_FAIL: 'EDIT_BOOK_FAIL',

    //create book
    CREATE_BOOK_REQUEST: 'CREATE_BOOK_REQUEST',
    CREATE_BOOK_SUCCESS: 'CREATE_BOOK_SUCCESS',
    CREATE_BOOK_FAIL: 'CREATE_BOOK_FAIL',

    DELETE_BOOK_REQUEST: 'DELETE_BOOK_REQUEST',
    DELETE_BOOK_SUCCESS: 'DELETE_BOOK_SUCCESS',
    DELETE_BOOK_FAIL: 'DELETE_BOOK_FAIL',

    GET_BOOK_REQUEST: 'GET_BOOK_REQUEST',
    GET_BOOK_SUCCESS: 'GET_BOOK_SUCCESS',
    GET_BOOK_FAIL: 'GET_BOOK_FAIL',

    //loading
    ADD_BOOKS_LOADING: 'ADD_BOOKS_LOADING'
};
