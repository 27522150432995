import * as Yup from 'yup';

const createwholesallerSchema = Yup.object().shape({

    name: Yup.string().min(2, 'Too Short!').max(70, 'Too Long!').required('Required'),

    gov_id: Yup.string(),

    phone: Yup.string().min(11, 'Too Short!').max(12, 'Too Long!').required('Required'),



});

export default createwholesallerSchema;
