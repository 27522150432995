import axiosInstance from '../../helpers/api';

export const getAllGovernments = (body) => {
    return axiosInstance.get('/governorate', body);
};



export const editGovernments = (formData) => {
    return axiosInstance.put(`/governorate/${formData.id}`, formData.formData);
};


export const createGovernments = (formData) => {
    return axiosInstance.post('/governorate', formData);
};

export const deleteGovernment = (id) => {
    return axiosInstance.delete(`/governorate/${id}`);
};
