import axiosInstance from '../../helpers/api';

export const getAllBooks = (body) => {
    return axiosInstance.get(`/books?page=${body}`,);
};

export const editBooks = (formData) => {
    return axiosInstance.put(`/books/${formData.id}`, formData.formData);
};


export const createBooks = (formData) => {
    return axiosInstance.post('/books', formData);
};

export const deleteBook = (id) => {
    return axiosInstance.delete(`/books/${id}`);
};
