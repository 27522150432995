import Types from './Types';


export const getAllTeachersRequest = (search, page) => ({
    type: Types.GET_TEACHERS_REQUEST,
    search: search,
    page: page
});

export const getAllTeachersSuccess = (data) => ({
    type: Types.GET_TEACHERS_SUCCESS,
    payload: data
});

export const getAllTeachersFail = () => ({
    type: Types.GET_TEACHERS_FAIL
});


export const editTeacherRequest = (formData, action) => ({
    type: Types.EDIT_TEACHER_REQUEST,
    payload: formData,
    action: action
});

export const editTeacherSuccess = (data) => ({
    type: Types.EDIT_TEACHER_SUCCESS,
    payload: data
});

export const editTeacherFail = () => ({
    type: Types.EDIT_TEACHER_FAIL
});

//LOADING
export const addTeacherLoading = (isLoading) => ({
    type: Types.ADD_TEACHERS_LOADING,
    payload: isLoading
});

// Create Teachers
export const createTeacherRequest = (formData, action) => ({
    type: Types.CREATE_TEACHER_REQUEST,
    payload: { ...formData },
    action: action
});

export const createTeacherSuccess = ({ data }) => ({
    type: Types.CREATE_TEACHER_SUCCESS,
    payload: {
        data
    }
});

export const createTeacherFail = () => ({
    type: Types.CREATE_TEACHER_FAIL
});

export const deleteTeacherRequest = (id) => ({
    type: Types.DELETE_TEACHER_REQUEST,
    payload: id
});

export const deleteTeacherSuccess = (id) => ({
    type: Types.DELETE_TEACHER_SUCCESS,
    payload: id
});

export const deleteTeacherFail = () => ({
    type: Types.DELETE_TEACHER_FAIL
});
