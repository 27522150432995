import axiosInstance from '../../helpers/api';

export const getAllWholesalers = (id) => {
    console.log(id)
    return axiosInstance.get(`/wholesalers/${id}`,);
};



export const editWholesalers = (formData) => {

    return axiosInstance.put(`/wholesalers/${formData.id}`, formData.formData);
};


export const createWholesalers = (formData) => {
    return axiosInstance.post('/wholesalers', formData);
};

export const deleteWholesaler = (id) => {
    return axiosInstance.delete(`/wholesalers/${id}`);
};
