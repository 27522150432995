import React from 'react';
import UserLayout from './components/UserRoutes';
import { Provider } from 'react-redux';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { store } from './services/configurationStore';
import Login from './pages/auth/index';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { theme } from './chakra.config';
import { ChakraProvider } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import { getUserDataRequest } from './modules/userData/Actions';
const App = () => {
    const token = Cookies.get('token');

    if (token) {
        store.dispatch(getUserDataRequest());
    }
    return (
        <div>
            <Provider store={store}>
                <ChakraProvider theme={theme}>
                    <ToastContainer />
                    <Router>
                        <Switch>
                            <Route path="/login" component={Login} />
                            <Route path="/forgot-password" component={Login} />
                            <UserLayout />
                        </Switch>
                    </Router>
                </ChakraProvider>
            </Provider>
        </div>
    );
};

export default App;
