import axiosInstance from '../../helpers/api';

export const getAllAuthors = (body) => {
    return axiosInstance.get('/authors', body);
};



export const editAuthors = (formData) => {

    return axiosInstance.put(`/authors/${formData.id}`, formData.formData);
};


export const createAuthors = (formData) => {
    return axiosInstance.post('/authors', formData);
};

export const deleteAuthor = (id) => {
    return axiosInstance.delete(`/authors/${id}`);
};
