import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllGrades({ payload }) {
    try {
        yield put(actions.addGradeLoading(true));
        const result = yield call(api.getAllGrades);
        yield put(actions.getAllGradesSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        console.log(error, 'error');
        yield put(actions.getAllGradesFail());
    } finally {
        yield put(actions.addGradeLoading(false));
    }
}



//Edit Tables
function* editGrade(formData) {
    try {
        yield put(actions.addGradeLoading(true));
        const result = yield call(api.editGrades, formData.payload);
        yield put(actions.editGradeSuccess(result.data));
        toast.success('Edit Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.editGradeFail());
    } finally {
        yield put(actions.addGradeLoading(false));
    }
}

// create
function* createGrade(formData) {
    try {
        yield put(actions.addGradeLoading(true));
        const result = yield call(api.createGrades, formData.payload);
        yield put(
            actions.createGradeSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.createGradeFail());
    } finally {
        yield put(actions.addGradeLoading(false));
    }
}

function* deleteGrade({ payload }) {
    try {
        yield put(actions.addGradeLoading(true));
        const result = yield call(api.deleteGrade, payload);

        yield put(actions.deleteGradeSuccess(payload));

        toast.success('Deleted Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteGradeFail());
    } finally {
        yield put(actions.addGradeLoading(false));
    }
}

export default function* gradeSaga() {
    yield takeLatest(Types.GET_GRADES_REQUEST, getAllGrades);
    yield takeLatest(Types.EDIT_GRADE_REQUEST, editGrade);
    yield takeLatest(Types.CREATE_GRADE_REQUEST, createGrade);
    yield takeLatest(Types.DELETE_GRADE_REQUEST, deleteGrade);
}
