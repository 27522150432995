import Types from './Types';

export const getRolesRequest = (body) => ({
    type: Types.GET_ROLES_REQUEST,
    payload: body
});

export const getRolesSuccess = (data) => ({
    type: Types.GET_ROLES_SUCCESS,
    payload: data
});

export const getRolesFail = () => ({
    type: Types.GET_ROLES_FAIL
});

export const getAllUsersRequest = (role, page) => ({
    type: Types.GET_USERS_REQUEST,
    role: role,
    page: page
});

export const getAllUsersSuccess = (data) => ({
    type: Types.GET_USERS_SUCCESS,
    payload: data
});

export const getAllUsersFail = () => ({
    type: Types.GET_USERS_FAIL
});

export const editUserRequest = (id, formData, action) => ({
    type: Types.EDIT_USER_REQUEST,
    id: id,
    formData: formData,
    action: action
});

export const editUserSuccess = (data) => ({
    type: Types.EDIT_USER_SUCCESS,
    payload: data
});

export const editUserFail = () => ({
    type: Types.EDIT_USER_FAIL
});

//LOADING
export const addUserLoading = (isLoading) => ({
    type: Types.ADD_USERS_LOADING,
    payload: isLoading
});

// Create User
export const createUserRequest = (formData, action) => ({
    type: Types.CREATE_USER_REQUEST,
    payload: { ...formData },
    action: action
});

export const createUserSuccess = ({ data }) => ({
    type: Types.CREATE_USER_SUCCESS,
    payload: {
        data
    }
});

export const createUserFail = () => ({
    type: Types.CREATE_USER_FAIL
});

export const createSalePersonRequest = (formData, action) => ({
    type: Types.CREATE_SALESPERSON_REQUEST,
    payload: { ...formData },
    action: action
});

export const createSalePersonSuccess = ({ data }) => ({
    type: Types.CREATE_SALESPERSON_SUCCESS,
    payload: {
        data
    }
});

export const createSalePersonFail = () => ({
    type: Types.CREATE_SALESPERSON_FAIL
});
export const deleteUserRequest = (id) => ({
    type: Types.DELETE_USER_REQUEST,
    payload: id
});

export const deleteUserSuccess = (id) => ({
    type: Types.DELETE_USER_SUCCESS,
    payload: id
});

export const deleteUserFail = () => ({
    type: Types.DELETE_USER_FAIL
});
