import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllSubjects({ payload }) {
    try {
        yield put(actions.addSubjectLoading(true));
        const result = yield call(api.getAllSubjects);
        yield put(actions.getAllSubjectsSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        console.log(error, 'error');
        yield put(actions.getAllSubjectsFail());
    } finally {
        yield put(actions.addSubjectLoading(false));
    }
}



//Edit Tables
function* editSubject(formData) {
    try {
        yield put(actions.addSubjectLoading(true));
        const result = yield call(api.editSubjects, formData.payload);
        yield put(actions.editSubjectSuccess(result.data));
        toast.success('Edit Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.editSubjectFail());
    } finally {
        yield put(actions.addSubjectLoading(false));
    }
}

// create
function* createSubject(formData) {
    try {
        yield put(actions.addSubjectLoading(true));
        const result = yield call(api.createSubjects, formData.payload);
        yield put(
            actions.createSubjectSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.createSubjectFail());
    } finally {
        yield put(actions.addSubjectLoading(false));
    }
}

function* deleteSubject({ payload }) {
    try {
        yield put(actions.addSubjectLoading(true));
        const result = yield call(api.deleteSubject, payload);

        yield put(actions.deleteSubjectSuccess(payload));

        toast.success('Deleted Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteSubjectFail());
    } finally {
        yield put(actions.addSubjectLoading(false));
    }
}

export default function* subjectSaga() {
    yield takeLatest(Types.GET_SUBJECTS_REQUEST, getAllSubjects);
    yield takeLatest(Types.EDIT_SUBJECT_REQUEST, editSubject);
    yield takeLatest(Types.CREATE_SUBJECT_REQUEST, createSubject);
    yield takeLatest(Types.DELETE_SUBJECT_REQUEST, deleteSubject);
}
