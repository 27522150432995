import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getRoles() {
    try {
        yield put(actions.addUserLoading(true));
        const result = yield call(api.getRoles);
        yield put(actions.getRolesSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        console.log(error, 'error');
        yield put(actions.getRolesFail());
    } finally {
        yield put(actions.addUserLoading(false));
    }
}

function* getAllUsers(payload) {
    try {
        yield put(actions.addUserLoading(true));
        const result = yield call(api.getAllUsers, payload);
        yield put(actions.getAllUsersSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        console.log(error, 'error');
        yield put(actions.getAllUsersFail());
    } finally {
        yield put(actions.addUserLoading(false));
    }
}

//Edit Tables
function* editUser(payload) {
    console.log(payload);

    try {
        yield put(actions.addUserLoading(true));
        const result = yield call(api.editUsers, payload);
        yield put(actions.editUserSuccess(result.data));
        toast.success('Edit Successfully');
        payload?.action && payload?.action();
    } catch (error) {
        yield put(actions.editUserFail());
    } finally {
        yield put(actions.addUserLoading(false));
    }
}

// create
function* createUser(formData) {
    try {
        yield put(actions.addUserLoading(true));
        const result = yield call(api.createUsers, formData.payload);
        yield put(
            actions.createUserSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action();
    } catch (error) {
        yield put(actions.createUserFail());
    } finally {
        yield put(actions.addUserLoading(false));
    }
}

function* createSalePerson(formData) {
    try {
        yield put(actions.addUserLoading(true));
        const result = yield call(api.createSalePerson, formData.payload);
        yield put(
            actions.createSalePersonSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action();
    } catch (error) {
        yield put(actions.createSalePersonFail());
    } finally {
        yield put(actions.addUserLoading(false));
    }
}

function* deleteUser({ payload }) {
    try {
        yield put(actions.addUserLoading(true));
        const result = yield call(api.deleteUser, payload);

        yield put(actions.deleteUserSuccess(payload));

        toast.success('Deleted Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteUserFail());
    } finally {
        yield put(actions.addUserLoading(false));
    }
}

export default function* userSaga() {
    yield takeLatest(Types.GET_ROLES_REQUEST, getRoles);
    yield takeLatest(Types.GET_USERS_REQUEST, getAllUsers);
    yield takeLatest(Types.EDIT_USER_REQUEST, editUser);
    yield takeLatest(Types.CREATE_USER_REQUEST, createUser);
    yield takeLatest(Types.DELETE_USER_REQUEST, deleteUser);
    yield takeLatest(Types.CREATE_SALESPERSON_REQUEST, createSalePerson);
}
