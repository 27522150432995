export default {
    GET_VISITS_BY_SPID_REQUEST: 'GET_VISITS_BY_SPID_REQUEST',
    GET_VISITS_BY_SPID_SUCCESS: 'GET_VISITS_BY_SPID_SUCCESS',
    GET_VISITS_BY_SPID_FAIL: 'GET_VISITS_BY_SPID_FAIL',

    GET_VISIT_DETAILS_REQUEST: 'GET_VISIT_DETAILS_REQUEST',
    GET_VISIT_DETAILS_SUCCESS: 'GET_VISIT_DETAILS_SUCCESS',
    GET_VISIT_DETAILS_FAIL: 'GET_VISIT_DETAILS_FAIL',
    CREATE_VISIT_REQUEST: 'CREATE_VISIT_REQUEST',
    CREATE_VISIT_SUCCESS: 'CREATE_VISIT_SUCCESS',
    CREATE_VISIT_FAIL: 'CREATE_VISIT_FAIL',

    GET_VISIT_LOADING: 'GET_VISIT_LOADING'
};
