import Types from './Types';


export const getAllTeacherTypesRequest = (id) => ({
    type: Types.GET_TEACHER_TYPE_REQUEST,
    payload: id
});

export const getAllTeacherTypesSuccess = (data) => ({
    type: Types.GET_TEACHER_TYPE_SUCCESS,
    payload: data
});

export const getAllTeacherTypesFail = () => ({
    type: Types.GET_TEACHER_TYPE_FAIL
});




//LOADING
export const addTeacherTypesLoading = (isLoading) => ({
    type: Types.ADD_TEACHER_TYPE_LOADING,
    payload: isLoading
});

// Create TEACHER_TYPE
export const createTeacherTypesRequest = (formData, action) => ({
    type: Types.CREATE_TEACHER_TYPE_REQUEST,
    payload: { ...formData },
    action: action
});

export const createTeacherTypesSuccess = ({ data }) => ({
    type: Types.CREATE_TEACHER_TYPE_SUCCESS,
    payload: {
        data
    }
});

export const createTeacherTypesFail = () => ({
    type: Types.CREATE_TEACHER_TYPE_FAIL
});

export const deleteTeacherTypesRequest = (id) => ({
    type: Types.DELETE_TEACHER_TYPE_REQUEST,
    payload: id
});

export const deleteTeacherTypesSuccess = (id) => ({
    type: Types.DELETE_TEACHER_TYPE_SUCCESS,
    payload: id
});

export const deleteTeacherTypesFail = () => ({
    type: Types.DELETE_TEACHER_TYPE_FAIL
});
