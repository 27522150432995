import Types from './Types';


export const getAllPlansRequest = (id) => ({
    type: Types.GET_PLANS_REQUEST,
    payload: id
});

export const getAllPlansSuccess = (data) => ({
    type: Types.GET_PLANS_SUCCESS,
    payload: data
});

export const getAllPlansFail = () => ({
    type: Types.GET_PLANS_FAIL
});

export const getPlansRequest = () => ({
    type: Types.GET_PLANS_DATA_REQUEST,

});

export const getPlansSuccess = (data) => ({
    type: Types.GET_PLANS_DATA_SUCCESS,
    payload: data
});

export const getPlansFail = () => ({
    type: Types.GET_PLANS_DATA_FAIL
});


export const editPlanRequest = (formData, action) => ({
    type: Types.EDIT_PLAN_REQUEST,
    payload: formData,
    action: action
});

export const editPlanSuccess = (data) => ({
    type: Types.EDIT_PLAN_SUCCESS,
    payload: data
});

export const editPlanFail = () => ({
    type: Types.EDIT_PLAN_FAIL
});

export const addPlanToUserRequest = (formData, action) => ({
    type: Types.ADD_PLAN_REQUEST,
    payload: formData,
    action: action
});

export const addPlanToUserSuccess = (data) => ({
    type: Types.ADD_PLAN_SUCCESS,
    payload: data
});

export const addPlanToUserFail = () => ({
    type: Types.ADD_PLAN_FAIL
});

//LOADING
export const addPlanLoading = (isLoading) => ({
    type: Types.ADD_PLANS_LOADING,
    payload: isLoading
});

// Create Plan
export const createPlanRequest = (formData, action) => ({
    type: Types.CREATE_PLAN_REQUEST,
    payload: { ...formData },
    action: action
});

export const createPlanSuccess = ({ data }) => ({
    type: Types.CREATE_PLAN_SUCCESS,
    payload: {
        data
    }
});

export const createPlanFail = () => ({
    type: Types.CREATE_PLAN_FAIL
});

export const deletePlanRequest = (id) => ({
    type: Types.DELETE_PLAN_REQUEST,
    payload: id
});

export const deletePlanSuccess = (id) => ({
    type: Types.DELETE_PLAN_SUCCESS,
    payload: id
});

export const deletePlanFail = () => ({
    type: Types.DELETE_PLAN_FAIL
});
