import Types from './Types';


export const getAllSchoolsRequest = (formData, page) => ({
    type: Types.GET_SCHOOLS_REQUEST,
    payload: formData,
    page: page
});

export const getAllSchoolsSuccess = (data) => ({
    type: Types.GET_SCHOOLS_SUCCESS,
    payload: data
});

export const getAllSchoolsFail = () => ({
    type: Types.GET_SCHOOLS_FAIL
});


export const editSchoolRequest = (formData, action) => ({
    type: Types.EDIT_SCHOOL_REQUEST,
    payload: formData,
    action: action
});

export const editSchoolSuccess = (data) => ({
    type: Types.EDIT_SCHOOL_SUCCESS,
    payload: data
});

export const editSchoolFail = () => ({
    type: Types.EDIT_SCHOOL_FAIL
});

//LOADING
export const addSchoolLoading = (isLoading) => ({
    type: Types.ADD_SCHOOLS_LOADING,
    payload: isLoading
});

// Create School
export const createSchoolRequest = (formData, action) => ({
    type: Types.CREATE_SCHOOL_REQUEST,
    payload: { ...formData },
    action: action
});

export const createSchoolSuccess = ({ data }) => ({
    type: Types.CREATE_SCHOOL_SUCCESS,
    payload: {
        data
    }
});

export const createSchoolFail = () => ({
    type: Types.CREATE_SCHOOL_FAIL
});

export const deleteSchoolRequest = (id) => ({
    type: Types.DELETE_SCHOOL_REQUEST,
    payload: id
});

export const deleteSchoolSuccess = (id) => ({
    type: Types.DELETE_SCHOOL_SUCCESS,
    payload: id
});

export const deleteSchoolFail = () => ({
    type: Types.DELETE_SCHOOL_FAIL
});
