import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Flex,
    Text,
    Box,
    Button
} from '@chakra-ui/react';

import { Formik, Field, Form } from 'formik';

import { getAllGovernmentsRequest } from '../../modules/governments/Actions';
import { getAllAreaVillagesRequest } from '../../modules/areaVillage/Actions';

import Select from 'react-select';

import { createPlanRequest, editPlanRequest } from '../../modules/plans/Actions';
import createPlanSchema from '../Schemas/createPlan';
import { getMunicipalitytsByGovIDRequest } from '../../modules/municipality/Actions';
import { closeCreatePlanModel } from '../../modules/models/Actions';

const CreatePlan = ({ data, addEdit }) => {
    const model = useSelector((state) => state.model);

    const dispatch = useDispatch();
    const governments = useSelector((state) => state.governments.governments);
    const areaVillages = useSelector((state) => state.areaVillages.areaVillages);
    const municipalitys = useSelector((state) => state.municipalitys.municipalitys);
    const isLoading = useSelector((state) => state.plans.isLoading);
    const [govID, setGovID] = useState('');
    const [muniID, setMuniID] = useState('');
    useEffect(() => {
        dispatch(getAllGovernmentsRequest());
    }, []);

    useEffect(() => {
        if (data?.gov_id) setGovID(data?.gov_id);
    }, [data?.gov_id]);
    useEffect(() => {
        if (govID) {
            dispatch(getMunicipalitytsByGovIDRequest(govID));
        }
    }, [govID]);
    useEffect(() => {
        if (muniID) {
            dispatch(
                getAllAreaVillagesRequest({
                    body: {
                        name: '',
                        muni_id: muniID
                    },
                    page: undefined
                })
            );
        }
    }, [muniID]);

    const govOptions = governments?.map((data) => {
        return {
            label: data?.name,
            value: data?.id
        };
    });
    const munOptions = municipalitys?.map((data) => {
        return {
            label: data?.name,
            value: data?.id
        };
    });

    const areaVillagesOptions = areaVillages?.map((data) => {
        return {
            label: data?.name,
            value: data?.id
        };
    });

    return (
        <Modal
            isOpen={model.isOpen15}
            onClose={() => dispatch(closeCreatePlanModel())}
            size="2xl"
            closeOnOverlayClick={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader padding="15px" bg="main_2" color={'white'}>
                    {addEdit ? 'اضافة' : 'تعديل'}
                </ModalHeader>
                <ModalCloseButton
                    color={'white'}
                    right="90%"
                    onClick={() => dispatch(closeCreatePlanModel())}
                />
                <ModalBody>
                    <Formik
                        validationSchema={createPlanSchema}
                        initialValues={
                            !addEdit
                                ? { ...data }
                                : {
                                      name: '',
                                      gov_id: '',
                                      areas: '',
                                      muni_id: ''
                                  }
                        }
                        onSubmit={(values) => {
                            const areas = values?.areas?.map((data) => data?.value);
                            const action = () => {
                                dispatch(closeCreatePlanModel());
                            };
                            if (addEdit) {
                                dispatch(createPlanRequest({ ...values, areas: areas }, action));
                            } else {
                                dispatch(
                                    editPlanRequest(
                                        {
                                            id: data?.id,
                                            formData: {
                                                name: values.name,
                                                muni_id: values.muni_id,
                                                gov_id: values.gov_id,
                                                areas: areas
                                            }
                                        },
                                        action
                                    )
                                );
                            }
                        }}>
                        {({ values, errors, touched, handleSubmit, setValues }) => (
                            <Form>
                                <Flex flexDirection="column" justify="space-between" width={'100%'}>
                                    <Flex alignItems={'center'} justifyContent="space-between">
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width={'45%'}
                                            mb="20px">
                                            <label htmlFor="name" style={{ marginBlock: '10px' }}>
                                                الاسم
                                            </label>
                                            <Field
                                                id="name"
                                                name="name"
                                                placeholder=""
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #8080803b',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                            {errors.name ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Box>
                                    </Flex>

                                    <Flex flexDirection="column" w="100%">
                                        <label htmlFor="gov_id" style={{ marginBlock: '10px' }}>
                                            المحافظة
                                        </label>
                                        <Select
                                            options={govOptions}
                                            defaultValue={{
                                                label: data?.gov_name,
                                                value: data?.gov_id
                                            }}
                                            name="gov_id"
                                            onChange={(val) => {
                                                setGovID(val?.value);
                                                setValues({ ...values, gov_id: val?.value });
                                            }}
                                        />
                                        {errors.gov_id ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>

                                    <Flex flexDirection="column" w="100%">
                                        <label htmlFor="muni_id" style={{ marginBlock: '10px' }}>
                                            الادارات
                                        </label>
                                        <Select
                                            options={munOptions}
                                            defaultValue={{
                                                label: data?.muni_name,
                                                value: data?.muni_id
                                            }}
                                            name="muni_id"
                                            onChange={(val) => {
                                                setMuniID(val?.value);
                                                setValues({ ...values, muni_id: val?.value });
                                            }}
                                        />
                                        {errors.muni_id ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>

                                    <Flex flexDirection="column">
                                        <label htmlFor="areas" style={{ marginBlock: '10px' }}>
                                            القري\المدينة
                                        </label>

                                        <Select
                                            isMulti={true}
                                            options={areaVillagesOptions}
                                            name="areas"
                                            defaultValue={values?.areas}
                                            onChange={(val) => setValues({ ...values, areas: val })}
                                        />

                                        {errors.areas ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>

                                    <Button
                                        mt="20px"
                                        w="25%"
                                        type="submit"
                                        style={{ marginBlock: '10px' }}
                                        bg="main_2"
                                        isLoading={isLoading}
                                        alignSelf={'flex-end'}
                                        color="white">
                                        حفظ التغيرات
                                    </Button>
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CreatePlan;
