import Types from './Types';

const INITIAL_STATE = {
    teachers: [],
    teacher: {},
    isLoading: false,
    count: ''
};

export default function teachers(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_TEACHERS_SUCCESS: {
            console.log(payload)
            return {
                ...state,
                teachers: payload.message ? [] : payload.data.data,
                count: payload.message ? 0 : payload.data.pagination.total_records
            };
        }

        case Types.GET_TEACHER_SUCCESS: {
            return {
                ...state,
                teacher: payload
            };
        }

        case Types.EDIT_TEACHER_SUCCESS: {
            let data = payload.data;
            const teachers = state.teachers;
            let idx = 0;
            teachers.forEach((table, index) => {
                if (table.id === data.id) {
                    idx = index;
                    return;
                }
            });

            teachers[idx] = data;
            return {
                ...state,
                teachers: teachers
            };
        }

        // create
        case Types.CREATE_TEACHER_SUCCESS: {
            console.log(payload)
            return {
                ...state,
                teachers: state.teachers.concat(payload.data.data)
            };
        }
        case Types.ADD_TEACHERS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        case Types.DELETE_TEACHER_SUCCESS: {
            const newteachers = state.teachers.filter((item) => item.id !== payload);
            return {
                ...state,
                teachers: newteachers
            };
        }
        default: {
            return state;
        }
    }
}
