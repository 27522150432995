import axiosInstance from '../../helpers/api';

export const getVistisDySPId = (data) => {
    return axiosInstance.post(`/visits/search?page=${data?.page}`, data?.payload);
};

export const getVisitDetails = (id) => {
    return axiosInstance.get(`/visits/${id}`);
};

export const createVisit = (data) => {
    console.log('creata');

    return axiosInstance.post(`/visits`, data);
};
