import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DashboardLayout from '../../components/Layout/DashboardLayout';
import { openCreateWholeSellerModel } from '../../modules/models/Actions';
import { useSelector } from 'react-redux';
import {
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuItemOption,
    MenuList,
    MenuOptionGroup
} from '@chakra-ui/react';
import CTable from '../../components/table/table';
import { HiDotsVertical } from 'react-icons/hi';
import { RiDeleteBin6Line, RiFileEditLine } from 'react-icons/ri';

import AuthWrapper from '../../components/Auth/AuthWrapper';

import { getAllGovernmentsRequest } from '../../modules/governments/Actions';

import {
    deleteWholesalerRequest,
    getAllWholesalersRequest
} from '../../modules/wholesalers/Actions';
import CreateWholesaler from '../../components/models/createWholwsallers';

const Wholesalers = () => {
    const dispatch = useDispatch();
    const [addEdit, setAddEdit] = useState(true);
    const wholesalers = useSelector((state) => state.wholesalers.wholesalers);
    const governments = useSelector((state) => state.governments.governments);

    const isLoading = useSelector((state) => state.wholesalers.isLoading);
    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [govID, setGovID] = useState('');

    const [selected, setSelected] = useState({
        id: ''
    });
    useEffect(() => {
        if (governments) setGovID(governments[0]?.id);
    }, [governments]);

    useEffect(() => {
        if (govID) dispatch(getAllWholesalersRequest(govID));
    }, [govID]);

    useEffect(() => {
        dispatch(getAllGovernmentsRequest());
    }, []);
    const Data = wholesalers?.map((data) => {
        return {
            ...data,
            id: data?.id,
            name: data?.name,
            gov_id: data?.area?.gov_id,
            area_vil: data?.area?.id,
            phone: data?.phone,
            govName: data?.gov
        };
    });

    const actions = (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HiDotsVertical />}
                size="sm"
                fontSize="20px"
                variant="outline"
                border="none"
            />
            <MenuList>
                <MenuItem
                    icon={<RiFileEditLine />}
                    onClick={() => {
                        setAddEdit(false);
                        dispatch(openCreateWholeSellerModel());
                    }}>
                    تعديل
                </MenuItem>
                <MenuItem
                    onClick={() => dispatch(deleteWholesalerRequest(selected?.id))}
                    icon={<RiDeleteBin6Line />}>
                    حذف
                </MenuItem>
            </MenuList>
        </Menu>
    );
    const columns = [
        {
            Header: 'الاسم',
            accessor: 'name'
        },
        {
            Header: 'الهاتف',
            accessor: 'phone'
        },
        {
            Header: 'المحافظة',
            accessor: 'govName'
        },
        {
            Header: '',
            accessor: 'Actions'
        }
    ];

    const handelFilter = (e) => {
        console.log(e);
        setGovID(e);
    };
    const viewData = (data) => {
        setSelected(data);
    };

    const filterList = (
        <MenuOptionGroup type="radio" value={govID}>
            {governments.map((government) => {
                return (
                    <MenuItemOption
                        onClick={() => handelFilter(government?.id)}
                        value={government?.id}>
                        {government?.name}
                    </MenuItemOption>
                );
            })}
        </MenuOptionGroup>
    );
    return (
        <AuthWrapper roles={['Super Admin']}>
            <DashboardLayout title={' تجار الجملة'}>
                <CTable
                    selectedData={viewData}
                    Columns={columns}
                    Data={Data}
                    path={openCreateWholeSellerModel}
                    Actions={actions}
                    Title=" "
                    subTitle={``}
                    btnTitle="hi"
                    placeHolder=""
                    noSearchBar={true}
                    noFilter={false}
                    footerBtnTitle
                    filterList={filterList}
                    addButton
                    addButtonTitle="اضافة  "
                    setPage={setPage}
                    setPerPage={setPerPage}
                    currentpage={pageNumber}
                    setPageNumber={setPageNumber}
                    noSecondSearchBar={true}
                    isLoading={isLoading}
                    perPage={size}
                    totalPage={Math.ceil(wholesalers?.length / 10)}
                    setAddEdit={setAddEdit}
                />

                <CreateWholesaler data={selected} addEdit={addEdit} />
            </DashboardLayout>
        </AuthWrapper>
    );
};

export default Wholesalers;
