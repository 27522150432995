import Types from './Types';

const INITIAL_STATE = {
    centers: [],
    center: {},
    isLoading: false,
    count: ''
};

export default function centers(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_CENTERS_SUCCESS: {
            console.log(payload)
            return {
                ...state,
                centers: payload.data,
                count: payload.data.length
            };
        }

        case Types.GET_CENTER_SUCCESS: {
            return {
                ...state,
                center: payload
            };
        }

        case Types.EDIT_CENTER_SUCCESS: {
            let data = payload.data;
            const centers = state.centers;
            let idx = 0;
            centers.forEach((table, index) => {
                if (table.id === data.id) {
                    idx = index;
                    return;
                }
            });

            centers[idx] = data;
            return {
                ...state,
                centers: centers
            };
        }

        // create
        case Types.CREATE_CENTER_SUCCESS: {
            console.log(payload)
            return {
                ...state,
                centers: state.centers.concat(payload.data.data)
            };
        }
        case Types.ADD_CENTERS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        case Types.DELETE_CENTER_SUCCESS: {
            const newcenters = state.centers.filter((item) => item.id !== payload);
            return {
                ...state,
                centers: newcenters
            };
        }
        default: {
            return state;
        }
    }
}
