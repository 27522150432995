export default {
    GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
    GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
    GET_ROLES_FAIL: 'GET_ROLES_FAIL',
    //get all users
    GET_USERS_REQUEST: 'GET_USERS_REQUEST',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
    GET_USERS_FAIL: 'GET_USERS_FAIL',

    //edit users
    EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
    EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
    EDIT_USER_FAIL: 'EDIT_USER_FAIL',

    //create user
    CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_FAIL: 'CREATE_USER_FAIL',

    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAIL: 'DELETE_USER_FAIL',

    GET_USER_REQUEST: 'GET_USER_REQUEST',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAIL: 'GET_USER_FAIL',

    CREATE_SALESPERSON_REQUEST: 'CREATE_SALESPERSON_REQUEST',
    CREATE_SALESPERSON_SUCCESS: 'CREATE_SALESPERSON_SUCCESS',
    CREATE_SALESPERSON_FAIL: 'CREATE_SALESPERSON_FAIL',

    //loading
    ADD_USERS_LOADING: 'ADD_USERS_LOADING'
};
