import axiosInstance from '../../helpers/api';

export const getAllSubjects = (body) => {
    return axiosInstance.get('/subjects', body);
};



export const editSubjects = (formData) => {

    return axiosInstance.put(`/subjects/${formData.id}`, formData.formData);
};


export const createSubjects = (formData) => {
    return axiosInstance.post('/subjects', formData);
};

export const deleteSubject = (id) => {
    return axiosInstance.delete(`/subjects/${id}`);
};
