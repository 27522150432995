export default {
    //get all users
    GET_BOOKSTORES_REQUEST: 'GET_BOOKSTORES_REQUEST',
    GET_BOOKSTORES_SUCCESS: 'GET_BOOKSTORES_SUCCESS',
    GET_BOOKSTORES_FAIL: 'GET_BOOKSTORES_FAIL',

    //edit users
    EDIT_BOOKSTORE_REQUEST: 'EDIT_BOOKSTORE_REQUEST',
    EDIT_BOOKSTORE_SUCCESS: 'EDIT_BOOKSTORE_SUCCESS',
    EDIT_BOOKSTORE_FAIL: 'EDIT_BOOKSTORE_FAIL',

    //create user
    CREATE_BOOKSTORE_REQUEST: 'CREATE_BOOKSTORE_REQUEST',
    CREATE_BOOKSTORE_SUCCESS: 'CREATE_BOOKSTORE_SUCCESS',
    CREATE_BOOKSTORE_FAIL: 'CREATE_BOOKSTORE_FAIL',

    DELETE_BOOKSTORE_REQUEST: 'DELETE_BOOKSTORE_REQUEST',
    DELETE_BOOKSTORE_SUCCESS: 'DELETE_BOOKSTORE_SUCCESS',
    DELETE_BOOKSTORE_FAIL: 'DELETE_BOOKSTORE_FAIL',

    GET_BOOKSTORE_REQUEST: 'GET_BOOKSTORE_REQUEST',
    GET_BOOKSTORE_SUCCESS: 'GET_BOOKSTORE_SUCCESS',
    GET_BOOKSTORE_FAIL: 'GET_BOOKSTORE_FAIL',

    //loading
    ADD_BOOKSTORES_LOADING: 'ADD_BOOKSTORES_LOADING'
};
