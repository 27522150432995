import { Box, Flex, IconButton } from '@chakra-ui/react';
import React from 'react';
import { GrFormNext, GrFormPrevious } from 'react-icons/gr';

const CFooter = ({
    pageIndex,
    pageOptions,
    footerBtnTitle,
    setPage,
    totalPage,
    currentpage,
    setPageNumber
}) => {
    return (
        <Flex justify="space-between" align="center" flexDir="row-reverse">
            <Box mb="6">
                <Flex mt="2">
                    <span
                        style={{
                            marginTop: '6px',
                            marginRight: '5px'
                        }}>
                        <strong
                            style={{
                                color: 'black',
                                marginTop: '5px'
                            }}>
                            {pageIndex + 1} / {pageOptions.length}
                        </strong>{' '}
                    </span>

                    <IconButton
                        mr="1"
                        ml="1"
                        bg="main_2"
                        color="white"
                        borderRadius="20px"
                        aria-label="previousPage"
                        onClick={() => {
                            setPage((s) => (s === 0 ? 0 : s - 10));
                            setPageNumber((s) => (s === 0 ? 0 : s - 1));
                        }}
                        disabled={pageIndex === 0}
                        icon={
                            <GrFormNext
                                style={{
                                    color: 'white'
                                }}
                            />
                        }
                    />
                    <IconButton
                        mr="1"
                        ml="1"
                        color="white"
                        bg="main_2"
                        borderRadius="20px"
                        aria-label="nextPage"
                        onClick={() => {
                            setPage((s) => s + 10);
                            setPageNumber((s) => s + 1);
                        }}
                        disabled={pageIndex === totalPage - 1}
                        icon={
                            <GrFormPrevious
                                style={{
                                    color: 'white'
                                }}
                            />
                        }
                    />
                </Flex>
            </Box>
        </Flex>
    );
};

export default CFooter;
