import Types from './Types';


export const getAllBookstoresRequest = (id, page) => ({
    type: Types.GET_BOOKSTORES_REQUEST,
    id: id,
    page: page
});

export const getAllBookstoresSuccess = (data) => ({
    type: Types.GET_BOOKSTORES_SUCCESS,
    payload: data
});

export const getAllBookstoresFail = () => ({
    type: Types.GET_BOOKSTORES_FAIL
});


export const editBookstoreRequest = (formData, action) => ({
    type: Types.EDIT_BOOKSTORE_REQUEST,
    payload: formData,
    action: action
});

export const editBookstoreSuccess = (data) => ({
    type: Types.EDIT_BOOKSTORE_SUCCESS,
    payload: data
});

export const editBookstoreFail = () => ({
    type: Types.EDIT_BOOKSTORE_FAIL
});

//LOADING
export const addBookstoreLoading = (isLoading) => ({
    type: Types.ADD_BOOKSTORES_LOADING,
    payload: isLoading
});

// Create Bookstores
export const createBookstoreRequest = (formData, action) => ({
    type: Types.CREATE_BOOKSTORE_REQUEST,
    payload: { ...formData },
    action: action
});

export const createBookstoreSuccess = ({ data }) => ({
    type: Types.CREATE_BOOKSTORE_SUCCESS,
    payload: {
        data
    }
});

export const createBookstoreFail = () => ({
    type: Types.CREATE_BOOKSTORE_FAIL
});

export const deleteBookstoreRequest = (id) => ({
    type: Types.DELETE_BOOKSTORE_REQUEST,
    payload: id
});

export const deleteBookstoreSuccess = (id) => ({
    type: Types.DELETE_BOOKSTORE_SUCCESS,
    payload: id
});

export const deleteBookstoreFail = () => ({
    type: Types.DELETE_BOOKSTORE_FAIL
});
