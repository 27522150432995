import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Flex,
    Text,
    Box,
    Button,
    Input
} from '@chakra-ui/react';
import { closeCreateVisitModel } from '../../modules/models/Actions';
import { Formik, Field, Form } from 'formik';

import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import createTacherSchema from '../Schemas/createTeacher';

import { getAllBooksRequest } from '../../modules/books/Actions';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { createVisitRequest } from '../../modules/visits/Actions';

const CreateVisit = ({ data, addEdit }) => {
    const model = useSelector((state) => state.model);
    const animatedComponents = makeAnimated();

    const dispatch = useDispatch();
    const [startDate, setStartDate] = useState(new Date());
    const [booksCount, setBooksCount] = useState(1);
    const isLoading = useSelector((state) => state.plans.isLoading);
    const books = useSelector((state) => state.books.books);

    useEffect(() => {
        dispatch(getAllBooksRequest());
    }, []);

    const booksOptions = books?.map((data) => {
        return {
            label: data?.title,
            value: data?.id
        };
    });

    const status = [
        {
            label: ' لديه مخزون',
            value: '0'
        },
        {
            label: ' زيارة ناجحة',
            value: '1'
        },
        {
            label: ' ليس لديه مخزون',
            value: '2'
        },
        {
            label: ' اسباب اخري',
            value: '3'
        }
    ];
    return (
        <Modal
            isOpen={model.isOpen19}
            onClose={() => dispatch(closeCreateVisitModel())}
            size="2xl"
            closeOnOverlayClick={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader padding="15px" bg="main_2" color={'white'}>
                    {'اضافة'}
                </ModalHeader>
                <ModalCloseButton
                    color={'white'}
                    right="90%"
                    onClick={() => dispatch(closeCreateVisitModel())}
                />
                <ModalBody>
                    <Formik
                        initialValues={{
                            date: '',
                            notes: '',
                            teacher_id: data?.id,
                            status: '',
                            books: [
                                {
                                    book_id: '',
                                    book_sale_quantity: ''
                                }
                            ]
                        }}
                        onSubmit={(values) => {
                            const action = () => {
                                dispatch(closeCreateVisitModel());
                            };

                            dispatch(createVisitRequest({ values, action }));
                        }}>
                        {({ values, errors, handleChange, handleSubmit, setValues }) => (
                            <Form>
                                {console.log(values)}

                                <Flex flexDirection="column" justify="space-between" width={'100%'}>
                                    <Flex
                                        alignItems={'center'}
                                        justifyContent="space-between"></Flex>

                                    <Flex
                                        alignItems={'start'}
                                        justifyContent="space-between"
                                        w="100%">
                                        <Flex flexDirection="column" w="100%">
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                width={'85%'}
                                                mb="20px">
                                                <label
                                                    htmlFor="type"
                                                    style={{ marginBlock: '10px' }}>
                                                    status
                                                </label>
                                                <Select
                                                    options={status}
                                                    name="status"
                                                    onChange={(val) => {
                                                        setValues({
                                                            ...values,
                                                            status: val?.value
                                                        });
                                                    }}
                                                />

                                                {errors.status ? (
                                                    <Text color="red">هذا الحقل مطلوب</Text>
                                                ) : null}
                                            </Box>
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                width={'85%'}
                                                mb="20px">
                                                <label
                                                    htmlFor="notes"
                                                    style={{ marginBlock: '10px' }}>
                                                    الملاحظات
                                                </label>
                                                <Field
                                                    id="notes"
                                                    name="notes"
                                                    placeholder=""
                                                    style={{
                                                        width: '100%',
                                                        border: '1px solid #8080803b',
                                                        padding: '5px',
                                                        borderRadius: '5px'
                                                    }}
                                                />
                                                {errors.notes ? (
                                                    <Text color="red">هذا الحقل مطلوب</Text>
                                                ) : null}
                                            </Box>
                                        </Flex>

                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width={'45%'}
                                            mb="20px">
                                            <label htmlFor="date" style={{ marginBlock: '10px' }}>
                                                التاريخ
                                            </label>
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date) => {
                                                    console.log(date);
                                                    setStartDate(date);
                                                    setValues({
                                                        ...values,
                                                        date: moment(date).format('YYYY-MM-DD')
                                                    });
                                                }}
                                                inline
                                            />
                                            {errors.date ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Box>
                                    </Flex>

                                    <Flex flexDirection="column" w="100%">
                                        <Flex alignItems="center" justifyContent="space-between">
                                            <label
                                                htmlFor="subjects"
                                                style={{ marginBlock: '10px' }}>
                                                books
                                            </label>
                                            <Button
                                                onClick={() =>
                                                    setValues({
                                                        ...values,
                                                        books: [
                                                            ...values.books,
                                                            {
                                                                book_id: '',
                                                                book_sale_quantity: ''
                                                            }
                                                        ]
                                                    })
                                                }>
                                                Add book
                                            </Button>
                                        </Flex>

                                        {values.books.map((val, index) => {
                                            return (
                                                <Flex
                                                    style={{ gap: '20px', marginBlock: '10px' }}
                                                    w="100%">
                                                    <Box style={{ width: '40%' }}>
                                                        <Select
                                                            closeMenuOnSelect={true}
                                                            components={animatedComponents}
                                                            options={booksOptions}
                                                            onChange={(val) => {
                                                                const newBooks = values?.books;

                                                                newBooks[`${index}`] = {
                                                                    ...newBooks[`${index}`],
                                                                    book_id: val?.value
                                                                };
                                                                console.log(newBooks, val);
                                                                setValues({
                                                                    ...values,
                                                                    books: newBooks
                                                                });
                                                            }}
                                                        />
                                                    </Box>
                                                    <input
                                                        className="form-control"
                                                        placeholder=""
                                                        autoComplete="off"
                                                        style={{ width: '30%' }}
                                                        value={val.book_sale_quantity}
                                                        onChange={(val) => {
                                                            const newBooks = values?.books;
                                                            newBooks[`${index}`] = {
                                                                ...newBooks[`${index}`],
                                                                book_sale_quantity:
                                                                    val?.target.value
                                                            };
                                                            setValues({
                                                                ...values,
                                                                books: newBooks
                                                            });
                                                        }}
                                                    />{' '}
                                                    <Button
                                                        onClick={() => {
                                                            let newData = values.books;
                                                            const filteredData = newData.filter(
                                                                (val, idx) => idx != index
                                                            );
                                                            setValues({
                                                                ...values,
                                                                books: filteredData
                                                            });

                                                            setBooksCount(booksCount - 1);
                                                        }}>
                                                        -
                                                    </Button>
                                                </Flex>
                                            );
                                        })}
                                    </Flex>

                                    <Button
                                        mt="20px"
                                        w="25%"
                                        type="submit"
                                        style={{ marginBlock: '10px' }}
                                        bg="main_2"
                                        isLoading={isLoading}
                                        alignSelf={'flex-end'}
                                        color="white">
                                        حفظ التغيرات
                                    </Button>
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CreateVisit;
