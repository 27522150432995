import { Box, Flex, IconButton, Button, Heading } from '@chakra-ui/react';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import SideNavbar from '../Sidenavbar';
import { BiHomeCircle, BiMenu } from 'react-icons/bi';
import MobileNavbar from '../Sidenavbar/mobileNavbar';
import { useSelector } from 'react-redux';
const Header = ({ displayData, setDisplayData, title, custamHeight = '140px' }) => {
    const [displayMobile, setDisplayMobile] = useState(false);
    const userData = useSelector((state) => state.userData.userData);
    return (
        <>
            <Flex position={'fixed'} w="100%" zIndex={'10'} h={custamHeight}>
                <Box
                    borderLeft={'1px solid #7774741a'}
                    w="25%"
                    display={{ base: 'none', md: displayData }}
                    bg="white"
                    boxShadow="rgba(0, 0, 0, 0.1) -4px 9px 25px -6px"
                    h="100vh">
                    <SideNavbar />
                </Box>
                <Box display={{ base: 'block', md: 'none' }}>
                    <MobileNavbar isDisplay={displayMobile} setIsDisplay={setDisplayMobile} />
                </Box>
                <Box
                    h={custamHeight}
                    alignItems="center"
                    w={{ base: '100%', md: displayData == 'block' ? '75%' : '100%' }}
                    bg="white"
                    boxShadow="rgba(0, 0, 0, 0.1) -4px 9px 25px -6px"
                    transition={'all 0.5s linear'}>
                    <Flex
                        borderBottom={'1px solid #7774741a'}
                        w="100%"
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        p={2}>
                        <Box>
                            <IconButton
                                aria-label="Search database"
                                display={{ base: 'none', md: 'inline' }}
                                p="11px"
                                icon={<BiMenu />}
                                mx="10px"
                                onClick={() =>
                                    setDisplayData(displayData == 'block' ? 'none' : 'block')
                                }
                            />
                            <IconButton
                                aria-label="Search database"
                                display={{ base: 'inline', md: 'none' }}
                                p="11px"
                                icon={<BiMenu />}
                                mx="10px"
                                onClick={() =>
                                    setDisplayMobile(displayMobile == 'block' ? 'none' : 'block')
                                }
                            />

                            {userData?.role?.[0]?.name == 'Super Admin' && (
                                <Button
                                    leftIcon={<BiHomeCircle />}
                                    variant="solid"
                                    onClick={() => window.location.replace('/municipality')}>
                                    الصفحة الرئيسية
                                </Button>
                            )}
                        </Box>
                        <Box>
                            <Button
                                variant="solid"
                                onClick={() => {
                                    Cookies.remove('token');
                                    window.location.reload();
                                }}>
                                تسجيل الخروج
                            </Button>
                        </Box>
                    </Flex>
                    <Heading as="h3" color={'main_1'} mt="10px" px={2}>
                        {title}
                    </Heading>
                </Box>
            </Flex>
        </>
    );
};

export default Header;
