export default {
    //get all users
    GET_PLANS_REQUEST: 'GET_PLANS_REQUEST',
    GET_PLANS_SUCCESS: 'GET_PLANS_SUCCESS',
    GET_PLANS_FAIL: 'GET_PLANS_FAIL',


    GET_PLANS_DATA_REQUEST: 'GET_PLANS_DATA_REQUEST',
    GET_PLANS_DATA_SUCCESS: 'GET_PLANS_DATA_SUCCESS',
    GET_PLANS_DATA_FAIL: 'GET_PLANS_DATA_FAIL',
    //edit users
    EDIT_PLAN_REQUEST: 'EDIT_PLAN_REQUEST',
    EDIT_PLAN_SUCCESS: 'EDIT_PLAN_SUCCESS',
    EDIT_PLAN_FAIL: 'EDIT_PLAN_FAIL',

    ADD_PLAN_REQUEST: 'ADD_PLAN_REQUEST',
    ADD_PLAN_SUCCESS: 'ADD_PLAN_SUCCESS',
    ADD_PLAN_FAIL: 'ADD_PLAN_FAIL',

    //create user
    CREATE_PLAN_REQUEST: 'CREATE_PLAN_REQUEST',
    CREATE_PLAN_SUCCESS: 'CREATE_PLAN_SUCCESS',
    CREATE_PLAN_FAIL: 'CREATE_PLAN_FAIL',

    DELETE_PLAN_REQUEST: 'DELETE_PLAN_REQUEST',
    DELETE_PLAN_SUCCESS: 'DELETE_PLAN_SUCCESS',
    DELETE_PLAN_FAIL: 'DELETE_PLAN_FAIL',

    GET_PLAN_REQUEST: 'GET_PLAN_REQUEST',
    GET_PLAN_SUCCESS: 'GET_PLAN_SUCCESS',
    GET_PLAN_FAIL: 'GET_PLAN_FAIL',

    //loading
    ADD_PLANS_LOADING: 'ADD_PLANS_LOADING'
};
