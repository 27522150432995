import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DashboardLayout from '../../components/Layout/DashboardLayout';
import { openCreateTeacherrModel } from '../../modules/models/Actions';
import { useSelector } from 'react-redux';
import {
    Button,
    Flex,
    IconButton,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Text
} from '@chakra-ui/react';
import CTable from '../../components/table/table';
import { HiDotsVertical } from 'react-icons/hi';
import { RiDeleteBin6Line, RiFileEditLine } from 'react-icons/ri';
import { getAllSubjectsRequest } from '../../modules/subjects/Actions';
import AuthWrapper from '../../components/Auth/AuthWrapper';
import { deleteTeacherRequest, getAllTeachersRequest } from '../../modules/teachers/Actions';
import CreateTeacher from '../../components/models/createTeacher';
import { getAllTeacherTypesRequest } from '../../modules/teachers-types/Actions';
import GovAccordion from '../../components/UI/govAccordion';

const Teachers = () => {
    const dispatch = useDispatch();
    const [addEdit, setAddEdit] = useState(true);
    const [step, setStep] = useState(true);
    const teachers = useSelector((state) => state.teachers.teachers);
    const count = useSelector((state) => state.teachers.count);

    const subjects = useSelector((state) => state.subjects.subjects);
    const types = useSelector((state) => state.types.types);

    const isLoading = useSelector((state) => state.teachers.isLoading);
    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(100);
    const [pageNumber, setPageNumber] = useState(0);
    const [govID, setGovID] = useState('');
    const [filterOptions, setFilterOptions] = useState({
        name: '',
        gov: govID,
        area: '',
        subject: ''
    });

    const [selected, setSelected] = useState({
        id: ''
    });

    useEffect(() => {
        if (govID) dispatch(getAllTeachersRequest({ muni_id: govID }, pageNumber + 1));
    }, [govID]);
    useEffect(() => {
        if (filterOptions)
            dispatch(getAllTeachersRequest({ ...filterOptions, muni_id: govID }, pageNumber + 1));
    }, [filterOptions, pageNumber]);

    useEffect(() => {
        dispatch(getAllSubjectsRequest());
        dispatch(getAllTeacherTypesRequest());
    }, []);
    const Data = teachers?.map((data) => {
        return {
            ...data,
            id: data?.id,
            name: data?.name,
            gov_id: data?.area?.gov_id,
            gov_name: data?.gov,
            area_vil: data?.area?.id,
            area_vil_name: data?.area?.name,
            muni_id: data?.area?.muni_id,
            muni_id_name: '',
            school_id: data?.school?.id,
            school_name: data?.school?.name,
            type: data?.type,
            subjects: data?.subject?.map((data) => {
                return { value: data.id, label: data?.name };
            }),
            grades: data?.grades?.map((data) => {
                return { value: data.id, label: data?.name };
            }),
            subjectsData: data?.subject?.map((val) => {
                return <Text>{val.name}</Text>;
            })
        };
    });

    const actions = (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HiDotsVertical />}
                size="sm"
                fontSize="20px"
                variant="outline"
                border="none"
            />
            <MenuList>
                <MenuItem
                    icon={<RiFileEditLine />}
                    onClick={() => {
                        setAddEdit(false);
                        dispatch(openCreateTeacherrModel());
                    }}>
                    تعديل
                </MenuItem>
                <MenuItem
                    onClick={() => dispatch(deleteTeacherRequest(selected?.id))}
                    icon={<RiDeleteBin6Line />}>
                    حذف
                </MenuItem>
            </MenuList>
        </Menu>
    );
    const columns = [
        {
            Header: 'الاسم',
            accessor: 'name'
        },
        {
            Header: 'الهاتف',
            accessor: 'phone'
        },
        {
            Header: 'النوع',
            accessor: 'type'
        },
        {
            Header: 'المدرسة',
            accessor: 'school_name'
        },
        {
            Header: 'المواد',
            accessor: 'subjectsData'
        },
        {
            Header: '',
            accessor: 'Actions'
        }
    ];

    const handelFilter = (value, name) => {
        setFilterOptions({ ...filterOptions, [name]: value });
    };
    const viewData = (data) => {
        setSelected(data);
    };

    return (
        <AuthWrapper roles={['Super Admin']}>
            <Flex
                position="fixed"
                zIndex="100"
                flexDirection={{ base: 'column', md: 'row' }}
                style={{
                    gap: '10px'
                }}
                left={step ? 0 : '150px'}
                top={{ base: '135px', md: '90px' }}>
                <Menu>
                    <MenuButton as={Button} bg="gradient_main_color" color="white">
                        المواد
                    </MenuButton>
                    <MenuList>
                        <MenuOptionGroup type="radio" value={filterOptions.subject}>
                            <MenuItemOption onClick={() => handelFilter('', 'subject')} value={''}>
                                الكل
                            </MenuItemOption>

                            {subjects.map((subject) => {
                                return (
                                    <MenuItemOption
                                        onClick={() => handelFilter(subject?.name, 'subject')}
                                        value={subject?.name}>
                                        {subject?.name}
                                    </MenuItemOption>
                                );
                            })}
                        </MenuOptionGroup>
                    </MenuList>
                </Menu>
                <Menu>
                    <MenuButton as={Button} bg="gradient_main_color" color="white" mx="10px">
                        النوع
                    </MenuButton>
                    <MenuList>
                        <MenuOptionGroup type="radio" value={filterOptions.type}>
                            <MenuItemOption onClick={() => handelFilter('', 'type')} value={''}>
                                الكل
                            </MenuItemOption>
                            {types.map((type) => {
                                return (
                                    <MenuItemOption
                                        onClick={() => handelFilter(type?.name, 'type')}
                                        value={type?.name}>
                                        {type?.name}
                                    </MenuItemOption>
                                );
                            })}
                        </MenuOptionGroup>
                    </MenuList>
                </Menu>

                <Flex mx="5px">
                    <Input
                        onChange={(e) => handelFilter(e.target.value, 'name')}
                        placeholder="بحث بالاسم"></Input>
                </Flex>
                <Flex mx="5px">
                    <Input
                        onChange={(e) => handelFilter(e.target.value, 'phone')}
                        placeholder="بحث برقم الهاتف"></Input>
                </Flex>
            </Flex>
            <DashboardLayout title={' المدرسين'} custamHeight={{ base: '380px', md: '140px' }}>
                {step ? (
                    <GovAccordion isLoading={isLoading} setStep={setStep} setGovID={setGovID} />
                ) : (
                    <>
                        <Button
                            left="2"
                            position={'fixed'}
                            onClick={() => setStep(true)}
                            zIndex="10">
                            الرجوع للمحافظات
                        </Button>
                        <CTable
                            selectedData={viewData}
                            Columns={columns}
                            Data={Data}
                            path={openCreateTeacherrModel}
                            Actions={actions}
                            Title=" "
                            subTitle={``}
                            btnTitle="hi"
                            placeHolder=""
                            noSearchBar={true}
                            noFilter={true}
                            footerBtnTitle
                            filterList
                            addButton
                            addButtonTitle="اضافة  "
                            setPage={setPage}
                            setPerPage={setPerPage}
                            currentpage={pageNumber}
                            setPageNumber={setPageNumber}
                            noSecondSearchBar={true}
                            isLoading={isLoading}
                            perPage={size}
                            totalPage={Math.ceil(count / 100)}
                            setAddEdit={setAddEdit}
                        />
                    </>
                )}
                <CreateTeacher data={selected} addEdit={addEdit} />
            </DashboardLayout>
        </AuthWrapper>
    );
};

export default Teachers;
