import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
    Flex,
    Box,
    Heading,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Icon
} from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { BiBuildings, BiLocationPlus } from 'react-icons/bi';

const SideNavbar = () => {
    const user = useSelector((state) => state.userData.userData);
    const [navData, setNavData] = useState([]);
    const AdminNavData = [
        {
            buttonName: 'التقسيم الجغرافي',
            icon: BiLocationPlus,
            data: [
                { name: 'الادارات', url: '/municipality' },
                { name: 'المدن/القري', url: '/areaVillages' }
            ]
        },
        {
            buttonName: ' اماكن الزيارات',
            icon: BiBuildings,
            data: [
                { name: 'مدارس', url: '/schools' },
                // { name: 'تاجر الجملة', url: '/wholesallers' },
                // { name: 'انواع المدرسين', url: '/teacher-type' },
                { name: 'مدرسين', url: '/teachers' },
                { name: 'سناتر', url: '/centers' },
                { name: 'مكتبات', url: '/bookstores' }
            ]
        },
        {
            buttonName: ' التقسيم التعليمي ',
            icon: BiLocationPlus,
            data: [
                { name: 'الكتب', url: '/books' },
                { name: 'المواد', url: '/subjects' },
                { name: 'السنوات الدراسية', url: '/grades' },
                { name: 'المؤلفين', url: '/authors' }
            ]
        },
        {
            buttonName: 'المستخدمين ',
            icon: BiLocationPlus,
            data: [
                { name: 'المديرين', url: '/users/moderators' },
                { name: 'مندوبي المبيعات', url: '/users/salePersons' }
            ]
        },
        {
            buttonName: 'الادارة والمتابعة',
            icon: BiBuildings,
            data: [
                { name: ' خطوط السير', url: '/plans' },
                { name: 'متابعة الزيارات', url: '/visits' },
                { name: ' الفواتير', url: '/' }
            ]
        }
    ];
    const ModeratorNavData = [
        {
            buttonName: 'الادارة والمتابعة',
            icon: BiBuildings,
            data: [
                { name: ' خطوط السير', url: '/plans' },
                { name: 'متابعة الزيارات', url: '/visits' },
                { name: ' الفواتير', url: '/' }
            ]
        },
        {
            buttonName: 'المستخدمين ',
            icon: BiLocationPlus,
            data: [{ name: 'مندوبي المبيعات', url: '/users/salePersons' }]
        }
    ];
    const SalesManNavData = [
        {
            buttonName: 'الادارة والمتابعة',
            icon: BiBuildings,
            data: [
                { name: ' خطوط السير', url: '/plans' },
                { name: ' المدرسين', url: '/salesperson' },
                { name: 'متابعة الزيارات', url: '/visits' }
            ]
        }
    ];
    useEffect(() => {
        if (user?.role) {
            if (user?.role[0]?.name === 'Moderator') setNavData(ModeratorNavData);
            if (user?.role[0]?.name === 'Super Admin') setNavData(AdminNavData);
            if (user?.role[0]?.name === 'Sale Person') setNavData(SalesManNavData);
        }
    }, [user]);
    return (
        <Flex
            direction="column"
            align="center"
            style={{ height: '100vh' }}
            display={'flex'}
            className="scroll">
            <Box h="140px" alignSelf={'center'} color="" mt="10px">
                {/* <img src='' alt='logo'/> */}
                <Heading color="main_1" p="3px"></Heading>
            </Box>
            {navData?.map((data) => {
                return (
                    <Accordion w={'100%'} allowMultiple allowToggle>
                        <AccordionItem border="0" my="10px">
                            <h2>
                                <AccordionButton
                                    _expanded={{ color: 'main_1' }}
                                    color="main_font"
                                    fontSize="20px"
                                    w="100%"
                                    _focus={{ outline: 'none' }}
                                    display={'flex'}
                                    alignItems="center"
                                    justifyContent={'space-between'}>
                                    <Flex alignItems={'center'}>
                                        <Icon as={data?.icon} />
                                        <Text textDecoration="none" mr="10px">
                                            {data?.buttonName}
                                        </Text>
                                    </Flex>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                {data?.data?.map((val) => {
                                    return (
                                        <NavLink
                                            to={val?.url}
                                            style={{
                                                textDecoration: 'none',
                                                color: 'currentColor'
                                            }}
                                            activeStyle={{
                                                fontWeight: 'bolder',
                                                bg: 'main_1',
                                                color: 'main_font'
                                            }}>
                                            <Box
                                                p="10px"
                                                color={
                                                    window.location.pathname == val?.url
                                                        ? 'white'
                                                        : 'main_font'
                                                }
                                                _hover={{
                                                    bg: 'main_1',
                                                    color: 'white'
                                                }}
                                                bg={
                                                    window.location.pathname == val?.url
                                                        ? 'main_1'
                                                        : ''
                                                }>
                                                {val?.name}
                                            </Box>
                                        </NavLink>
                                    );
                                })}
                            </AccordionPanel>
                        </AccordionItem>
                    </Accordion>
                );
            })}
        </Flex>
    );
};

export default SideNavbar;
