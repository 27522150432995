import * as Yup from 'yup';

const createCenterSchema = Yup.object().shape({

    name: Yup.string().min(2, 'Too Short!').max(70, 'Too Long!').required('Required'),
    gov_id: Yup.string(),
    address: Yup.string().required('Required'),
    area_vil: Yup.string().required('Required'),
    phone: Yup.string().min(11, 'Too Short!').max(12, 'Too Long!').required('Required'),
    owner_name: Yup.string().min(2, 'Too Short!').max(70, 'Too Long!').required('Required'),





});

export default createCenterSchema;