import React, { useEffect } from 'react';

import LoginForm from '../../components/Auth/LoginForm';
import { useSelector, useDispatch } from 'react-redux';
import Cookies from 'js-cookie';
import { getUserDataRequest } from '../../modules/userData/Actions';
import Loading from '../../components/UI/loading';

const Login = (props) => {
    const user = useSelector((state) => state.userData.userData);
    const isLoading = useSelector((state) => state.userData.isLoading);
    const token = Cookies.get('token');
    const dispatch = useDispatch();

    useEffect(() => {
        if (token) {
            dispatch(getUserDataRequest());
        }
        if (user.role) {
            if (user?.role[0]?.name == 'Super Admin') window.location.href = '/dashboard';
            if (user?.role[0]?.name == 'Teacher') window.location.href = '/dashboard';
            else if (user?.role[0]?.name == 'Student') window.location.href = '/';
            else if (user?.role[0]?.name == 'Sale Person') window.location.href = '/salesperson';
        }
    }, [user.role, token]);

    if (isLoading) <Loading />;

    return <LoginForm />;
};

export default Login;
