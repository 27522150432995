import Types from './Types';

const INITIAL_STATE = {
    wholesalers: [],
    bookstore: {},
    isLoading: false,
    count: ''
};

export default function wholesalers(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_WHOLESALERS_SUCCESS: {
            console.log(payload)
            return {
                ...state,
                wholesalers: payload.data,
                count: payload.data.length
            };
        }

        case Types.GET_WHOLESALER_SUCCESS: {
            return {
                ...state,
                wholesaler: payload
            };
        }

        case Types.EDIT_WHOLESALER_SUCCESS: {
            let data = payload.data;
            const wholesalers = state.wholesalers;
            let idx = 0;
            wholesalers.forEach((table, index) => {
                if (table.id === data.id) {
                    idx = index;
                    return;
                }
            });

            wholesalers[idx] = data;
            return {
                ...state,
                wholesalers: wholesalers
            };
        }

        // create
        case Types.CREATE_WHOLESALER_SUCCESS: {
            console.log(payload)
            return {
                ...state,
                wholesalers: state.wholesalers.concat(payload.data.data)
            };
        }
        case Types.ADD_WHOLESALERS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        case Types.DELETE_WHOLESALER_SUCCESS: {
            const newwholesalers = state.wholesalers.filter((item) => item.id !== payload);
            return {
                ...state,
                wholesalers: newwholesalers
            };
        }
        default: {
            return state;
        }
    }
}
