import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DashboardLayout from '../../components/Layout/DashboardLayout';
import {
    openCreateMunicipalityModel,
    openEditMunicipalityModel
} from '../../modules/models/Actions';
import { useSelector } from 'react-redux';
import {
    Button,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuItemOption,
    MenuList,
    MenuOptionGroup
} from '@chakra-ui/react';
import CTable from '../../components/table/table';
import { HiDotsVertical } from 'react-icons/hi';
import { RiDeleteBin6Line, RiFileEditLine } from 'react-icons/ri';
import CreateMunicipality from '../../components/models/municipality/createMunicipality';
import EditMunicipality from '../../components/models/municipality/editMunicipality';
import {
    deleteMunicipalityRequest,
    getAllMunicipalitysRequest,
    getMunicipalitytsByGovIDRequest
} from '../../modules/municipality/Actions';
import AuthWrapper from '../../components/Auth/AuthWrapper';
import { getAllPlansRequest } from '../../modules/plans/Actions';
import GovList from '../../components/UI/govList';

const Municipality = () => {
    const dispatch = useDispatch();
    const [addEdit, setAddEdit] = useState(true);
    const municipalitys = useSelector((state) => state.municipalitys.municipalitys);
    const plans = useSelector((state) => state.plans.plans);

    const isLoading = useSelector((state) => state.municipalitys.isLoading);
    const [offset, setPage] = useState(0);
    const [search, setSearch] = useState('');

    const [govID, setGovID] = useState('');
    const [size, setPerPage] = useState(100);
    const [pageNumber, setPageNumber] = useState(0);
    const [selected, setSelected] = useState({
        id: ''
    });
    const [step, setStep] = useState(true);
    const [planId, setPlanId] = useState('');
    useEffect(() => {
        if (govID) dispatch(getAllPlansRequest(govID));
    }, [govID]);
    useEffect(() => {
        dispatch(
            getAllMunicipalitysRequest({
                body: { ...search, plan_id: planId },
                page: pageNumber + 1
            })
        );
    }, [search, pageNumber, planId]);

    const Data = React.useMemo(() => [
        municipalitys?.map((data) => {
            return {
                ...data,
                id: data?.id,
                name: data?.name,
                gov_id: data?.gov_id
            };
        })
    ]);

    const actions = (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HiDotsVertical />}
                size="sm"
                fontSize="20px"
                variant="outline"
                border="none"
            />
            <MenuList>
                <MenuItem
                    icon={<RiFileEditLine />}
                    onClick={() => {
                        setAddEdit(false);
                        dispatch(openEditMunicipalityModel());
                    }}>
                    تعديل
                </MenuItem>
                <MenuItem
                    icon={<RiDeleteBin6Line />}
                    onClick={() => dispatch(deleteMunicipalityRequest(selected?.id))}>
                    حذف
                </MenuItem>
            </MenuList>
        </Menu>
    );
    const columns = [
        {
            Header: 'الادارة',
            accessor: 'name'
        },
        {
            Header: 'خطوط السير',
            accessor: 'plans'
        },
        {
            Header: 'عدد المدرسين',
            accessor: 'teachers'
        },

        {
            Header: '',
            accessor: 'Actions'
        }
    ];

    const viewData = (data) => {
        setSelected(data);
    };
    useEffect(() => {
        if (govID) dispatch(getMunicipalitytsByGovIDRequest(govID));
    }, [govID]);

    const filterList = (
        <MenuOptionGroup type="radio" value={planId}>
            <MenuItemOption
                onClick={() => {
                    dispatch(getMunicipalitytsByGovIDRequest(govID));
                    setPlanId('');
                }}
                value="">
                الكل
            </MenuItemOption>
            {plans.map((plan) => {
                return (
                    <MenuItemOption onClick={() => setPlanId(plan?.id)} value={plan?.id}>
                        {plan?.name}
                    </MenuItemOption>
                );
            })}
        </MenuOptionGroup>
    );
    return (
        <AuthWrapper roles={['Super Admin']}>
            <DashboardLayout title={'الادارات'}>
                {step ? (
                    <GovList isLoading={isLoading} setStep={setStep} setGovID={setGovID} />
                ) : (
                    <>
                        <Button
                            left="2"
                            position={'fixed'}
                            onClick={() => setStep(true)}
                            zIndex="10">
                            الرجوع للمحافظات
                        </Button>
                        <CTable
                            selectedData={viewData}
                            Columns={columns}
                            Data={Data[0]}
                            path={openCreateMunicipalityModel}
                            Actions={actions}
                            Title=" "
                            subTitle={``}
                            btnTitle="hi"
                            placeHolder=""
                            noSearchBar={false}
                            setSearch={setSearch}
                            noFilter={false}
                            footerBtnTitle
                            filterList={filterList}
                            addButton
                            addButtonTitle="اضافة  "
                            setPage={setPage}
                            setPerPage={setPerPage}
                            currentpage={pageNumber}
                            setPageNumber={setPageNumber}
                            noSecondSearchBar={true}
                            isLoading={isLoading}
                            perPage={size}
                            totalPage={1}
                            //Math.ceil(municipalitys?.length / 100)
                            setAddEdit={setAddEdit}
                        />
                    </>
                )}

                <CreateMunicipality />
                <EditMunicipality data={selected} addEdit={addEdit} />
            </DashboardLayout>
        </AuthWrapper>
    );
};

export default Municipality;
