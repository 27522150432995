import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DashboardLayout from '../../components/Layout/DashboardLayout';
import { openCreateAreaVillageModel, openEditAreaVillageModel } from '../../modules/models/Actions';
import { useSelector } from 'react-redux';
import GovAccordion from '../../components/UI/govAccordion';
import { IconButton, Menu, MenuButton, MenuItem, MenuList, Button } from '@chakra-ui/react';
import CTable from '../../components/table/table';
import { HiDotsVertical } from 'react-icons/hi';
import { RiDeleteBin6Line, RiFileEditLine } from 'react-icons/ri';
import {
    deleteAreaVillageRequest,
    getAllAreaVillagesRequest
} from '../../modules/areaVillage/Actions';
import AuthWrapper from '../../components/Auth/AuthWrapper';
import CreateAreaVillage from '../../components/models/areaVillage/addAreaVillage';
import EditAreaVillage from '../../components/models/areaVillage/editAreaVillage';

const AreaVillages = () => {
    const dispatch = useDispatch();
    const [addEdit, setAddEdit] = useState(true);
    const areaVillages = useSelector((state) => state.areaVillages.areaVillages);
    const count = useSelector((state) => state.areaVillages.count);

    const isLoading = useSelector((state) => state.areaVillages.isLoading);
    const [offset, setPage] = useState(0);
    const [search, setSearch] = useState('');

    const [size, setPerPage] = useState(100);
    const [pageNumber, setPageNumber] = useState(0);

    const [selected, setSelected] = useState({
        id: ''
    });
    const [govID, setGovID] = useState('');
    const [step, setStep] = useState(true);

    useEffect(() => {
        // dispatch(getAllAreaVillagesRequest(govID, pageNumber + 1))
        dispatch(
            getAllAreaVillagesRequest({
                body: {
                    name: search?.name,
                    muni_id: govID
                },
                page: pageNumber + 1
            })
        );
    }, [govID, pageNumber, search]);
    const Data = areaVillages?.map((data) => {
        return {
            ...data,
            id: data?.id,
            name: data?.name,
            gov_id: data?.gov_id,
            gov_name: data?.governorate,
            muni_id: data?.muni_id,
            muni_name: data?.municipality
        };
    });
    const actions = (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HiDotsVertical />}
                size="sm"
                fontSize="20px"
                variant="outline"
                border="none"
            />
            <MenuList>
                <MenuItem
                    icon={<RiFileEditLine />}
                    onClick={() => {
                        setAddEdit(false);
                        dispatch(openEditAreaVillageModel());
                    }}>
                    Edit
                </MenuItem>
                <MenuItem
                    onClick={() => dispatch(deleteAreaVillageRequest(selected?.id))}
                    icon={<RiDeleteBin6Line />}>
                    Delete
                </MenuItem>
            </MenuList>
        </Menu>
    );
    const columns = [
        {
            Header: 'اسم القرية',
            accessor: 'name'
        },
        {
            Header: 'اسم الادارة',
            accessor: 'muni_name'
        },
        {
            Header: 'اسم المحافظة',
            accessor: 'gov_name'
        },

        {
            Header: '',
            accessor: 'Actions'
        }
    ];

    const viewData = (data) => {
        setSelected(data);
    };

    return (
        <AuthWrapper roles={['Super Admin']}>
            <DashboardLayout title={'قرى المنطقة'}>
                {step ? (
                    <GovAccordion isLoading={isLoading} setStep={setStep} setGovID={setGovID} />
                ) : (
                    <>
                        <Button left="2" position={'fixed'} onClick={() => setStep(true)}>
                            الرجوع للمحافظات
                        </Button>
                        <CTable
                            selectedData={viewData}
                            Columns={columns}
                            Data={Data ? Data : []}
                            path={openCreateAreaVillageModel}
                            Actions={actions}
                            Title=" "
                            subTitle={``}
                            btnTitle="hi"
                            placeHolder="ابحث بالاسم"
                            secondPlaceHolder="ابحث باسم الادارة"
                            noSearchBar={false}
                            setSearch={setSearch}
                            noFilter={true}
                            footerBtnTitle
                            filterList
                            addButton
                            addButtonTitle="اضافة  "
                            setPage={setPage}
                            setPerPage={setPerPage}
                            currentpage={pageNumber}
                            setPageNumber={setPageNumber}
                            noSecondSearchBar={true}
                            isLoading={isLoading}
                            perPage={size}
                            totalPage={Math.ceil(count / 100)}
                            setAddEdit={setAddEdit}
                        />
                    </>
                )}
                <CreateAreaVillage addEdit={addEdit} />
                <EditAreaVillage data={selected} addEdit={addEdit} />
            </DashboardLayout>
        </AuthWrapper>
    );
};

export default AreaVillages;
