import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    useDisclosure,
    Flex,
    Text,
    Box,
    Button
} from '@chakra-ui/react';
import { closeCreateTeacherrModel, closeTeacherTypeModel } from '../../modules/models/Actions';
import { Formik, Field, Form } from 'formik';
import { createTeacherTypesRequest } from '../../modules/teachers-types/Actions';
import createTeacherTypeSchema from '../Schemas/createTeacherType';

const CreateTeacherType = ({ data, addEdit }) => {
    const model = useSelector((state) => state.model);
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.governments.isLoading);

    return (
        <Modal
            isOpen={model.isOpen18}
            onClose={() => dispatch(closeCreateTeacherrModel())}
            size="2xl"
            closeOnOverlayClick={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader padding="15px" bg="main_2" color={'white'}>
                    {addEdit ? 'اضافة' : 'تعديل'}
                </ModalHeader>
                <ModalCloseButton
                    color={'white'}
                    right="90%"
                    onClick={() => dispatch(closeTeacherTypeModel())}
                />
                <ModalBody>
                    <Formik
                        validationSchema={createTeacherTypeSchema}
                        initialValues={
                            !addEdit
                                ? { ...data }
                                : {
                                      name: ''
                                  }
                        }
                        onSubmit={(values) => {
                            const action = () => {
                                dispatch(closeTeacherTypeModel());
                            };

                            dispatch(createTeacherTypesRequest(values, action));
                        }}>
                        {({ values, errors, touched, handleSubmit }) => (
                            <Form>
                                <Flex flexDirection="column" justify="space-between" width={'100%'}>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        width={'100%'}
                                        mb="20px">
                                        <label htmlFor="name" style={{ marginBlock: '10px' }}>
                                            النوع
                                        </label>
                                        <Field
                                            id="name"
                                            name="name"
                                            placeholder=""
                                            style={{
                                                width: '100%',
                                                border: '1px solid #8080803b',
                                                padding: '5px',
                                                borderRadius: '5px'
                                            }}
                                        />
                                    </Box>
                                    {errors.name ? <Text color="red">هذا الحقل مطلوب</Text> : null}
                                    <Button
                                        mt="20px"
                                        w="25%"
                                        type="submit"
                                        isLoading={isLoading}
                                        style={{ marginBlock: '10px' }}
                                        bg="main_2"
                                        alignSelf={'flex-end'}
                                        color="white">
                                        حفظ التغيرات
                                    </Button>
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CreateTeacherType;
