import axiosInstance from '../../helpers/api';

export const getAllGrades = (body) => {
    return axiosInstance.get('/grade', body);
};



export const editGrades = (formData) => {
    return axiosInstance.put(`/grade/update/${formData.id}`, formData.formData);
};


export const createGrades = (formData) => {
    return axiosInstance.post('/grade/set', formData);
};

export const deleteGrade = (id) => {
    return axiosInstance.delete(`/grade/delete/${id}`);
};
