import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Flex,
    Text,
    Box,
    Button
} from '@chakra-ui/react';
import { closeCreateCenterModel } from '../../modules/models/Actions';
import { Formik, Field, Form } from 'formik';
import { getAllSubjectsRequest } from '../../modules/subjects/Actions';
import { getAllGovernmentsRequest } from '../../modules/governments/Actions';
import { getAllAreaVillagesRequest } from '../../modules/areaVillage/Actions';
import Select from 'react-select';
import { createCenterRequest, editCenterRequest } from '../../modules/centers/Actions';
import createCenterSchema from '../Schemas/createCenter';
import { getMunicipalitytsByGovIDRequest } from '../../modules/municipality/Actions';

const CreateCenter = ({ data, addEdit }) => {
    const model = useSelector((state) => state.model);

    const dispatch = useDispatch();
    const governments = useSelector((state) => state.governments.governments);
    const areaVillages = useSelector((state) => state.areaVillages.areaVillages);
    const isLoading = useSelector((state) => state.centers.isLoading);
    const municipalitys = useSelector((state) => state.municipalitys.municipalitys);
    const [govID, setGovID] = useState('');
    useEffect(() => {
        dispatch(getAllGovernmentsRequest());
        dispatch(getAllSubjectsRequest());
    }, []);

    useEffect(() => {
        if (data?.gov_id) setGovID(data?.gov_id);
    }, [data?.gov_id]);
    useEffect(() => {
        if (govID) {
            dispatch(getMunicipalitytsByGovIDRequest(govID));
            dispatch(getAllAreaVillagesRequest(govID));
        }
    }, [govID]);

    const municipalitysOptions = municipalitys?.map((data) => {
        return {
            label: data?.name,
            value: data?.id
        };
    });
    const govOptions = governments?.map((data) => {
        return {
            label: data?.name,
            value: data?.id
        };
    });

    const areaVillagesOptions = areaVillages?.map((data) => {
        return {
            label: data?.name,
            value: data?.id
        };
    });

    return (
        <Modal
            isOpen={model.isOpen14}
            onClose={() => dispatch(closeCreateCenterModel())}
            size="2xl"
            closeOnOverlayClick={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader padding="15px" bg="main_2" color={'white'}>
                    {addEdit ? 'اضافة' : 'تعديل'}
                </ModalHeader>
                <ModalCloseButton
                    color={'white'}
                    right="90%"
                    onClick={() => dispatch(closeCreateCenterModel())}
                />
                <ModalBody>
                    <Formik
                        validationSchema={createCenterSchema}
                        initialValues={
                            !addEdit
                                ? { ...data }
                                : {
                                      name: '',
                                      address: '',
                                      gov_id: '',
                                      area_vil: '',
                                      phone: '',
                                      subjects: ''
                                  }
                        }
                        onSubmit={(values) => {
                            // const subjectsData = values?.subjects?.map((data) => data?.value)
                            const action = () => {
                                dispatch(closeCreateCenterModel());
                            };
                            if (addEdit) {
                                dispatch(createCenterRequest({ ...values }, action));
                            } else {
                                dispatch(
                                    editCenterRequest(
                                        {
                                            id: data?.id,
                                            formData: {
                                                name: values.name,
                                                address: values.address,
                                                gov_id: values.gov_id,
                                                area_vil: values.area_vil,
                                                phone: values.phone
                                            }
                                        },
                                        action
                                    )
                                );
                            }
                        }}>
                        {({ values, errors, touched, handleSubmit, setValues }) => (
                            <Form>
                                {console.log(values, 'values')}
                                <Flex flexDirection="column" justify="space-between" width={'100%'}>
                                    <Flex alignItems={'center'} justifyContent="space-between">
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width={'45%'}
                                            mb="20px">
                                            <label htmlFor="name" style={{ marginBlock: '10px' }}>
                                                اسم السنتر
                                            </label>
                                            <Field
                                                id="name"
                                                name="name"
                                                placeholder=""
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #8080803b',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                            {errors.name ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width={'45%'}
                                            mb="20px">
                                            <label
                                                htmlFor="owner_name"
                                                style={{ marginBlock: '10px' }}>
                                                اسم صاحب السنتر
                                            </label>
                                            <Field
                                                id="owner_name"
                                                name="owner_name"
                                                placeholder=""
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #8080803b',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                            {errors.owner_name ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Box>
                                    </Flex>
                                    <Flex alignItems={'center'} justifyContent="space-between">
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width={'45%'}
                                            mb="20px">
                                            <label
                                                htmlFor="address"
                                                style={{ marginBlock: '10px' }}>
                                                العنوان
                                            </label>
                                            <Field
                                                id="address"
                                                name="address"
                                                placeholder=""
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #8080803b',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                            {errors.address ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width={'45%'}
                                            mb="20px">
                                            <label htmlFor="phone" style={{ marginBlock: '10px' }}>
                                                رقم الموبيل
                                            </label>
                                            <Field
                                                id="phone"
                                                name="phone"
                                                placeholder=""
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #8080803b',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                            {errors.phone ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Box>
                                    </Flex>

                                    <Flex flexDirection="column" w="100%">
                                        <label htmlFor="gov_id" style={{ marginBlock: '10px' }}>
                                            المحافظة
                                        </label>
                                        <Select
                                            options={govOptions}
                                            defaultValue={{
                                                value: values?.gov_id,
                                                label: values?.gov
                                            }}
                                            name="gov_id"
                                            onChange={(val) => {
                                                setGovID(val?.value);
                                                setValues({ ...values, gov_id: val?.value });
                                            }}
                                        />

                                        {errors.gov_id ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>
                                    <Flex flexDirection="column">
                                        <label htmlFor="muni_id" style={{ marginBlock: '10px' }}>
                                            الادارة
                                        </label>
                                        <Select
                                            options={municipalitysOptions}
                                            defaultValue={{
                                                value: values?.muni_id,
                                                label: values?.muni_name
                                            }}
                                            name="muni_id"
                                            onChange={(val) =>
                                                setValues({ ...values, muni_id: val?.value })
                                            }
                                        />

                                        {errors.muni_id ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>
                                    <Flex flexDirection="column">
                                        <label htmlFor="area_vil" style={{ marginBlock: '10px' }}>
                                            القري\المدينة
                                        </label>
                                        <Select
                                            options={areaVillagesOptions}
                                            defaultValue={{
                                                value: values?.area_vil,
                                                label: values?.area_name
                                            }}
                                            name="area_vil"
                                            onChange={(val) =>
                                                setValues({ ...values, area_vil: val?.value })
                                            }
                                        />

                                        {errors.area_vil ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>
                                    {/* <Flex flexDirection="column">
                                        <label
                                            htmlFor="subjects"
                                            style={{ marginBlock: '10px' }}>
                                            المادة
                                        </label>
                                        <Select
                                            defaultValue={!addEdit ? selectedSubject : []}
                                            isMulti={true} options={subjectOptions} name='subjects' onChange={(val) => setValues({ ...values, subjects: val })} />



                                        {errors.area_vil ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex> */}

                                    <Button
                                        mt="20px"
                                        w="25%"
                                        type="submit"
                                        style={{ marginBlock: '10px' }}
                                        bg="main_2"
                                        isLoading={isLoading}
                                        alignSelf={'flex-end'}
                                        color="white">
                                        حفظ التغيرات
                                    </Button>
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CreateCenter;
