import React from 'react';
import P from 'prop-types';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import Cookie from 'js-cookie';
// import {
//   authTokenSelector,
//   authIsLoadingSelector,
// } from "../../modules/auth/Selectors";

const ProtectedRoute = (props) => {
    const {
        component: Component,
        userType,
        requiredPermissions,
        authIsLoading,
        user,
        ...rest
    } = props;

    const token = 'test'
    //Cookie.get('token');
    return (
        <Route
            {...rest}
            render={(componentProps) =>
                !token && window.location.pathname != '/login' ? (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: componentProps.location }
                        }}
                    />

                ) : (
                    <Component {...componentProps} />
                )
            }
        />
    );
};

ProtectedRoute.propTypes = {
    component: P.func.isRequired,
    token: P.string,
    user: P.shape({}),
    authIsLoading: P.bool.isRequired
};

ProtectedRoute.defaultProps = {
    token: null,
    user: null
};

const mapStateToProps = (state) => {
    return {
        // token: authTokenSelector(state),
        // authIsLoading: authIsLoadingSelector(state),
    };
};

export default connect(mapStateToProps)(ProtectedRoute);
