/* eslint-disable import/no-anonymous-default-export */
import { fork, put } from 'redux-saga/effects';
import { appReadyAction } from './globalActions';
import authSaga from '../modules/auth/Saga';
import userDataSaga from '../modules/userData/Saga';
import governmentSaga from '../modules/governments/Saga';
import municipalitySaga from '../modules/municipality/Saga';
import gradesSaga from '../modules/grades/Saga';
import subjectsSaga from '../modules/subjects/Saga';
import usersSaga from '../modules/users/Saga';
import booksSaga from '../modules/books/Saga';
import authorSaga from '../modules/authors/Saga';
import areaVillagesSaga from '../modules/areaVillage/Saga';
import schoolsSaga from '../modules/schools/Saga';
import BookstoreSaga from '../modules/bookstores/Saga';
import wholesalersSaga from '../modules/wholesalers/Saga';
import centersSaga from '../modules/centers/Saga';
import TeachersSaga from '../modules/teachers/Saga';
import PlanSaga from '../modules/plans/Saga';
import visitsSaga from '../modules/visits/Saga';
import TypesSaga from '../modules/teachers-types/Saga';
export default function* () {
    yield put(appReadyAction());
    yield fork(authSaga);
    yield fork(userDataSaga);
    yield fork(governmentSaga);
    yield fork(municipalitySaga);
    yield fork(gradesSaga);
    yield fork(subjectsSaga);
    yield fork(usersSaga);
    yield fork(booksSaga);
    yield fork(authorSaga);
    yield fork(areaVillagesSaga);
    yield fork(schoolsSaga);
    yield fork(BookstoreSaga);
    yield fork(wholesalersSaga);
    yield fork(centersSaga);
    yield fork(TeachersSaga);
    yield fork(PlanSaga);
    yield fork(visitsSaga);
    yield fork(TypesSaga);
}
