import Types from './Types';

const INITIAL_STATE = {
    bookstores: [],
    bookstore: {},
    isLoading: false,
    count: ''
};

export default function bookstores(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_BOOKSTORES_SUCCESS: {
            console.log(payload)
            return {
                ...state,
                bookstores: payload?.message ? [] : payload.data?.data,
                count: payload?.message ? 0 : payload.data?.pagination?.total_records
            };
        }

        case Types.GET_BOOKSTORE_SUCCESS: {
            return {
                ...state,
                bookstore: payload
            };
        }

        case Types.EDIT_BOOKSTORE_SUCCESS: {
            let data = payload.data;
            const bookstores = state.bookstores;
            let idx = 0;
            bookstores.forEach((table, index) => {
                if (table.id === data.id) {
                    idx = index;
                    return;
                }
            });

            bookstores[idx] = data;
            return {
                ...state,
                bookstores: bookstores
            };
        }

        // create
        case Types.CREATE_BOOKSTORE_SUCCESS: {

            return {
                ...state,
                bookstores: state.bookstores.concat(payload.data)
            };
        }
        case Types.ADD_BOOKSTORES_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        case Types.DELETE_BOOKSTORE_SUCCESS: {
            const newbookstores = state.bookstores.filter((item) => item.id !== payload);
            return {
                ...state,
                bookstores: newbookstores
            };
        }
        default: {
            return state;
        }
    }
}
