import Types from './Types';

const INITIAL_STATE = {
    subjects: [],
    subject: {},
    isLoading: false,
    count: ''
};

export default function governments(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_SUBJECTS_SUCCESS: {
            return {
                ...state,
                subjects: payload.data,
                count: payload.data.length
            };
        }

        case Types.GET_SUBJECT_SUCCESS: {
            return {
                ...state,
                subject: payload
            };
        }

        case Types.EDIT_SUBJECT_SUCCESS: {
            let data = payload.data;
            const subjects = state.subjects;
            let idx = 0;
            subjects.forEach((table, index) => {
                if (table.id === data.id) {
                    idx = index;
                    return;
                }
            });

            subjects[idx] = data;
            return {
                ...state,
                subjects: subjects
            };
        }

        // create
        case Types.CREATE_SUBJECT_SUCCESS: {
            const data = payload.data.data;
            let newArray = state.subjects.slice();
            let index = state.subjects.length + 1;
            newArray.splice(index, 0, data);
            return {
                ...state,
                subjects: newArray
            };
        }
        case Types.ADD_SUBJECTS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        case Types.DELETE_SUBJECT_SUCCESS: {
            const newSubjects = state.subjects.filter((item) => item.id !== payload);
            return {
                ...state,
                subjects: newSubjects
            };
        }
        default: {
            return state;
        }
    }
}
