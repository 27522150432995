import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DashboardLayout from '../../components/Layout/DashboardLayout';
import { openCreateSchoolModel } from '../../modules/models/Actions';
import { useSelector } from 'react-redux';

import {
    Button,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuItemOption,
    MenuList,
    MenuOptionGroup
} from '@chakra-ui/react';
import CTable from '../../components/table/table';
import { HiDotsVertical } from 'react-icons/hi';
import { RiDeleteBin6Line, RiFileEditLine } from 'react-icons/ri';

import { deleteSubjectRequest } from '../../modules/subjects/Actions';
import AuthWrapper from '../../components/Auth/AuthWrapper';
import { getAllSchoolsRequest } from '../../modules/schools/Actions';
import CreateSchools from '../../components/models/createSchools';

import GovAccordion from '../../components/UI/govAccordion';

const Schools = () => {
    const dispatch = useDispatch();
    const [addEdit, setAddEdit] = useState(true);
    const [search, setSearch] = useState('');
    const [govName, setGovName] = useState('');
    const [step, setStep] = useState(true);

    const schools = useSelector((state) => state.schools.schools);
    const count = useSelector((state) => state.schools.count);

    const areaVillages = useSelector((state) => state.areaVillages.areaVillages);

    const isLoading = useSelector((state) => state.schools.isLoading);
    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(100);
    const [pageNumber, setPageNumber] = useState(0);
    const [filterOptions, setFilterOptions] = useState({});

    const [selected, setSelected] = useState({
        id: ''
    });

    useEffect(() => {
        if (filterOptions)
            dispatch(
                getAllSchoolsRequest(
                    { ...filterOptions, name: search?.name, muni_id: govName },
                    pageNumber + 1
                )
            );
    }, [filterOptions, pageNumber, govName, search]);

    const Data = schools?.map((data) => {
        return {
            ...data,
            id: data?.id,
            name: data?.name,
            gov_id: data?.area?.gov_id,
            area_vil: data?.area?.id,
            govName: data?.gov,
            address: data?.address
        };
    });

    const actions = (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HiDotsVertical />}
                size="sm"
                fontSize="20px"
                variant="outline"
                border="none"
            />
            <MenuList>
                <MenuItem
                    icon={<RiFileEditLine />}
                    onClick={() => {
                        setAddEdit(false);
                        dispatch(openCreateSchoolModel());
                    }}>
                    تعديل
                </MenuItem>
                <MenuItem
                    onClick={() => dispatch(deleteSubjectRequest(selected?.id))}
                    icon={<RiDeleteBin6Line />}>
                    حذف
                </MenuItem>
            </MenuList>
        </Menu>
    );
    const columns = [
        {
            Header: 'المدرسة',
            accessor: 'name'
        },
        {
            Header: 'العنوان',
            accessor: 'address'
        },

        {
            Header: '',
            accessor: 'Actions'
        }
    ];

    const handelFilter = (value, name) => {
        console.log(value);
        setFilterOptions({ ...filterOptions, [name]: value });
    };
    const viewData = (data) => {
        setSelected(data);
    };

    const secondFilterList = (
        <MenuOptionGroup type="radio" value={filterOptions?.areaVillage}>
            {areaVillages.map((areaVillage) => {
                return (
                    <MenuItemOption
                        onClick={() => handelFilter(areaVillage?.name, 'area')}
                        value={areaVillage?.name}>
                        {areaVillage?.name}
                    </MenuItemOption>
                );
            })}
        </MenuOptionGroup>
    );
    return (
        <AuthWrapper roles={['Super Admin']}>
            <DashboardLayout title={' المدارس'}>
                {step ? (
                    <GovAccordion isLoading={isLoading} setStep={setStep} setGovID={setGovName} />
                ) : (
                    <>
                        <Button
                            left="2"
                            position={'fixed'}
                            onClick={() => setStep(true)}
                            zIndex="10">
                            الرجوع للمحافظات
                        </Button>

                        <CTable
                            selectedData={viewData}
                            Columns={columns}
                            Data={Data}
                            path={openCreateSchoolModel}
                            Actions={actions}
                            Title=" "
                            subTitle={``}
                            btnTitle="hi"
                            placeHolder=""
                            noSearchBar={false}
                            noFilter={true}
                            footerBtnTitle
                            filterList
                            addButton
                            addButtonTitle="اضافة"
                            setPage={setPage}
                            setPerPage={setPerPage}
                            currentpage={pageNumber}
                            setPageNumber={setPageNumber}
                            noSecondSearchBar={true}
                            isLoading={isLoading}
                            perPage={size}
                            totalPage={Math.ceil(count / 100)}
                            setAddEdit={setAddEdit}
                            setSearch={setSearch}
                            noSecondFilter={false}
                            secondFilterList={secondFilterList}
                            secondFilterTitle={'تنقية  بالقرية/المدينة'}
                        />
                    </>
                )}
                <CreateSchools data={selected} addEdit={addEdit} />
            </DashboardLayout>
        </AuthWrapper>
    );
};

export default Schools;
