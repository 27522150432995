import React, { useEffect } from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Spinner,
    Flex,
    Button
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllGovernmentsRequest } from '../../modules/governments/Actions';
import { getMunicipalitytsByGovIDRequest } from '../../modules/municipality/Actions';
const GovAccordion = ({ setStep, setGovID }) => {
    const dispatch = useDispatch();
    const governments = useSelector((state) => state.governments.governments);
    const municipalitys = useSelector((state) => state.municipalitys.municipalitys);
    const isLoading = useSelector((state) => state.municipalitys.isLoading);
    useEffect(() => {
        dispatch(getAllGovernmentsRequest());
    }, []);
    return (
        <Accordion allowToggle my="20px">
            {governments?.length == 0 ? (
                <Flex
                    w="100%"
                    alignItems="center"
                    justifyContent="center"
                    my="28px"
                    minW="300px"
                    fontSize="30px"
                    color="#80808073">
                    لا يوجد محافظات
                </Flex>
            ) : (
                governments?.map((government, index) => {
                    return (
                        <AccordionItem key={index}>
                            <h2>
                                <AccordionButton
                                    _focus={{ outline: '0' }}
                                    w="100%"
                                    onClick={() =>
                                        dispatch(getMunicipalitytsByGovIDRequest(government?.id))
                                    }
                                    display="flex"
                                    alighItems="center"
                                    justifyContent="space-between">
                                    <Box textAlign="left">
                                        {government?.name} | {government?.municipilty}
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                {isLoading ? (
                                    <Flex w="100%" alignItems="center" justifyContent="center">
                                        <Spinner />
                                    </Flex>
                                ) : (
                                    <Flex
                                        flexWrap="wrap"
                                        alignItems="center"
                                        justifyContent="space-evenly">
                                        {municipalitys?.map((municipality, index) => {
                                            return (
                                                <Button
                                                    key={index}
                                                    m="15px"
                                                    onClick={() => {
                                                        setStep(false);
                                                        setGovID(municipality?.id);
                                                    }}>
                                                    {municipality?.name}
                                                </Button>
                                            );
                                        })}
                                    </Flex>
                                )}
                            </AccordionPanel>
                        </AccordionItem>
                    );
                })
            )}
        </Accordion>
    );
};

export default GovAccordion;
