import {
    Button,
    Flex,
    IconButton,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Text
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import { RiFileEditLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import AuthWrapper from '../../components/Auth/AuthWrapper';
import DashboardLayout from '../../components/Layout/DashboardLayout';
import CreateVisit from '../../components/models/createVisit';
import CTable from '../../components/table/table';
import { openCreateVisitModel } from '../../modules/models/Actions';
import { getPlansRequest } from '../../modules/plans/Actions';
import { getAllSubjectsRequest } from '../../modules/subjects/Actions';
import { getAllTeacherTypesRequest } from '../../modules/teachers-types/Actions';
import { getAllTeachersRequest } from '../../modules/teachers/Actions';

const SalesmanVisitis = () => {
    const dispatch = useDispatch();
    const [addEdit, setAddEdit] = useState(true);
    const [step, setStep] = useState(true);
    const teachers = useSelector((state) => state.teachers.teachers);
    const count = useSelector((state) => state.teachers.count);
    const plans = useSelector((state) => state.plans.plans);

    const subjects = useSelector((state) => state.subjects.subjects);
    const types = useSelector((state) => state.types.types);

    const isLoading = useSelector((state) => state.teachers.isLoading);
    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(100);
    const [pageNumber, setPageNumber] = useState(0);
    const [filterOptions, setFilterOptions] = useState({
        name: '',
        area: '',
        subject: ''
    });

    const [selected, setSelected] = useState({
        id: ''
    });

    useEffect(() => {
        if (filterOptions) dispatch(getAllTeachersRequest({ ...filterOptions }, pageNumber + 1));
    }, [filterOptions, pageNumber]);

    useEffect(() => {
        dispatch(getAllSubjectsRequest());
        dispatch(getAllTeacherTypesRequest());
        dispatch(getPlansRequest());
    }, []);
    const Data = teachers?.map((data) => {
        return {
            ...data,
            id: data?.id,
            name: data?.name,
            gov_id: data?.area?.gov_id,
            gov_name: data?.gov,
            area_vil: data?.area?.id,
            area_vil_name: data?.area?.name,
            muni_id: data?.area?.muni_id,
            muni_id_name: '',
            school_id: data?.school?.id,
            school_name: data?.school?.name,
            type: data?.type,
            subjects: data?.subject?.map((data) => {
                return { value: data.id, label: data?.name };
            }),
            grades: data?.grades?.map((data) => {
                return { value: data.id, label: data?.name };
            }),
            subjectsData: data?.subject?.map((val) => {
                return <Text>{val.name}</Text>;
            })
        };
    });

    const actions = (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HiDotsVertical />}
                size="sm"
                fontSize="20px"
                variant="outline"
                border="none"
            />
            <MenuList>
                <MenuItem
                    icon={<RiFileEditLine />}
                    onClick={() => {
                        setAddEdit(false);
                        dispatch(openCreateVisitModel());
                    }}>
                    اضافة زيارة
                </MenuItem>
            </MenuList>
        </Menu>
    );
    const columns = [
        {
            Header: 'الاسم',
            accessor: 'name'
        },
        {
            Header: 'الهاتف',
            accessor: 'phone'
        },
        {
            Header: 'النوع',
            accessor: 'type'
        },
        {
            Header: 'المدرسة',
            accessor: 'school_name'
        },
        {
            Header: 'المواد',
            accessor: 'subjectsData'
        },
        {
            Header: '',
            accessor: 'Actions'
        }
    ];

    const handelFilter = (value, name) => {
        setFilterOptions({ ...filterOptions, [name]: value });
    };
    const viewData = (data) => {
        setSelected(data);
    };

    return (
        <AuthWrapper roles={['Sale Person']}>
            <Flex
                position="fixed"
                flexDirection={{ base: 'column', md: 'row' }}
                style={{
                    gap: '10px'
                }}
                top="90px"
                zIndex="100"
                left={step ? 0 : '150px'}>
                <Menu>
                    <MenuButton as={Button} bg="gradient_main_color" color="white" mx="10px">
                        خطوط السير
                    </MenuButton>
                    <MenuList>
                        <MenuOptionGroup type="radio" value={filterOptions.subject}>
                            <MenuItemOption onClick={() => handelFilter('', 'plan_id')} value={''}>
                                الكل
                            </MenuItemOption>
                            {console.log(plans, 'kk')}
                            {plans.map((plan) => {
                                return (
                                    <MenuItemOption
                                        onClick={() => handelFilter(plan?.id, 'plan_id')}
                                        value={plan?.name}>
                                        {plan?.name}
                                    </MenuItemOption>
                                );
                            })}
                        </MenuOptionGroup>
                    </MenuList>
                </Menu>
                <Menu>
                    <MenuButton as={Button} bg="gradient_main_color" color="white">
                        المواد
                    </MenuButton>
                    <MenuList>
                        <MenuOptionGroup type="radio" value={filterOptions.subject}>
                            <MenuItemOption onClick={() => handelFilter('', 'subject')} value={''}>
                                الكل
                            </MenuItemOption>

                            {subjects.map((subject) => {
                                return (
                                    <MenuItemOption
                                        onClick={() => handelFilter(subject?.name, 'subject')}
                                        value={subject?.name}>
                                        {subject?.name}
                                    </MenuItemOption>
                                );
                            })}
                        </MenuOptionGroup>
                    </MenuList>
                </Menu>
                <Menu>
                    <MenuButton as={Button} bg="gradient_main_color" color="white" mx="10px">
                        النوع
                    </MenuButton>
                    <MenuList>
                        <MenuOptionGroup type="radio" value={filterOptions.type}>
                            <MenuItemOption onClick={() => handelFilter('', 'type')} value={''}>
                                الكل
                            </MenuItemOption>
                            {types.map((type) => {
                                return (
                                    <MenuItemOption
                                        onClick={() => handelFilter(type?.name, 'type')}
                                        value={type?.name}>
                                        {type?.name}
                                    </MenuItemOption>
                                );
                            })}
                        </MenuOptionGroup>
                    </MenuList>
                </Menu>

                <Flex mx="5px">
                    <Input
                        onChange={(e) => handelFilter(e.target.value, 'name')}
                        placeholder="بحث بالاسم"></Input>
                </Flex>
                <Flex mx="5px">
                    <Input
                        onChange={(e) => handelFilter(e.target.value, 'phone')}
                        placeholder="بحث برقم الهاتف"></Input>
                </Flex>
            </Flex>
            <DashboardLayout title={' المدرسين'} custamHeight={{ base: '380px', md: '140px' }}>
                {/* {step ? (
                    <GovAccordion isLoading={isLoading} setStep={setStep} setGovID={setGovID} />
                ) : (
                    <> */}
                {/* <Button
                            left="2"
                            position={'fixed'}
                            onClick={() => setStep(true)}
                            zIndex="10">
                            الرجوع للمحافظات
                        </Button> */}
                <CTable
                    selectedData={viewData}
                    Columns={columns}
                    Data={Data}
                    path={openCreateVisitModel}
                    Actions={actions}
                    Title=" "
                    subTitle={``}
                    btnTitle="hi"
                    placeHolder=""
                    noSearchBar={true}
                    noFilter={true}
                    footerBtnTitle
                    filterList
                    setPage={setPage}
                    setPerPage={setPerPage}
                    currentpage={pageNumber}
                    setPageNumber={setPageNumber}
                    noSecondSearchBar={true}
                    isLoading={isLoading}
                    perPage={size}
                    totalPage={Math.ceil(count / 100)}
                    setAddEdit={setAddEdit}
                />
                {/* </>
                )} */}
            </DashboardLayout>
            <CreateVisit data={selected} />
        </AuthWrapper>
    );
};

export default SalesmanVisitis;
