import * as Yup from 'yup';

const createBookstoreSchema = Yup.object().shape({
    name: Yup.string().min(2, 'Too Short!').max(70, 'Too Long!').required('Required'),

    address: Yup.string().required('Required'),
    type: Yup.string().required('Required'),
    gov_id: Yup.string(),
    area_vil: Yup.string().required('Required'),
    phone: Yup.string().min(11, 'Too Short!').max(12, 'Too Long!').required('Required'),
    owner: Yup.string().required('Required'),
    ISBN: Yup.string().required('Required'),
    author_id: Yup.string().required('Required')
});

export default createBookstoreSchema;
