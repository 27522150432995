import React, { useMemo } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td, chakra, Container, Flex } from '@chakra-ui/react';

import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { TiArrowUnsorted } from 'react-icons/ti';
import { useTable, useSortBy, useGlobalFilter, usePagination } from 'react-table';
import CFooter from './footer';
import CHeader from './header';
import Loading from '../UI/loading';

const CTable = ({
    setSearch,
    Data,
    Columns,
    Actions,
    Title,
    subTitle,
    btnTitle,
    placeHolder,
    noSearchBar,
    noFilter,
    footerBtnTitle,
    filterList,
    selectedData,
    ActionsData,
    currentpage,
    setAddEdit,
    path,
    addButton,
    addButtonTitle,
    setPerPage,
    setPage,
    perPage,
    totalPage,
    setPageNumber,
    filterLength,
    searchFn = () => {},
    isLoading = false,
    noSecondSearchBar,
    secondPlaceHolder,
    noSecondFilter,
    secondFilterList,
    secondFilterTitle
}) => {
    const data = useMemo(
        () =>
            Data?.map((value) => {
                return {
                    ...value,
                    Actions: ActionsData ? ActionsData(value) : Actions
                };
            }),
        [Data]
    );

    const columns = useMemo(
        () =>
            Columns?.map((column) => {
                return {
                    Header: column.Header,
                    accessor: column.accessor
                };
            }),
        [Columns]
    );

    const {
        state,
        setGlobalFilter,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        pageOptions,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data,
            useControlledState: (state) => {
                return React.useMemo(
                    () => ({
                        ...state,
                        pageIndex: currentpage
                    }),
                    [state, currentpage]
                );
            },
            initialState: {
                pageIndex: currentpage
            },
            manualPagination: true,
            pageCount: totalPage
        },

        useGlobalFilter,

        useSortBy,
        usePagination
    );

    return (
        <>
            <Container maxW="container">
                <CHeader
                    setAddEdit={setAddEdit}
                    filterList={filterList}
                    noSearchBar={noSearchBar}
                    noFilter={noFilter}
                    Title={Title}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    Data={Data}
                    subTitle={subTitle}
                    btnTitle={btnTitle}
                    placeHolder={placeHolder}
                    selectedData={selectedData}
                    setSearch={setSearch}
                    filterLength={filterLength}
                    path={path}
                    addButton={addButton}
                    addButtonTitle={addButtonTitle}
                    noSecondSearchBar={noSecondSearchBar}
                    secondPlaceHolder={secondPlaceHolder}
                    noSecondFilter={noSecondFilter}
                    secondFilterList={secondFilterList}
                    secondFilterTitle={secondFilterTitle}
                />
                {data?.length === 0 ? (
                    <Flex
                        w="100%"
                        alignItems="center"
                        justifyContent="center"
                        my="28px"
                        minW="300px"
                        fontSize="30px"
                        color="#80808073">
                        لا يوجد بيانات
                    </Flex>
                ) : (
                    <>
                        <Table {...getTableProps()} bg="white" mb="6" borderRadius="6px" mt="50px">
                            <Thead bg="" color="#645f65">
                                {headerGroups.map((headerGroup) => (
                                    <Tr {...headerGroup.getHeaderGroupProps()}>
                                        {headerGroup.headers.map((column) => (
                                            <Th
                                                bg="inherit"
                                                {...column.getHeaderProps(
                                                    column.Header === ''
                                                        ? ''
                                                        : column.getSortByToggleProps()
                                                )}>
                                                <Flex>
                                                    <>
                                                        {column.render('Header')}
                                                        {column.Header === '' ? (
                                                            ''
                                                        ) : (
                                                            <chakra.span
                                                                style={{
                                                                    paddingLeft: '10px',
                                                                    margin: '-1px'
                                                                }}
                                                                display="inline-block">
                                                                {column.isSorted ? (
                                                                    column.isSortedDesc ? (
                                                                        <TriangleDownIcon aria-label="sorted descending" />
                                                                    ) : (
                                                                        <TriangleUpIcon aria-label="sorted ascending" />
                                                                    )
                                                                ) : (
                                                                    <TiArrowUnsorted fontSize="18px" />
                                                                )}
                                                            </chakra.span>
                                                        )}
                                                    </>
                                                </Flex>
                                            </Th>
                                        ))}
                                    </Tr>
                                ))}
                            </Thead>
                            {!isLoading ? (
                                <Tbody {...getTableBodyProps()} color="black">
                                    {page.map((row) => {
                                        prepareRow(row);
                                        return (
                                            <Tr
                                                {...row.getRowProps()}
                                                onClick={() => selectedData(row.original)}>
                                                {row.cells.map((cell) => (
                                                    <Td
                                                        {...cell.getCellProps()}
                                                        isNumeric={cell.column.isNumeric}>
                                                        {cell.render('Cell')}
                                                    </Td>
                                                ))}
                                            </Tr>
                                        );
                                    })}
                                </Tbody>
                            ) : (
                                <Loading />
                            )}
                        </Table>

                        {Data.length > 0 ? (
                            <CFooter
                                setPageNumber={setPageNumber}
                                footerBtnTitle={footerBtnTitle}
                                pageIndex={pageIndex}
                                pageOptions={pageOptions}
                                setPage={setPage}
                                totalPage={totalPage}
                                currentpage={currentpage}
                            />
                        ) : (
                            ''
                        )}
                    </>
                )}
            </Container>
        </>
    );
};

export default CTable;
