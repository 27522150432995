import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';
import Cookie from 'js-cookie';
import Cookies from 'js-cookie';

// SIGNIN

function* signinUser(formData) {
    try {
        yield put(actions.setAuthIsLoadingAction(true));
        const result = yield call(api.Login, formData.payload);

        // Cookies.set('token', '14|sArA67U3Yu8J6q8jFcOytUPVYpZEkHE7PJakLNkT')
        // Cookies.set('role', 'Super_Admin')



        yield put(
            actions.loginUserSuccessAction({
                data: result.data
            })
        );
    } catch (error) {
        toast.error(' خطاء في اسم المستخدم او كلمة المرور ')
    } finally {
        yield put(actions.setAuthIsLoadingAction(false));
    }
}

function* signoutUser() {
    try {
        yield put(actions.setAuthIsLoadingAction(true));
        const result = yield call(api.Logout);
        yield put(actions.logoutUserSuccessAction());
        window.location.href = '/auth/login';
    } catch (error) {
        const { response, message } = error;
    } finally {
        yield put(actions.setAuthIsLoadingAction(false));
    }
}

export default function* authSaga() {
    yield takeLatest(Types.LOGIN_USER, signinUser);

    yield takeLatest(Types.LOGOUT_USER, signoutUser);
}
