import axiosInstance from '../../helpers/api';

export const getAllPlans = (id) => {
    return axiosInstance.get(`/plans/get/${id}`);
};

export const getPlans = () => {
    return axiosInstance.get(`/plans`);
};

export const editPlans = (formData) => {
    return axiosInstance.put(`/plans/${formData.id}`, formData.formData);
};

export const addPlan = (formData) => {
    return axiosInstance.post(`/plans/${formData.id}/sale-person`, formData.formData);
};

export const createPlans = (formData) => {
    return axiosInstance.post('/plans', formData);
};

export const deletePlan = (id) => {
    return axiosInstance.delete(`/plans/${id}`);
};
