import Types from './Types';

const INITIAL_STATE = {
    municipalitys: [],
    municipality: {},
    isLoading: false,
    count: ''
};

export default function municipalitys(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_MUNICIPALITYS_SUCCESS: {
            return {
                ...state,
                municipalitys: payload,
                count: payload.length
            };
        }

        case Types.GET_MUNICIPALITYS_BY_GOV_ID_SUCCESS: {

            return {
                ...state,
                municipalitys: payload.data,
                count: payload.data.length
            };
        }

        case Types.GET_MUNICIPALITY_SUCCESS: {
            return {
                ...state,
                municipality: payload
            };
        }

        case Types.EDIT_MUNICIPALITY_SUCCESS: {
            let data = payload.data;
            const municipalitys = state.municipalitys;
            let idx = 0;
            municipalitys.forEach((table, index) => {
                if (table.id === data.id) {
                    idx = index;
                    return;
                }
            });

            municipalitys[idx] = data;
            return {
                ...state,
                municipalitys: municipalitys
            };
        }

        // create
        case Types.CREATE_MUNICIPALITY_SUCCESS: {
            const data = payload.data.data;

            return {
                ...state,
                municipalitys: state.municipalitys.concat(data)
            };
        }
        case Types.ADD_MUNICIPALITYS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        case Types.DELETE_MUNICIPALITY_SUCCESS: {
            const newMunicipalitys = state.municipalitys.filter((item) => item.id !== payload);
            return {
                ...state,
                municipalitys: newMunicipalitys
            };
        }
        default: {
            return state;
        }
    }
}
