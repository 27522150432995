import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllGovernments({ payload }) {
    try {
        yield put(actions.addGovernmentLoading(true));
        const result = yield call(api.getAllGovernments);
        yield put(actions.getAllGovernmentsSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        console.log(error, 'error');
        yield put(actions.getAllGovernmentsFail());
    } finally {
        yield put(actions.addGovernmentLoading(false));
    }
}



//Edit Tables
function* editGovernment(formData) {
    try {
        yield put(actions.addGovernmentLoading(true));
        const result = yield call(api.editGovernments, formData?.payload);
        yield put(actions.editGovernmentSuccess(result.data));
        toast.success('Edit Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.editGovernmentFail());
    } finally {
        yield put(actions.addGovernmentLoading(false));
    }
}

// create
function* createGovernment(formData) {
    try {
        yield put(actions.addGovernmentLoading(true));
        const result = yield call(api.createGovernments, formData.payload);
        yield put(
            actions.createGovernmentSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.createGovernmentFail());
    } finally {
        yield put(actions.addGovernmentLoading(false));
    }
}

function* deleteGovernment({ payload }) {
    try {
        yield put(actions.addGovernmentLoading(true));
        const result = yield call(api.deleteGovernment, payload);

        yield put(actions.deleteGovernmentSuccess(payload));

        toast.success('Deleted Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteGovernmentFail());
    } finally {
        yield put(actions.addGovernmentLoading(false));
    }
}

export default function* governmentSaga() {
    yield takeLatest(Types.GET_GOVERNMENTS_REQUEST, getAllGovernments);
    yield takeLatest(Types.EDIT_GOVERNMENT_REQUEST, editGovernment);
    yield takeLatest(Types.CREATE_GOVERNMENT_REQUEST, createGovernment);
    yield takeLatest(Types.DELETE_GOVERNMENT_REQUEST, deleteGovernment);
}
