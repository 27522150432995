import Types from './Types';

const INITIAL_STATE = {
    types: [],

    isLoading: false,
    count: ''
};

export default function teachers(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_TEACHER_TYPE_SUCCESS: {
            console.log(payload)
            return {
                ...state,
                types: payload.data,
                count: payload.data.length
            };
        }



        // create
        case Types.CREATE_TEACHER_TYPE_SUCCESS: {
            console.log(payload)
            return {
                ...state,
                types: state.types.concat(payload.data.data)
            };
        }
        case Types.ADD_TEACHER_TYPE_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        case Types.DELETE_TEACHER_TYPE_SUCCESS: {
            const newteachers = state.types.filter((item) => item.id !== payload);
            return {
                ...state,
                types: newteachers
            };
        }
        default: {
            return state;
        }
    }
}
