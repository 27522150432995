import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllMunicipalitys(data) {
    console.log(data, 'FORMdATA')

    try {
        yield put(actions.addMunicipalityLoading(true));
        const result = yield call(api.getAllMunicipalityts, data);
        yield put(actions.getAllMunicipalitysSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        console.log(error, 'error');
        yield put(actions.getAllMunicipalitysFail());
    } finally {
        yield put(actions.addMunicipalityLoading(false));
    }
}

function* getMunicipalitytsByGovID({ payload }) {
    try {
        yield put(actions.addMunicipalityLoading(true));
        const result = yield call(api.getMunicipalitytsByGovID, payload);
        yield put(actions.getMunicipalitytsByGovIDSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        console.log(error, 'error');
        yield put(actions.getMunicipalitytsByGovIDFail());
    } finally {
        yield put(actions.addMunicipalityLoading(false));
    }
}


//Edit Tables
function* editMunicipality(formData) {
    try {
        yield put(actions.addMunicipalityLoading(true));
        const result = yield call(api.editMunicipalitys, formData.payload);
        yield put(actions.editMunicipalitySuccess(result.data));
        toast.success('Edit Successfully');
        console.log(formData)
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.editMunicipalityFail());
    } finally {
        yield put(actions.addMunicipalityLoading(false));
    }
}

// create
function* createMunicipality(formData) {
    try {
        yield put(actions.addMunicipalityLoading(true));
        const result = yield call(api.createMunicipalitys, formData.payload);
        yield put(
            actions.createMunicipalitySuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.createMunicipalityFail());
    } finally {
        yield put(actions.addMunicipalityLoading(false));
    }
}

function* deleteMunicipality({ payload }) {
    try {
        yield put(actions.addMunicipalityLoading(true));
        const result = yield call(api.deleteMunicipality, payload);

        yield put(actions.deleteMunicipalitySuccess(payload));

        toast.success('Deleted Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteMunicipalityFail());
    } finally {
        yield put(actions.addMunicipalityLoading(false));
    }
}

export default function* municipalitySaga() {
    yield takeLatest(Types.GET_MUNICIPALITYS_REQUEST, getAllMunicipalitys);
    yield takeLatest(Types.EDIT_MUNICIPALITY_REQUEST, editMunicipality);
    yield takeLatest(Types.CREATE_MUNICIPALITY_REQUEST, createMunicipality);
    yield takeLatest(Types.DELETE_MUNICIPALITY_REQUEST, deleteMunicipality);
    yield takeLatest(Types.GET_MUNICIPALITYS_BY_GOV_ID_REQUEST, getMunicipalitytsByGovID);


}
