import { actions } from './Reducer';

export const openCreateGovernmentModel = () => ({
    type: actions.OPEN_MODEL_1
});

export const closeCreateGovernmentModel = () => ({
    type: actions.CLOSE_MODEL_1
});

export const openCreateMunicipalityModel = () => ({
    type: actions.OPEN_MODEL_2
});

export const closeCreateMunicipalityModel = () => ({
    type: actions.CLOSE_MODEL_2
});

export const openEditMunicipalityModel = () => ({
    type: actions.OPEN_MODEL_3
});

export const closeEditMunicipalityModel = () => ({
    type: actions.CLOSE_MODEL_3
});

export const openCreateGradeModel = () => ({
    type: actions.OPEN_MODEL_4
});

export const closeCreateGradeModel = () => ({
    type: actions.CLOSE_MODEL_4
});

export const openCreateSubjectModel = () => ({
    type: actions.OPEN_MODEL_5
});

export const closeCreateSubjectModel = () => ({
    type: actions.CLOSE_MODEL_5
});

export const openCreateAuthorModel = () => ({
    type: actions.OPEN_MODEL_6
});

export const closeCreateAuthorModel = () => ({
    type: actions.CLOSE_MODEL_6
});
export const openCreateUserModel = () => ({
    type: actions.OPEN_MODEL_7
});

export const closeCreateUserModel = () => ({
    type: actions.CLOSE_MODEL_7
});

export const openCreateBookModel = () => ({
    type: actions.OPEN_MODEL_8
});

export const closeCreateBookModel = () => ({
    type: actions.CLOSE_MODEL_8
});

export const openCreateAreaVillageModel = () => ({
    type: actions.OPEN_MODEL_9
});

export const closeCreateAreaVillageModel = () => ({
    type: actions.CLOSE_MODEL_9
});

export const openEditAreaVillageModel = () => ({
    type: actions.OPEN_MODEL_19
});

export const closeEditAreaVillageModel = () => ({
    type: actions.CLOSE_MODEL_19
});

export const openCreateSchoolModel = () => ({
    type: actions.OPEN_MODEL_10
});

export const closeCreateSchoolModel = () => ({
    type: actions.CLOSE_MODEL_10
});

export const openCreateBookstoreModel = () => ({
    type: actions.OPEN_MODEL_11
});

export const closeCreateBookstoreModel = () => ({
    type: actions.CLOSE_MODEL_11
});

export const openCreateWholeSellerModel = () => ({
    type: actions.OPEN_MODEL_12
});

export const closeCreateWholeSellerModel = () => ({
    type: actions.CLOSE_MODEL_12
});

export const openCreateTeacherrModel = () => ({
    type: actions.OPEN_MODEL_13
});

export const closeCreateTeacherrModel = () => ({
    type: actions.CLOSE_MODEL_13
});

export const openCreateCenterModel = () => ({
    type: actions.OPEN_MODEL_14
});

export const closeCreateCenterModel = () => ({
    type: actions.CLOSE_MODEL_14
});

export const openCreatePlanModel = () => ({
    type: actions.OPEN_MODEL_15
});

export const closeCreatePlanModel = () => ({
    type: actions.CLOSE_MODEL_15
});

export const openAddPlanToUserModel = () => ({
    type: actions.OPEN_MODEL_16
});

export const closeAddPlanToUserlanModel = () => ({
    type: actions.CLOSE_MODEL_16
});

export const openVisitodel = () => ({
    type: actions.OPEN_MODEL_17
});

export const closeAVisitModel = () => ({
    type: actions.CLOSE_MODEL_17
});

export const openTeacherTypeModel = () => ({
    type: actions.OPEN_MODEL_18
});

export const closeTeacherTypeModel = () => ({
    type: actions.CLOSE_MODEL_18
});

export const openCreateVisitModel = () => ({
    type: actions.OPEN_MODEL_19
});

export const closeCreateVisitModel = () => ({
    type: actions.CLOSE_MODEL_19
});
