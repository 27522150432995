import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Flex,
    Text,
    Box,
    Button
} from '@chakra-ui/react';
import { closeCreateBookModel } from '../../modules/models/Actions';
import { Formik, Field, Form } from 'formik';
import { createBookRequest, editBookRequest } from '../../modules/books/Actions';
import { getAllSubjectsRequest } from '../../modules/subjects/Actions';
import { getAllGradesRequest } from '../../modules/grades/Actions';
import { getAllAuthorsRequest } from '../../modules/authors/Actions';
import createBookSchema from '../Schemas/createBook';

const CreateBooks = ({ data, addEdit }) => {
    const model = useSelector((state) => state.model);
    const dispatch = useDispatch();
    const authors = useSelector((state) => state.authors.authors);
    const subjects = useSelector((state) => state.subjects.subjects);
    const grades = useSelector((state) => state.grades.grades);
    const isLoading = useSelector((state) => state.books.isLoading);

    useEffect(() => {
        dispatch(getAllSubjectsRequest());
        dispatch(getAllGradesRequest());
        dispatch(getAllAuthorsRequest());
    }, []);

    return (
        <Modal
            isOpen={model.isOpen8}
            onClose={() => dispatch(closeCreateBookModel())}
            size="2xl"
            closeOnOverlayClick={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader padding="15px" bg="main_2" color={'white'}>
                    {addEdit ? 'اضافة' : 'تعديل'}
                </ModalHeader>
                <ModalCloseButton
                    color={'white'}
                    right="90%"
                    onClick={() => dispatch(closeCreateBookModel())}
                />
                <ModalBody>
                    <Formik
                        validationSchema={createBookSchema}
                        initialValues={
                            !addEdit
                                ? { ...data }
                                : {
                                      title: '',
                                      price: '',
                                      ISBN: '',
                                      author_id: '',
                                      subject_id: '',
                                      grade_id: '',
                                      book_year: '',
                                      max_discount: '',
                                      reg_date: '',
                                      prints_num: 0
                                  }
                        }
                        onSubmit={(values) => {
                            const action = () => {
                                dispatch(closeCreateBookModel());
                            };
                            if (addEdit) {
                                dispatch(createBookRequest(values, action));
                            } else {
                                dispatch(
                                    editBookRequest(
                                        {
                                            id: data?.id,
                                            formData: {
                                                title: values.title,
                                                price: values.price,
                                                ISBN: values.ISBN,
                                                author_id: values.author_id,
                                                subject_id: values.subject_id,
                                                grade_id: values.grade_id,
                                                book_year: values.book_year,
                                                max_discount: values.max_discount,
                                                reg_date: values.reg_date,
                                                prints_num: values.prints_num
                                            }
                                        },
                                        action
                                    )
                                );
                            }
                        }}>
                        {({ values, errors, touched, handleSubmit }) => (
                            <Form>
                                <Flex flexDirection="column" justify="space-between" width={'100%'}>
                                    <Flex alignItems={'center'} justifyContent="space-between">
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width={'45%'}
                                            mb="20px">
                                            <label htmlFor="title" style={{ marginBlock: '10px' }}>
                                                الاسم
                                            </label>
                                            <Field
                                                id="title"
                                                name="title"
                                                placeholder=""
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #8080803b',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                            {errors.title ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width={'45%'}
                                            mb="20px">
                                            <label htmlFor="price" style={{ marginBlock: '10px' }}>
                                                السعر
                                            </label>
                                            <Field
                                                id="price"
                                                name="price"
                                                placeholder=""
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #8080803b',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                            {errors.price ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Box>
                                    </Flex>
                                    <Flex alignItems={'center'} justifyContent="space-between">
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width={'45%'}
                                            mb="20px">
                                            <label htmlFor="ISBN" style={{ marginBlock: '10px' }}>
                                                ISBN
                                            </label>
                                            <Field
                                                id="ISBN"
                                                name="ISBN"
                                                placeholder=""
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #8080803b',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                            {errors.ISBN ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width={'45%'}
                                            mb="20px">
                                            <label
                                                htmlFor="prints_num"
                                                style={{ marginBlock: '10px' }}>
                                                عدد الطبعات
                                            </label>
                                            <Field
                                                id="prints_num"
                                                name="prints_num"
                                                placeholder=""
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #8080803b',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                            {errors.prints_num ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Box>
                                    </Flex>

                                    <Flex alignItems={'center'} justifyContent="space-between">
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width={'45%'}
                                            mb="20px">
                                            <label
                                                htmlFor="max_discount"
                                                style={{ marginBlock: '10px' }}>
                                                الخصم
                                            </label>
                                            <Field
                                                id="max_discount"
                                                name="max_discount"
                                                placeholder=""
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #8080803b',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                            {errors.max_discount ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width={'45%'}
                                            mb="20px">
                                            <label
                                                htmlFor="book_year"
                                                style={{ marginBlock: '10px' }}>
                                                سنة الاصدار
                                            </label>
                                            <Field
                                                id="book_year"
                                                name="book_year"
                                                placeholder=""
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #8080803b',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                            {errors.book_year ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Box>
                                    </Flex>
                                    <Flex flexDirection="column" w="100%">
                                        <label htmlFor="author_id" style={{ marginBlock: '10px' }}>
                                            الكاتب
                                        </label>
                                        <Field
                                            style={{
                                                border: '1px solid gray',
                                                padding: '5px',
                                                borderRadius: '5px',
                                                width: '100%'
                                            }}
                                            component="select"
                                            id="author_id"
                                            name="author_id"
                                            multiple={false}>
                                            <option>selecte option ...</option>

                                            {authors?.map((author, index) => {
                                                return (
                                                    <option value={author?.id}>
                                                        {author?.name}
                                                    </option>
                                                );
                                            })}
                                        </Field>

                                        {errors.author_id ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>

                                    <Flex flexDirection="column">
                                        <label htmlFor="subject_id" style={{ marginBlock: '10px' }}>
                                            المادة
                                        </label>
                                        <Field
                                            style={{
                                                border: '1px solid gray',
                                                padding: '5px',
                                                borderRadius: '5px',
                                                width: '100%'
                                            }}
                                            component="select"
                                            id="subject_id"
                                            name="subject_id"
                                            multiple={false}>
                                            <option>selecte option ...</option>

                                            {subjects?.map((subject, index) => {
                                                return (
                                                    <option value={subject?.id}>
                                                        {subject?.name}
                                                    </option>
                                                );
                                            })}
                                        </Field>

                                        {errors.subject_id ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>

                                    <Flex flexDirection="column">
                                        <label htmlFor="grade_id" style={{ marginBlock: '10px' }}>
                                            الصف الدراسي
                                        </label>
                                        <Field
                                            style={{
                                                border: '1px solid gray',
                                                padding: '5px',
                                                borderRadius: '5px',
                                                width: '100%'
                                            }}
                                            component="select"
                                            id="grade_id"
                                            name="grade_id"
                                            multiple={false}>
                                            <option>selecte option ...</option>

                                            {grades?.map((grade, index) => {
                                                return (
                                                    <option value={grade?.id}>{grade?.name}</option>
                                                );
                                            })}
                                        </Field>

                                        {errors.grade_id ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>

                                    <Button
                                        mt="20px"
                                        w="25%"
                                        type="submit"
                                        style={{ marginBlock: '10px' }}
                                        bg="main_2"
                                        isLoading={isLoading}
                                        alignSelf={'flex-end'}
                                        color="white">
                                        حفظ التغيرات
                                    </Button>
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CreateBooks;
