import Types from './Types';

const INITIAL_STATE = {
    schools: [],
    school: {},
    isLoading: false,
    count: ''
};

export default function schools(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_SCHOOLS_SUCCESS: {
            return {
                ...state,
                schools: payload?.message ? [] : payload.data?.data,
                count: payload?.message ? 0 : payload.data?.pagination?.total_records
            };
        }

        case Types.GET_SCHOOL_SUCCESS: {
            return {
                ...state,
                school: payload
            };
        }

        case Types.EDIT_SCHOOL_SUCCESS: {
            let data = payload.data;
            const schools = state.schools;
            let idx = 0;
            schools.forEach((table, index) => {
                if (table.id === data.id) {
                    idx = index;
                    return;
                }
            });

            schools[idx] = data;
            return {
                ...state,
                schools: schools
            };
        }

        // create
        case Types.CREATE_SCHOOL_SUCCESS: {
            const data = payload.data.data;
            let newArray = state.schools.slice();
            let index = state.schools.length + 1;
            newArray.splice(index, 0, data);
            return {
                ...state,
                schools: newArray
            };
        }
        case Types.ADD_SCHOOLS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        case Types.DELETE_SCHOOL_SUCCESS: {
            const newschools = state.schools.filter((item) => item.id !== payload);
            return {
                ...state,
                schools: newschools
            };
        }
        default: {
            return state;
        }
    }
}
