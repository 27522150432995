import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    Select,
    ModalCloseButton,
    ModalBody,
    Flex,
    Text,
    Box,
    Button
} from '@chakra-ui/react';
import { closeCreateSchoolModel } from '../../modules/models/Actions';
import { Formik, Field, Form } from 'formik';

import { getAllGovernmentsRequest } from '../../modules/governments/Actions';
import { getAllAreaVillagesRequest } from '../../modules/areaVillage/Actions';
import { createSchoolRequest, editSchoolRequest } from '../../modules/schools/Actions';
import createSchoolsSchema from '../Schemas/createٍSchools';
import { toast } from 'react-toastify';

const CreateSchools = ({ data, addEdit }) => {
    const model = useSelector((state) => state.model);
    const dispatch = useDispatch();
    const governments = useSelector((state) => state.governments.governments);
    const areaVillages = useSelector((state) => state.areaVillages.areaVillages);
    const isLoading = useSelector((state) => state.books.isLoading);
    const [govID, setGovID] = useState('');
    useEffect(() => {
        dispatch(getAllGovernmentsRequest());
    }, []);

    useEffect(() => {
        if (data?.gov_id) setGovID(data?.gov_id);
    }, [data?.gov_id]);
    useEffect(() => {
        if (govID) dispatch(getAllAreaVillagesRequest(govID));
    }, [govID]);

    console.log('govID', govID);
    return (
        <Modal
            isOpen={model.isOpen10}
            onClose={() => dispatch(closeCreateSchoolModel())}
            size="2xl"
            closeOnOverlayClick={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader padding="15px" bg="main_2" color={'white'}>
                    {addEdit ? 'اضافة' : 'تعديل'}
                </ModalHeader>
                <ModalCloseButton
                    color={'white'}
                    right="90%"
                    onClick={() => dispatch(closeCreateSchoolModel())}
                />
                <ModalBody>
                    <Formik
                        validationSchema={createSchoolsSchema}
                        initialValues={
                            !addEdit
                                ? { ...data }
                                : {
                                      name: '',
                                      address: '',
                                      type: '',
                                      gov_id: govID,
                                      area_vil: ''
                                  }
                        }
                        onSubmit={(values) => {
                            if (!govID) {
                                toast.error('اسم المحافظة مطلوب');
                                return;
                            }
                            const action = () => {
                                dispatch(closeCreateSchoolModel());
                            };
                            if (addEdit) {
                                dispatch(createSchoolRequest({ ...values, gov_id: govID }, action));
                            } else {
                                dispatch(
                                    editSchoolRequest(
                                        {
                                            id: data?.id,
                                            formData: {
                                                name: values.name,
                                                address: values.address,
                                                type: values.type,
                                                gov_id: govID,
                                                area_vil: values.area_vil
                                            }
                                        },
                                        action
                                    )
                                );
                            }
                        }}>
                        {({ values, errors, touched, handleSubmit }) => (
                            <Form>
                                <Flex flexDirection="column" justify="space-between" width={'100%'}>
                                    <Flex alignItems={'center'} justifyContent="space-between">
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width={'45%'}
                                            mb="20px">
                                            <label htmlFor="name" style={{ marginBlock: '10px' }}>
                                                الاسم
                                            </label>
                                            <Field
                                                id="name"
                                                name="name"
                                                placeholder=""
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #8080803b',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                            {errors.name ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Box>
                                    </Flex>

                                    <Flex alignItems={'center'} justifyContent="space-between">
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width={'45%'}
                                            mb="20px">
                                            <label
                                                htmlFor="address"
                                                style={{ marginBlock: '10px' }}>
                                                العنوان
                                            </label>
                                            <Field
                                                id="address"
                                                name="address"
                                                placeholder=""
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #8080803b',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                            {errors.address ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width={'45%'}
                                            mb="20px">
                                            <label htmlFor="type" style={{ marginBlock: '10px' }}>
                                                النوع
                                            </label>
                                            <Field
                                                id="type"
                                                name="type"
                                                placeholder=""
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #8080803b',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                            {errors.type ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Box>
                                    </Flex>
                                    <Flex flexDirection="column" w="100%">
                                        <label htmlFor="gov_id" style={{ marginBlock: '10px' }}>
                                            المحافظة
                                        </label>
                                        <Select
                                            onChange={(e) => setGovID(e.target.value)}
                                            dir="ltr"
                                            defaultValue={govID}>
                                            <option>selecte option ...</option>
                                            {governments?.map((government, index) => {
                                                return (
                                                    <option value={government?.id}>
                                                        {government?.name}
                                                    </option>
                                                );
                                            })}
                                        </Select>

                                        {errors.gov_id ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>

                                    <Flex flexDirection="column">
                                        <label htmlFor="area_vil" style={{ marginBlock: '10px' }}>
                                            القري\المدينة
                                        </label>
                                        <Field
                                            style={{
                                                border: '1px solid gray',
                                                padding: '5px',
                                                borderRadius: '5px',
                                                width: '100%',
                                                direction: 'ltr'
                                            }}
                                            component="select"
                                            id="area_vil"
                                            name="area_vil"
                                            multiple={false}>
                                            <option>selecte option ...</option>

                                            {areaVillages?.map((areaVillage, index) => {
                                                return (
                                                    <option value={areaVillage?.id}>
                                                        {areaVillage?.name}
                                                    </option>
                                                );
                                            })}
                                        </Field>

                                        {errors.area_vil ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>

                                    <Button
                                        mt="20px"
                                        w="25%"
                                        type="submit"
                                        style={{ marginBlock: '10px' }}
                                        bg="main_2"
                                        isLoading={isLoading}
                                        alignSelf={'flex-end'}
                                        color="white">
                                        حفظ التغيرات
                                    </Button>
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CreateSchools;
