import React from 'react';
import DashboardLayout from '../../components/Layout/DashboardLayout';
import AuthWrapper from '../../components/Auth/AuthWrapper';
import { Flex, Image, Text, Box } from '@chakra-ui/react';

const Dashboard = () => {
    return (
        <AuthWrapper roles={['Super Admin', 'Moderator', 'Sale Person']}>
            <DashboardLayout title={'الصفحة الرئيسية'}>
                <Flex>
                    <Image src="/assets/images/homelogo.png" />
                    <Box>
                        <Text>Welcome to Sindbad Dashoard</Text>
                    </Box>
                </Flex>
            </DashboardLayout>
        </AuthWrapper>
    );
};

export default Dashboard;
