import axiosInstance from '../../helpers/api';

export const getAllMunicipalityts = (formData) => {
    console.log(formData, 'formData')
    return axiosInstance.post(`/municipality/search?page=${formData.payload.page}`, formData?.payload?.body);
};

export const getMunicipalitytsByGovID = (id) => {
    return axiosInstance.get(`/municipality/${id}`);
};


export const editMunicipalitys = (formData) => {
    return axiosInstance.put(`/municipality/${formData.id}`, formData.formData);
};


export const createMunicipalitys = (formData) => {
    return axiosInstance.post(`/municipality/${formData?.id}`, formData.formData);
};

export const deleteMunicipality = (id) => {
    return axiosInstance.delete(`/municipality/${id}`);
};
