import Types from './Types';


export const getAllBooksRequest = (body) => ({
    type: Types.GET_BOOKS_REQUEST,
    payload: body
});

export const getAllBooksSuccess = (data) => ({
    type: Types.GET_BOOKS_SUCCESS,
    payload: data
});

export const getAllBooksFail = () => ({
    type: Types.GET_BOOKS_FAIL
});


export const editBookRequest = (formData, action) => ({
    type: Types.EDIT_BOOK_REQUEST,
    payload: formData,
    action: action
});

export const editBookSuccess = (data) => ({
    type: Types.EDIT_BOOK_SUCCESS,
    payload: data
});

export const editBookFail = () => ({
    type: Types.EDIT_BOOK_FAIL
});

//LOADING
export const addBookLoading = (isLoading) => ({
    type: Types.ADD_BOOKS_LOADING,
    payload: isLoading
});

// Create Book
export const createBookRequest = (formData, action) => ({
    type: Types.CREATE_BOOK_REQUEST,
    payload: { ...formData },
    action: action
});

export const createBookSuccess = ({ data }) => ({
    type: Types.CREATE_BOOK_SUCCESS,
    payload: {
        data
    }
});

export const createBookFail = () => ({
    type: Types.CREATE_BOOK_FAIL
});

export const deleteBookRequest = (id) => ({
    type: Types.DELETE_BOOK_REQUEST,
    payload: id
});

export const deleteBookSuccess = (id) => ({
    type: Types.DELETE_BOOK_SUCCESS,
    payload: id
});

export const deleteBookFail = () => ({
    type: Types.DELETE_BOOK_FAIL
});
