import axiosInstance from '../../helpers/api';

export const getAllSchools = (data) => {
    console.log(data)

    return axiosInstance.post(`/schools/search?page=${data?.page}`, data.payload);

    // return axiosInstance.get(`/schools/${data?.payload}?page=${data?.page}`,);
};



export const editSchools = (formData) => {

    return axiosInstance.put(`/schools/${formData.id}`, formData.formData);
};


export const createSchools = (formData) => {
    return axiosInstance.post('/schools', formData);
};

export const deleteSchool = (id) => {
    return axiosInstance.delete(`/schools/${id}`);
};
