export default {
    //get all users
    GET_GOVERNMENTS_REQUEST: 'GET_GOVERNMENTS_REQUEST',
    GET_GOVERNMENTS_SUCCESS: 'GET_GOVERNMENTS_SUCCESS',
    GET_GOVERNMENTS_FAIL: 'GET_GOVERNMENTS_FAIL',

    //edit users
    EDIT_GOVERNMENT_REQUEST: 'EDIT_GOVERNMENT_REQUEST',
    EDIT_GOVERNMENT_SUCCESS: 'EDIT_GOVERNMENT_SUCCESS',
    EDIT_GOVERNMENT_FAIL: 'EDIT_GOVERNMENT_FAIL',

    //create user
    CREATE_GOVERNMENT_REQUEST: 'CREATE_GOVERNMENT_REQUEST',
    CREATE_GOVERNMENT_SUCCESS: 'CREATE_GOVERNMENT_SUCCESS',
    CREATE_GOVERNMENT_FAIL: 'CREATE_GOVERNMENT_FAIL',

    DELETE_GOVERNMENT_REQUEST: 'DELETE_GOVERNMENT_REQUEST',
    DELETE_GOVERNMENT_SUCCESS: 'DELETE_GOVERNMENT_SUCCESS',
    DELETE_GOVERNMENT_FAIL: 'DELETE_GOVERNMENT_FAIL',

    GET_GOVERNMENT_REQUEST: 'GET_GOVERNMENT_REQUEST',
    GET_GOVERNMENT_SUCCESS: 'GET_GOVERNMENT_SUCCESS',
    GET_GOVERNMENT_FAIL: 'GET_GOVERNMENT_FAIL',

    //loading
    ADD_GOVERNMENTS_LOADING: 'ADD_GOVERNMENTS_LOADING'
};
