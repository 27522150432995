export default {
    //get all users
    GET_WHOLESALERS_REQUEST: 'GET_WHOLESALERS_REQUEST',
    GET_WHOLESALERS_SUCCESS: 'GET_WHOLESALERS_SUCCESS',
    GET_WHOLESALERS_FAIL: 'GET_WHOLESALERS_FAIL',

    //edit users
    EDIT_WHOLESALER_REQUEST: 'EDIT_WHOLESALER_REQUEST',
    EDIT_WHOLESALER_SUCCESS: 'EDIT_WHOLESALER_SUCCESS',
    EDIT_WHOLESALER_FAIL: 'EDIT_WHOLESALER_FAIL',

    //create user
    CREATE_WHOLESALER_REQUEST: 'CREATE_WHOLESALER_REQUEST',
    CREATE_WHOLESALER_SUCCESS: 'CREATE_WHOLESALER_SUCCESS',
    CREATE_WHOLESALER_FAIL: 'CREATE_WHOLESALER_FAIL',

    DELETE_WHOLESALER_REQUEST: 'DELETE_WHOLESALER_REQUEST',
    DELETE_WHOLESALER_SUCCESS: 'DELETE_WHOLESALER_SUCCESS',
    DELETE_WHOLESALER_FAIL: 'DELETE_WHOLESALER_FAIL',

    GET_WHOLESALER_REQUEST: 'GET_WHOLESALER_REQUEST',
    GET_WHOLESALER_SUCCESS: 'GET_WHOLESALER_SUCCESS',
    GET_WHOLESALER_FAIL: 'GET_WHOLESALER_FAIL',

    //loading
    ADD_WHOLESALERS_LOADING: 'ADD_WHOLESALERS_LOADING'
};
