import * as Yup from 'yup';

const createPlanSchema = Yup.object().shape({

    name: Yup.string().min(2, 'Too Short!').max(70, 'Too Long!').required('Required'),
    gov_id: Yup.string(),
    areas: Yup.array().required('Required'),
    muni_id: Yup.string().required('Required')
});

export default createPlanSchema;