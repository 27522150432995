import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik, Form } from 'formik';
import { Button, FormLabel, Heading, Text, Flex, Box, Icon, Image } from '@chakra-ui/react';
import { loginUserAction } from '../../modules/auth/Actions';
import Cookies from 'js-cookie';
import loGinImg from '../../assets/login.webp';
import { BiLock, BiEnvelope, BiShow, BiLowVision } from 'react-icons/bi';
const LoginForm = (props) => {
    const token = Cookies.get('token');
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.auth.isLoading);
    const data = useSelector((state) => state.auth.user);

    const [inputType, setInputType] = useState('password');
    const validateUserName = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        }
        return error;
    };
    const validatePassword = (value) => {
        let error;
        if (!value) {
            error = 'Required';
        }
        return error;
    };

    useEffect(() => {
        if (data?.role[0]) {
            if (data?.role[0]?.name == 'Super Admin') window.location.href = '/';

            if (data?.role[0]?.name == 'Teacher') window.location.href = '/dashboard';
            else if (data?.role[0]?.name == 'Student') window.location.href = '/';
            else if (data?.role[0]?.name == 'Sale Person') window.location.href = '/salesperson';
            else if (data?.role[0]?.name == 'Moderator') window.location.href = '/plans';
        }
    }, [data]);
    return (
        <Flex overflow={'hidden'} h="100vh">
            {/* right side */}
            <Image src={loGinImg} w="50%" h="100%" />

            {/* left side */}
            <Flex
                bg="whiteAlpha.700"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                w="100%"
                h="100%">
                <Heading mb="60px" mt="20px" color="main_1" fontSize={'28px'}>
                    تسجيل الدخول الي لوحة التحكم
                </Heading>
                <Formik
                    initialValues={{
                        email: '',
                        password: ''
                    }}
                    onSubmit={(values) => {
                        let formData = new FormData();
                        formData.append('phone', values.email);
                        formData.append('password', values.password);

                        dispatch(loginUserAction(formData));
                    }}>
                    {({ values, handleSubmit, handleChange, dirty, errors, touched }) => (
                        <Form style={{ paddingInline: '15px' }}>
                            <Box>
                                <Flex alignItems={'center'}>
                                    <Icon
                                        as={BiEnvelope}
                                        fontSize="20px"
                                        ml="7px"
                                        mb="10px"
                                        color="main_1"
                                    />
                                    <FormLabel
                                        htmlFor="email"
                                        className="form-label "
                                        fontWeight="600">
                                        الايميل
                                    </FormLabel>
                                </Flex>

                                <Field
                                    style={{
                                        width: '320px',
                                        border: '1px solid #777',
                                        borderRadius: '5px',
                                        padding: '5px',
                                        marginBlock: '10px'
                                    }}
                                    name="email"
                                    validate={validateUserName}
                                />
                                {errors.email && touched.email && (
                                    <Text color="red">{errors.email}</Text>
                                )}
                            </Box>
                            <Box mt="10px" position={'relative'}>
                                <Flex alignItems={'center'}>
                                    <Icon
                                        as={BiLock}
                                        fontSize="20px"
                                        ml="7px"
                                        mb="10px"
                                        color="main_1"
                                    />
                                    <FormLabel
                                        htmlFor="password"
                                        className="form-label "
                                        fontWeight="600">
                                        كلمة المرور
                                    </FormLabel>
                                </Flex>

                                <Field
                                    style={{
                                        width: '320px',
                                        border: '1px solid #777',
                                        borderRadius: '5px',
                                        padding: '5px',
                                        marginBlock: '10px'
                                    }}
                                    name="password"
                                    validate={validatePassword}
                                    type={inputType}
                                />

                                <Icon
                                    position={'absolute'}
                                    left="10px"
                                    top="50px"
                                    fontSize="20px"
                                    color="main_1"
                                    as={inputType == 'password' ? BiShow : BiLowVision}
                                    onClick={() =>
                                        inputType == 'password'
                                            ? setInputType('text')
                                            : setInputType('password')
                                    }
                                />
                                {errors.password && touched.password && (
                                    <Text color="red" w="180px" textAlign="justify">
                                        {errors.password}
                                    </Text>
                                )}
                            </Box>
                            <Button
                                mt="40px"
                                type="submit"
                                w="150px"
                                float={'left'}
                                isLoading={isLoading}
                                bg="gradient_main_color"
                                color="white">
                                تسجيل الدخول
                            </Button>
                        </Form>
                    )}
                </Formik>
            </Flex>
        </Flex>
    );
};

export default LoginForm;
