import * as Yup from 'yup';

const createTacherSchema = Yup.object().shape({

    name: Yup.string().min(2, 'Too Short!').max(70, 'Too Long!').required('Required'),
    type: Yup.string(),
    gov_id: Yup.string().required('Required'),
    muni_id: Yup.string().required('Required'),
    area_vil: Yup.string(),
    subjects: Yup.array(),
    grades: Yup.array(),
    school_id: Yup.string(),
    phone: Yup.string().min(11, 'Too Short!').max(12, 'Too Long!').required('Required'),





});

export default createTacherSchema;