import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Flex,
    Text,
    Box,
    Button,
    Input,
    Icon
} from '@chakra-ui/react';
import { closeCreateAreaVillageModel } from '../../../modules/models/Actions';
import { Formik, Form } from 'formik';
import { AiFillMinusCircle } from 'react-icons/ai';
import { BsFillPlusCircleFill } from 'react-icons/bs';
import { getAllGovernmentsRequest } from '../../../modules/governments/Actions';
import { getMunicipalitytsByGovIDRequest } from '../../../modules/municipality/Actions';
import { createAreaVillageRequest } from '../../../modules/areaVillage/Actions';

const CreateAreaVillage = () => {
    const model = useSelector((state) => state.model);
    const dispatch = useDispatch();
    const [govID, setGovID] = useState('');
    const municipalitys = useSelector((state) => state.municipalitys.municipalitys);
    const governments = useSelector((state) => state.governments.governments);
    const isLoading = useSelector((state) => state.areaVillages.isLoading);
    const [fieldsCount, setFieldsCount] = useState(0);
    const [data, setData] = useState([]);
    useEffect(() => {
        dispatch(getAllGovernmentsRequest());
    }, []);
    useEffect(() => {
        if (govID) dispatch(getMunicipalitytsByGovIDRequest(govID));
    }, [govID]);

    useEffect(() => {
        if (data?.gov_id) setGovID(data?.gov_id);
    }, [data?.gov_id]);
    const govOptions = governments?.map((data) => {
        return {
            label: data?.name,
            value: data?.id
        };
    });
    const municipalitysOptions = municipalitys?.map((data) => {
        return {
            label: data?.name,
            value: data?.id
        };
    });
    return (
        <Modal
            isOpen={model.isOpen9}
            onClose={() => dispatch(closeCreateAreaVillageModel())}
            size="2xl"
            closeOnOverlayClick={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader padding="15px" bg="main_2" color={'white'}>
                    اضافة
                </ModalHeader>
                <ModalCloseButton
                    color={'white'}
                    right="90%"
                    onClick={() => dispatch(closeCreateAreaVillageModel())}
                />
                <ModalBody>
                    <Formik
                        initialValues={{
                            name: '',
                            gov_id: '',
                            muni_id: ''
                        }}
                        onSubmit={(values) => {
                            const action = () => {
                                dispatch(closeCreateAreaVillageModel());
                            };
                            data?.map((val) => {
                                dispatch(
                                    createAreaVillageRequest({ ...values, name: val?.name }, action)
                                );
                            });
                        }}>
                        {({ values, errors, setValues, handleSubmit }) => (
                            <Form>
                                <Flex flexDirection="column" justify="space-between" width={'100%'}>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        width={'100%'}
                                        mb="20px">
                                        <label htmlFor="name" style={{ marginBlock: '10px' }}>
                                            الاسم
                                        </label>
                                        <Flex
                                            my="5px"
                                            alignItems={'center'}
                                            justifyContent={'center'}>
                                            <Input
                                                onChange={(e) => {
                                                    let newData = data;
                                                    newData[0] = { name: e.target.value };
                                                    setData(newData);
                                                }}
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #8080803b',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                            <Icon
                                                as={BsFillPlusCircleFill}
                                                mx={'10px'}
                                                color="main_2"
                                                fontSize={'18px'}
                                                cursor="pointer"
                                                onClick={() => setFieldsCount(fieldsCount + 1)}
                                            />
                                        </Flex>

                                        {[...Array(fieldsCount)].map((val, index) => {
                                            return (
                                                <Flex
                                                    my="5px"
                                                    alignItems={'center'}
                                                    justifyContent={'center'}>
                                                    <Input
                                                        onChange={(e) => {
                                                            let newData = data;
                                                            let newIndex = index + 1;
                                                            newData[newIndex] = {
                                                                name: e.target.value
                                                            };
                                                            setData(newData);
                                                        }}
                                                        style={{
                                                            width: '100%',
                                                            border: '1px solid #8080803b',
                                                            padding: '5px',
                                                            borderRadius: '5px'
                                                        }}
                                                    />

                                                    <Icon
                                                        as={AiFillMinusCircle}
                                                        mx={'10px'}
                                                        color="main_2"
                                                        fontSize={'22px'}
                                                        cursor="pointer"
                                                        onClick={() => {
                                                            setFieldsCount(fieldsCount - 1);
                                                            let newData = data;
                                                            const filteredData = newData.filter(
                                                                (val, idx) => idx != index + 1
                                                            );
                                                            setData(filteredData);
                                                        }}
                                                    />
                                                </Flex>
                                            );
                                        })}
                                    </Box>

                                    <Flex flexDirection="column">
                                        <label htmlFor="gov_id" style={{ marginBlock: '10px' }}>
                                            المحافظة
                                        </label>
                                        <Select
                                            options={govOptions}
                                            defaultValue={{
                                                value: values?.gov_id,
                                                label: values?.gov_name
                                            }}
                                            name="gov_id"
                                            onChange={(val) => {
                                                setGovID(val?.value);
                                                setValues({ ...values, gov_id: val?.value });
                                            }}
                                        />

                                        {errors.gov_id ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>

                                    <Flex flexDirection="column" w="100%">
                                        <label htmlFor="muni_id" style={{ marginBlock: '10px' }}>
                                            الادارة
                                        </label>
                                        <Select
                                            options={municipalitysOptions}
                                            defaultValue={{
                                                value: values?.muni_id,
                                                label: values?.muni_name
                                            }}
                                            name="muni_id"
                                            onChange={(val) =>
                                                setValues({ ...values, muni_id: val?.value })
                                            }
                                        />
                                        {errors.muni_id ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>
                                    <Button
                                        mt="20px"
                                        w="25%"
                                        type="submit"
                                        style={{ marginBlock: '10px' }}
                                        bg="main_2"
                                        isLoading={isLoading}
                                        alignSelf={'flex-end'}
                                        color="white">
                                        حفظ التغيرات
                                    </Button>
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CreateAreaVillage;
