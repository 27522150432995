import Types from './Types';


export const getAllWholesalersRequest = (id) => ({
    type: Types.GET_WHOLESALERS_REQUEST,
    payload: id
});

export const getAllWholesalersSuccess = (data) => ({
    type: Types.GET_WHOLESALERS_SUCCESS,
    payload: data
});

export const getAllWholesalersFail = () => ({
    type: Types.GET_WHOLESALERS_FAIL
});


export const editWholesalerRequest = (formData, action) => ({
    type: Types.EDIT_WHOLESALER_REQUEST,
    payload: formData,
    action: action
});

export const editWholesalerSuccess = (data) => ({
    type: Types.EDIT_WHOLESALER_SUCCESS,
    payload: data
});

export const editWholesalerFail = () => ({
    type: Types.EDIT_WHOLESALER_FAIL
});

//LOADING
export const addWholesalerLoading = (isLoading) => ({
    type: Types.ADD_WHOLESALERS_LOADING,
    payload: isLoading
});

// Create Wholesalers
export const createWholesalerRequest = (formData, action) => ({
    type: Types.CREATE_WHOLESALER_REQUEST,
    payload: { ...formData },
    action: action
});

export const createWholesalerSuccess = ({ data }) => ({
    type: Types.CREATE_WHOLESALER_SUCCESS,
    payload: {
        data
    }
});

export const createWholesalerFail = () => ({
    type: Types.CREATE_WHOLESALER_FAIL
});

export const deleteWholesalerRequest = (id) => ({
    type: Types.DELETE_WHOLESALER_REQUEST,
    payload: id
});

export const deleteWholesalerSuccess = (id) => ({
    type: Types.DELETE_WHOLESALER_SUCCESS,
    payload: id
});

export const deleteWholesalerFail = () => ({
    type: Types.DELETE_WHOLESALER_FAIL
});
