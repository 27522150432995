import axios from 'axios';

import { API_URL, LIVE_API_URL } from '../constants';
import Cookie from 'js-cookie';

const axiosInstance = axios.create({
    baseURL:
        process.env.REACT_APP_NODE_ENV === 'development' ? `${API_URL}/api` : `${LIVE_API_URL}/api`,
    responseType: 'json'
});

axiosInstance.interceptors.request.use((config) => {
    const token = Cookie.get('token');
    const authorizedConfig = { ...config };
    if (token) {
        authorizedConfig.headers.Authorization = `Bearer ${token}`;
        authorizedConfig.headers['Content-Type'] = 'application/json;charset=utf-8';
    }
    return config;
});

export default axiosInstance;
