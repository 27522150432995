export default {
    //get all users
    GET_SUBJECTS_REQUEST: 'GET_SUBJECTS_REQUEST',
    GET_SUBJECTS_SUCCESS: 'GET_SUBJECTS_SUCCESS',
    GET_SUBJECTS_FAIL: 'GET_SUBJECTS_FAIL',

    //edit users
    EDIT_SUBJECT_REQUEST: 'EDIT_SUBJECT_REQUEST',
    EDIT_SUBJECT_SUCCESS: 'EDIT_SUBJECT_SUCCESS',
    EDIT_SUBJECT_FAIL: 'EDIT_SUBJECT_FAIL',

    //create user
    CREATE_SUBJECT_REQUEST: 'CREATE_SUBJECT_REQUEST',
    CREATE_SUBJECT_SUCCESS: 'CREATE_SUBJECT_SUCCESS',
    CREATE_SUBJECT_FAIL: 'CREATE_SUBJECT_FAIL',

    DELETE_SUBJECT_REQUEST: 'DELETE_SUBJECT_REQUEST',
    DELETE_SUBJECT_SUCCESS: 'DELETE_SUBJECT_SUCCESS',
    DELETE_SUBJECT_FAIL: 'DELETE_SUBJECT_FAIL',

    GET_SUBJECT_REQUEST: 'GET_SUBJECT_REQUEST',
    GET_SUBJECT_SUCCESS: 'GET_SUBJECT_SUCCESS',
    GET_SUBJECT_FAIL: 'GET_SUBJECT_FAIL',

    //loading
    ADD_SUBJECTS_LOADING: 'ADD_SUBJECTS_LOADING'
};
