import { combineReducers } from 'redux';
import modelsReducer from '../modules/models/Reducer';
import authReducer from '../modules/auth/Reducer';
import userDataReducer from '../modules/userData/Reducer'
import governmentsReducer from '../modules/governments/Reducer'
import municipalitysReducer from '../modules/municipality/Reducer'
import gradesReducer from '../modules/grades/Reducer'
import subjectsReducer from '../modules/subjects/Reducer'
import usersRducer from '../modules/users/Reducer'
import booksReducer from '../modules/books/Reducer';
import authorsReducer from '../modules/authors/Reducer'
import areaVillagesReducer from '../modules/areaVillage/Reducer'
import schoolsReducer from '../modules/schools/Reducer'
import bookstoresReducer from '../modules/bookstores/Reducer'
import wholesalersReducer from '../modules/wholesalers/Reducer'
import centersReducer from '../modules/centers/Reducer'
import teachersReducer from '../modules/teachers/Reducer'
import plansReducer from '../modules/plans/Reducer'
import visitsReducer from '../modules/visits/Reducer'
import typessReducer from '../modules/teachers-types/Reducer'
// import {actions as authActions} from "../modules/auth/Reducer";

const appReducer = combineReducers({
    userData: userDataReducer,
    model: modelsReducer,
    auth: authReducer,
    governments: governmentsReducer,
    municipalitys: municipalitysReducer,
    grades: gradesReducer,
    subjects: subjectsReducer,
    users: usersRducer,
    books: booksReducer,
    authors: authorsReducer,
    areaVillages: areaVillagesReducer,
    schools: schoolsReducer,
    bookstores: bookstoresReducer,
    wholesalers: wholesalersReducer,
    centers: centersReducer,
    teachers: teachersReducer,
    plans: plansReducer,

    visits: visitsReducer,

    types: typessReducer
});

const rootReducer = (state, action) => {
    return appReducer(state, action);
};

export default rootReducer;
