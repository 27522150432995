import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DashboardLayout from '../../components/Layout/DashboardLayout';
import { openCreateAuthorModel } from '../../modules/models/Actions';
import { useSelector } from 'react-redux';
import {
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuItemOption,
    MenuList,
    MenuOptionGroup,
    Text
} from '@chakra-ui/react';
import CTable from '../../components/table/table';
import { HiDotsVertical } from 'react-icons/hi';
import { RiDeleteBin6Line, RiFileEditLine } from 'react-icons/ri';
import CreateAuthor from '../../components/models/createAuthor';
import { deleteAuthorRequest, getAllAuthorsRequest } from '../../modules/authors/Actions';
import AuthWrapper from '../../components/Auth/AuthWrapper';

const Authors = () => {
    const dispatch = useDispatch();
    const [addEdit, setAddEdit] = useState(true);
    const authors = useSelector((state) => state.authors.authors);
    const isLoading = useSelector((state) => state.authors.isLoading);
    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [selected, setSelected] = useState({
        id: ''
    });

    useEffect(() => {
        dispatch(getAllAuthorsRequest());
    }, []);

    const Data = authors?.map((data) => {
        return {
            ...data,
            id: data?.id,
            name: data?.name,
            phone: data?.phone,
            books: data?.books?.map((book, index) => {
                return <Text key={index}>{book?.name}</Text>;
            })
        };
    });

    const actions = (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HiDotsVertical />}
                size="sm"
                fontSize="20px"
                variant="outline"
                border="none"
            />
            <MenuList>
                <MenuItem
                    icon={<RiFileEditLine />}
                    onClick={() => {
                        setAddEdit(false);
                        dispatch(openCreateAuthorModel());
                    }}>
                    تعديل
                </MenuItem>
                <MenuItem
                    onClick={() => dispatch(deleteAuthorRequest(selected?.id))}
                    icon={<RiDeleteBin6Line />}>
                    حذف
                </MenuItem>
            </MenuList>
        </Menu>
    );
    const columns = [
        {
            Header: 'اسم المؤلف',
            accessor: 'name'
        },
        {
            Header: 'رقم الهاتف',
            accessor: 'phone'
        },
        {
            Header: 'الكتب',
            accessor: 'books'
        },

        {
            Header: '',
            accessor: 'Actions'
        }
    ];

    const handelFilter = (e) => {};
    const viewData = (data) => {
        setSelected(data);
    };

    const filterList = (
        <MenuOptionGroup type="checkbox" onChange={(e) => handelFilter(e)}>
            <MenuItemOption value="2"></MenuItemOption>
            <MenuItemOption value="3"></MenuItemOption>
        </MenuOptionGroup>
    );
    return (
        <AuthWrapper roles={['Super Admin']}>
            <DashboardLayout title={' المؤلفين'}>
                <CTable
                    selectedData={viewData}
                    Columns={columns}
                    Data={Data}
                    path={openCreateAuthorModel}
                    Actions={actions}
                    Title=" "
                    subTitle={``}
                    btnTitle="hi"
                    placeHolder=""
                    noSearchBar={true}
                    noFilter={true}
                    footerBtnTitle
                    filterList
                    addButton
                    addButtonTitle="اضافة  "
                    setPage={setPage}
                    setPerPage={setPerPage}
                    currentpage={pageNumber}
                    setPageNumber={setPageNumber}
                    noSecondSearchBar={true}
                    isLoading={isLoading}
                    perPage={size}
                    totalPage={Math.ceil(authors?.length / 100)}
                    setAddEdit={setAddEdit}
                />

                <CreateAuthor data={selected} addEdit={addEdit} />
            </DashboardLayout>
        </AuthWrapper>
    );
};

export default Authors;
