import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DashboardLayout from '../../components/Layout/DashboardLayout';
import { openCreateBookstoreModel } from '../../modules/models/Actions';
import { useSelector } from 'react-redux';
import GovList from '../../components/UI/govList';

import {
    Button,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuItemOption,
    MenuList,
    MenuOptionGroup
} from '@chakra-ui/react';
import CTable from '../../components/table/table';
import { HiDotsVertical } from 'react-icons/hi';
import { RiDeleteBin6Line, RiFileEditLine } from 'react-icons/ri';
import AuthWrapper from '../../components/Auth/AuthWrapper';

import { deleteBookstoreRequest, getAllBookstoresRequest } from '../../modules/bookstores/Actions';
import CreateBookstore from '../../components/models/createBookstore';

const Bookstores = () => {
    const dispatch = useDispatch();
    const [addEdit, setAddEdit] = useState(true);
    const [step, setStep] = useState(true);
    const bookstores = useSelector((state) => state.bookstores.bookstores);
    const count = useSelector((state) => state.bookstores.count);

    const governments = useSelector((state) => state.governments.governments);

    const isLoading = useSelector((state) => state.bookstores.isLoading);
    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(100);
    const [pageNumber, setPageNumber] = useState(0);
    const [govID, setGovID] = useState('');

    const [selected, setSelected] = useState({
        id: ''
    });

    useEffect(() => {
        if (govID) dispatch(getAllBookstoresRequest(govID, pageNumber + 1));
    }, [govID, pageNumber]);

    const Data = bookstores?.map((data) => {
        return {
            ...data,
            id: data?.id,
            owner: data?.owner,
            gov_id: data?.area?.gov_id,
            area_vil: data?.area?.id
        };
    });

    const actions = (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HiDotsVertical />}
                size="sm"
                fontSize="20px"
                variant="outline"
                border="none"
            />
            <MenuList>
                <MenuItem
                    icon={<RiFileEditLine />}
                    onClick={() => {
                        setAddEdit(false);
                        dispatch(openCreateBookstoreModel());
                    }}>
                    تعديل
                </MenuItem>
                <MenuItem
                    onClick={() => dispatch(deleteBookstoreRequest(selected?.id))}
                    icon={<RiDeleteBin6Line />}>
                    حذف
                </MenuItem>
            </MenuList>
        </Menu>
    );
    const columns = [
        {
            Header: 'الاسم',
            accessor: 'owner'
        },
        {
            Header: 'العنوان',
            accessor: 'address'
        },
        {
            Header: 'الهاتف',
            accessor: 'phone'
        },
        {
            Header: 'النوع',
            accessor: 'type'
        },
        {
            Header: '',
            accessor: 'Actions'
        }
    ];

    const handelFilter = (e) => {
        console.log(e);
        setGovID(e);
    };
    const viewData = (data) => {
        setSelected(data);
    };

    const filterList = (
        <MenuOptionGroup type="radio" value={govID}>
            {governments.map((government) => {
                return (
                    <MenuItemOption
                        onClick={() => handelFilter(government?.id)}
                        value={government?.id}>
                        {government?.name}
                    </MenuItemOption>
                );
            })}
        </MenuOptionGroup>
    );
    return (
        <AuthWrapper roles={['Super Admin']}>
            <DashboardLayout title={' المكتبات'}>
                {step ? (
                    <GovList isLoading={isLoading} setStep={setStep} setGovID={setGovID} />
                ) : (
                    <>
                        <Button left="2" position={'fixed'} onClick={() => setStep(true)}>
                            الرجوع للمحافظات
                        </Button>
                        <CTable
                            selectedData={viewData}
                            Columns={columns}
                            Data={Data}
                            path={openCreateBookstoreModel}
                            Actions={actions}
                            Title=" "
                            subTitle={``}
                            btnTitle="hi"
                            placeHolder=""
                            noSearchBar={true}
                            noFilter={true}
                            footerBtnTitle
                            filterList
                            addButton
                            addButtonTitle="اضافة  "
                            setPage={setPage}
                            setPerPage={setPerPage}
                            currentpage={pageNumber}
                            setPageNumber={setPageNumber}
                            noSecondSearchBar={true}
                            isLoading={isLoading}
                            perPage={size}
                            totalPage={Math.ceil(count / 100)}
                            setAddEdit={setAddEdit}
                        />
                    </>
                )}
                <CreateBookstore data={selected} addEdit={addEdit} />
            </DashboardLayout>
        </AuthWrapper>
    );
};

export default Bookstores;
