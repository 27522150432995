import Types from './Types';

const INITIAL_STATE = {
    userData: {},
    isLoading: false
};

export default function lectures(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_USER_DATA_SUCCESS: {
            console.log(payload)
            return {
                ...state,
                userData: payload.data,
            };
        }
        case Types.USER_DATA_LOADING: {
            return {
                ...state,
                isLoading: payload,
            };
        }

        default: {
            return state;
        }
    }
}
