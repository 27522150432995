import React, { useEffect, useState } from 'react';
import { IoOptionsOutline } from 'react-icons/io5';
import { Menu, MenuButton, MenuList, Stack } from '@chakra-ui/react';

import {
    Box,
    Button,
    Flex,
    Heading,
    Input,
    InputGroup,
    InputLeftElement,
    Text
} from '@chakra-ui/react';
import { AiOutlineSearch } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { BiPlus } from 'react-icons/bi';

const CHeader = ({
    globalFilter,
    setGlobalFilter,
    noSecondSearchBar,
    Data,
    Title,
    subTitle,
    btnTitle,
    placeHolder,
    secondPlaceHolder,
    noFilter,
    noSearchBar,
    filterList,
    setSearch,
    selectedData,
    filterLength,
    path,
    addButton,
    addButtonTitle,
    setAddEdit,
    noSecondFilter,
    secondFilterList,
    secondFilterTitle
}) => {
    const dispatch = useDispatch();

    const [value, setValue] = useState(null);
    const [mobile, setMobile] = useState(null);
    useEffect(() => {
        const timeId = setTimeout(() => {
            if (value !== null) {
                setSearch({
                    name: value
                });
            }
        }, 500);

        return () => clearTimeout(timeId);
    }, [value]);

    useEffect(() => {
        const timeId = setTimeout(() => {
            if (mobile !== null) {
                setSearch({
                    mobile: mobile
                });
            }
        }, 500);

        return () => clearTimeout(timeId);
    }, [mobile]);
    const onChange = (value) => {};

    return (
        <Flex
            justify="space-between"
            position={'fixed'}
            width="fit-content"
            flexDirection={{ base: 'column', md: 'row' }}
            top="50px"
            left="3px"
            zIndex={'1000'}
            alignItems="end">
            <Stack flexDirection="column">
                {Title && (
                    <Heading as="h1" lineHeight="1.3" color="main_font" isTruncated size="lg">
                        {Title}
                    </Heading>
                )}
                {Data.length === 0 ? '' : <Text color="main_font">{subTitle}</Text>}
            </Stack>
            <Stack
                flexDirection={{ base: 'column', md: 'row' }}
                alignItems="center"
                mt="20px"
                style={{
                    gap: '10px'
                }}>
                {noSearchBar ? (
                    ''
                ) : (
                    <Box position="relative" zIndex="155555" mt="5px">
                        <InputGroup
                            bg="white"
                            style={{
                                borderRadius: '10px',
                                width: '270px'
                            }}>
                            <InputLeftElement
                                pointerEvents="none"
                                children={<AiOutlineSearch color="black" />}
                            />
                            <Input
                                type="text"
                                placeholder={placeHolder}
                                onChange={(e) => {
                                    setValue(e.target.value);
                                    onChange(e.target.value);
                                }}
                                color="black"
                                maxLength={255}
                                isLoading={true}
                            />
                        </InputGroup>
                    </Box>
                )}

                {noSecondSearchBar ? (
                    ''
                ) : (
                    <Box mr="4" my="10">
                        <InputGroup
                            bg="white"
                            style={{
                                borderRadius: '10px',
                                width: '270px'
                            }}>
                            <InputLeftElement
                                pointerEvents="none"
                                children={<AiOutlineSearch color="black" />}
                            />
                            <Input
                                type="tel"
                                placeholder={secondPlaceHolder}
                                onChange={(e) => {
                                    setMobile(e.target.value);
                                    onChange(e.target.value);
                                }}
                                color="black"
                                maxLength={255}
                                isLoading={true}
                            />
                        </InputGroup>
                    </Box>
                )}

                {noFilter ? (
                    ''
                ) : (
                    <Box
                        style={{
                            position: 'relative'
                        }}
                        bg="white"
                        borderRadius="8px">
                        <Menu closeOnSelect={false} flip={true} eventListeners={true} h="300px">
                            <MenuButton
                                as={Button}
                                leftIcon={<IoOptionsOutline />}
                                bg="white"
                                color="main_1"
                                pl="8"
                                pr="4"
                                py={2}
                                transition="all 0.2s"
                                borderRadius="md"
                                borderWidth="1px">
                                تنقية
                            </MenuButton>

                            <MenuList color="black" h="300px" overflowY={'scroll'}>
                                <>{filterList}</>
                            </MenuList>
                        </Menu>
                    </Box>
                )}

                {!noSecondFilter ? (
                    ''
                ) : (
                    <Box
                        mt="10"
                        mb="14"
                        mr="4"
                        style={{
                            position: 'relative'
                        }}
                        bg="white"
                        borderRadius="8px">
                        <Menu closeOnSelect={false}>
                            <MenuButton
                                as={Button}
                                leftIcon={<IoOptionsOutline />}
                                bg="white"
                                color="main_1"
                                pl="8"
                                pr="4"
                                py={2}
                                transition="all 0.2s"
                                borderRadius="md"
                                borderWidth="1px">
                                {secondFilterTitle}
                            </MenuButton>

                            <MenuList color="black">
                                <>{secondFilterList}</>
                            </MenuList>
                        </Menu>
                    </Box>
                )}

                <Box>
                    {addButton ? (
                        <Button
                            onClick={() => {
                                dispatch(path());
                                setAddEdit(true);
                            }}
                            bg="gradient_main_color"
                            color="white"
                            px={4}
                            py={2}
                            position="relative"
                            leftIcon={<BiPlus />}
                            transition="all 0.2s"
                            borderRadius="md"
                            borderWidth="1px">
                            {addButtonTitle}
                        </Button>
                    ) : (
                        ''
                    )}
                </Box>
            </Stack>
        </Flex>
    );
};

export default CHeader;
