import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllCenters({ payload }) {
    try {
        yield put(actions.addCenterLoading(true));
        const result = yield call(api.getAllCenters, payload);
        yield put(actions.getAllCentersSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        console.log(error, 'error');
        yield put(actions.getAllCentersFail());
    } finally {
        yield put(actions.addCenterLoading(false));
    }
}



//Edit Tables
function* editCenter(formData) {
    try {
        yield put(actions.addCenterLoading(true));
        const result = yield call(api.editCenters, formData.payload);
        yield put(actions.editCenterSuccess(result.data));
        toast.success('Edit Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.editCenterFail());
    } finally {
        yield put(actions.addCenterLoading(false));
    }
}

// create
function* createCenter(formData) {
    try {
        yield put(actions.addCenterLoading(true));
        const result = yield call(api.createCenters, formData.payload);
        yield put(
            actions.createCenterSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.createCenterFail());
    } finally {
        yield put(actions.addCenterLoading(false));
    }
}

function* deleteCenter({ payload }) {
    try {
        yield put(actions.addCenterLoading(true));
        const result = yield call(api.deleteCenter, payload);

        yield put(actions.deleteCenterSuccess(payload));

        toast.success('Deleted Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteCenterFail());
    } finally {
        yield put(actions.addCenterLoading(false));
    }
}

export default function* centersSaga() {
    yield takeLatest(Types.GET_CENTERS_REQUEST, getAllCenters);
    yield takeLatest(Types.EDIT_CENTER_REQUEST, editCenter);
    yield takeLatest(Types.CREATE_CENTER_REQUEST, createCenter);
    yield takeLatest(Types.DELETE_CENTER_REQUEST, deleteCenter);
}
