import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Flex,
    Text,
    Box,
    Button
} from '@chakra-ui/react';
import { closeCreateTeacherrModel } from '../../modules/models/Actions';
import { Formik, Field, Form } from 'formik';
import { getAllSubjectsRequest } from '../../modules/subjects/Actions';
import { getAllGradesRequest } from '../../modules/grades/Actions';
import { getAllGovernmentsRequest } from '../../modules/governments/Actions';
import { getAllAreaVillagesRequest } from '../../modules/areaVillage/Actions';
import { getAllSchoolsRequest } from '../../modules/schools/Actions';
import { createTeacherRequest, editTeacherRequest } from '../../modules/teachers/Actions';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import createTacherSchema from '../Schemas/createTeacher';
import { getAllTeacherTypesRequest } from '../../modules/teachers-types/Actions';
import { getMunicipalitytsByGovIDRequest } from '../../modules/municipality/Actions';

const CreateTeacher = ({ data, addEdit }) => {
    const model = useSelector((state) => state.model);
    const animatedComponents = makeAnimated();

    const dispatch = useDispatch();
    const governments = useSelector((state) => state.governments.governments);
    const grades = useSelector((state) => state.grades.grades);
    const subjects = useSelector((state) => state.subjects.subjects);
    const types = useSelector((state) => state.types.types);
    const municipalitys = useSelector((state) => state.municipalitys.municipalitys);
    const schools = useSelector((state) => state.schools.schools);
    const areaVillages = useSelector((state) => state.areaVillages.areaVillages);
    const isLoading = useSelector((state) => state.teachers.isLoading);
    const [govID, setGovID] = useState('');
    useEffect(() => {
        dispatch(getAllGovernmentsRequest());
        dispatch(getAllGradesRequest());
        dispatch(getAllTeacherTypesRequest());
        dispatch(getAllSubjectsRequest());
    }, []);

    useEffect(() => {
        if (data?.gov_id) setGovID(data?.gov_id);
    }, [data?.gov_id]);
    useEffect(() => {
        if (govID) {
            dispatch(getMunicipalitytsByGovIDRequest(govID));

            dispatch(getAllAreaVillagesRequest(govID));
            dispatch(getAllSchoolsRequest(govID));
        }
    }, [govID]);

    console.log('govID', govID);
    const SelectOptions = grades?.map((data) => {
        return {
            label: data?.name,
            value: data?.id
        };
    });
    const govOptions = governments?.map((data) => {
        return {
            label: data?.name,
            value: data?.id
        };
    });

    const areaVillagesOptions = areaVillages?.map((data) => {
        return {
            label: data?.name,
            value: data?.id
        };
    });
    const municipalitysOptions = municipalitys?.map((data) => {
        return {
            label: data?.name,
            value: data?.id
        };
    });

    const schoolOptions = schools?.map((data) => {
        return {
            label: data?.name,
            value: data?.id
        };
    });

    const typeOptions = types?.map((data) => {
        return {
            label: data?.name,
            value: data?.id
        };
    });

    const subjectOptions = subjects?.map((data) => {
        return {
            label: data?.name,
            value: data?.id
        };
    });
    return (
        <Modal
            isOpen={model.isOpen13}
            onClose={() => dispatch(closeCreateTeacherrModel())}
            size="2xl"
            closeOnOverlayClick={true}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader padding="15px" bg="main_2" color={'white'}>
                    {addEdit ? 'اضافة' : 'تعديل'}
                </ModalHeader>
                <ModalCloseButton
                    color={'white'}
                    right="90%"
                    onClick={() => dispatch(closeCreateTeacherrModel())}
                />
                <ModalBody>
                    <Formik
                        validationSchema={createTacherSchema}
                        initialValues={
                            !addEdit
                                ? { ...data }
                                : {
                                      name: '',
                                      type: '',
                                      gov_id: '',
                                      area_vil: '',
                                      phone: '',
                                      subject_id: '',
                                      grades: [],
                                      school_id: '',
                                      address: ''
                                  }
                        }
                        onSubmit={(values) => {
                            // if (!govID) {
                            //     toast.error('اسم المحافظة مطلوب')
                            //     return
                            // }

                            const gradesData = values?.grades?.map((data) => data?.value);
                            const subjectsData = values?.subjects?.map((data) => data?.value);
                            const action = () => {
                                dispatch(closeCreateTeacherrModel());
                            };
                            if (addEdit) {
                                dispatch(
                                    createTeacherRequest(
                                        { ...values, grades: gradesData, subjects: subjectsData },
                                        action
                                    )
                                );
                            } else {
                                dispatch(
                                    editTeacherRequest(
                                        {
                                            id: data?.id,
                                            formData: {
                                                name: values.name,
                                                whatsapp_number: values.whatsapp_number,
                                                type: values.type,
                                                gov_id: values.gov_id,
                                                mun_id: '3',
                                                area_vil: values.area_vil,
                                                phone: values.phone,
                                                subject_id: values.subject_id,
                                                grades: gradesData,
                                                school_id: values.school_id,
                                                address: values.address,
                                                subjects: subjectsData
                                            }
                                        },
                                        action
                                    )
                                );
                            }
                        }}>
                        {({ values, errors, touched, handleSubmit, setValues }) => (
                            <Form>
                                {console.log(values, 'values')}
                                <Flex flexDirection="column" justify="space-between" width={'100%'}>
                                    <Flex alignItems={'center'} justifyContent="space-between">
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width={'100%'}
                                            mb="20px">
                                            <label htmlFor="name" style={{ marginBlock: '10px' }}>
                                                الاسم
                                            </label>
                                            <Field
                                                id="name"
                                                name="name"
                                                placeholder=""
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #8080803b',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                            {errors.name ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Box>
                                    </Flex>

                                    <Flex alignItems={'center'} justifyContent="space-between">
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width={'45%'}
                                            mb="20px">
                                            <label htmlFor="phone" style={{ marginBlock: '10px' }}>
                                                رقم الموبيل
                                            </label>
                                            <Field
                                                id="phone"
                                                name="phone"
                                                placeholder=""
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #8080803b',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                            {errors.phone ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Box>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            width={'45%'}
                                            mb="20px">
                                            <label
                                                htmlFor="whatsapp_number"
                                                style={{ marginBlock: '10px' }}>
                                                رقم whatsApp
                                            </label>
                                            <Field
                                                id="whatsapp_number"
                                                name="whatsapp_number"
                                                placeholder=""
                                                style={{
                                                    width: '100%',
                                                    border: '1px solid #8080803b',
                                                    padding: '5px',
                                                    borderRadius: '5px'
                                                }}
                                            />
                                            {errors.whatsapp_number ? (
                                                <Text color="red">هذا الحقل مطلوب</Text>
                                            ) : null}
                                        </Box>
                                    </Flex>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        width={'100%'}
                                        mb="20px">
                                        <label htmlFor="address" style={{ marginBlock: '10px' }}>
                                            العنوان
                                        </label>
                                        <Field
                                            id="address"
                                            name="address"
                                            placeholder=""
                                            style={{
                                                width: '100%',
                                                border: '1px solid #8080803b',
                                                padding: '5px',
                                                borderRadius: '5px'
                                            }}
                                        />
                                        {errors.address ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Box>
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        width={'100%'}
                                        mb="20px">
                                        <label htmlFor="type" style={{ marginBlock: '10px' }}>
                                            النوع
                                        </label>
                                        <Select
                                            options={typeOptions}
                                            defaultValue={{
                                                value: values?.type,
                                                label: values?.type
                                            }}
                                            name="type"
                                            onChange={(val) => {
                                                setValues({ ...values, type: val?.value });
                                            }}
                                        />

                                        {errors.type ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Box>
                                    <Flex flexDirection="column" w="100%">
                                        <label htmlFor="gov_id" style={{ marginBlock: '10px' }}>
                                            المحافظة
                                        </label>
                                        <Select
                                            options={govOptions}
                                            defaultValue={{
                                                value: values?.gov_id,
                                                label: values?.gov_name
                                            }}
                                            name="gov_id"
                                            onChange={(val) => {
                                                setGovID(val?.value);
                                                setValues({ ...values, gov_id: val?.value });
                                            }}
                                        />

                                        {errors.gov_id ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>

                                    <Flex flexDirection="column">
                                        <label htmlFor="muni_id" style={{ marginBlock: '10px' }}>
                                            الادارة
                                        </label>
                                        <Select
                                            options={municipalitysOptions}
                                            defaultValue={{
                                                value: values?.muni_id,
                                                label: values?.muni_id_name
                                            }}
                                            name="muni_id"
                                            onChange={(val) =>
                                                setValues({ ...values, muni_id: val?.value })
                                            }
                                        />

                                        {errors.muni_id ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>

                                    <Flex flexDirection="column">
                                        <label htmlFor="area_vil" style={{ marginBlock: '10px' }}>
                                            القري\المدينة
                                        </label>
                                        <Select
                                            options={areaVillagesOptions}
                                            defaultValue={{
                                                value: values?.area_vil,
                                                label: values?.area_vil_name
                                            }}
                                            name="area_vil"
                                            onChange={(val) =>
                                                setValues({ ...values, area_vil: val?.value })
                                            }
                                        />

                                        {errors.area_vil ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>
                                    <Flex flexDirection="column">
                                        <label htmlFor="subjects" style={{ marginBlock: '10px' }}>
                                            المادة
                                        </label>
                                        {/* <Select options={subjectOptions} name='subject_id' onChange={(val) => setValues({ ...values, subject_id: val?.value })} /> */}

                                        <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            name="subjects"
                                            defaultValue={values?.subjects}
                                            // defaultValue={[colourOptions[4], colourOptions[5]]}
                                            isMulti
                                            options={subjectOptions}
                                            onChange={(val) =>
                                                setValues({ ...values, subjects: val })
                                            }
                                        />

                                        {errors.subjects ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>

                                    <Flex flexDirection="column">
                                        <label htmlFor="school_id" style={{ marginBlock: '10px' }}>
                                            المدرسة
                                        </label>
                                        <Select
                                            options={schoolOptions}
                                            defaultValue={{
                                                value: values?.school_id,
                                                label: values?.school_name
                                            }}
                                            name="school_id"
                                            onChange={(val) =>
                                                setValues({ ...values, school_id: val?.value })
                                            }
                                        />

                                        {errors.area_vil ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>
                                    <Flex flexDirection="column">
                                        <label htmlFor="grades" style={{ marginBlock: '10px' }}>
                                            السنة الدراسية
                                        </label>
                                        <Select
                                            closeMenuOnSelect={false}
                                            components={animatedComponents}
                                            name="grades"
                                            defaultValue={values?.grades}
                                            // defaultValue={[colourOptions[4], colourOptions[5]]}
                                            isMulti
                                            options={SelectOptions}
                                            onChange={(val) =>
                                                setValues({ ...values, grades: val })
                                            }
                                        />

                                        {errors.grades ? (
                                            <Text color="red">هذا الحقل مطلوب</Text>
                                        ) : null}
                                    </Flex>

                                    <Button
                                        mt="20px"
                                        w="25%"
                                        type="submit"
                                        style={{ marginBlock: '10px' }}
                                        bg="main_2"
                                        isLoading={isLoading}
                                        alignSelf={'flex-end'}
                                        color="white">
                                        حفظ التغيرات
                                    </Button>
                                </Flex>
                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default CreateTeacher;
