export default {
    //get all users
    GET_SCHOOLS_REQUEST: 'GET_SCHOOLS_REQUEST',
    GET_SCHOOLS_SUCCESS: 'GET_SCHOOLS_SUCCESS',
    GET_SCHOOLS_FAIL: 'GET_SCHOOLS_FAIL',

    //edit users
    EDIT_SCHOOL_REQUEST: 'EDIT_SCHOOL_REQUEST',
    EDIT_SCHOOL_SUCCESS: 'EDIT_SCHOOL_SUCCESS',
    EDIT_SCHOOL_FAIL: 'EDIT_SCHOOL_FAIL',

    //create user
    CREATE_SCHOOL_REQUEST: 'CREATE_SCHOOL_REQUEST',
    CREATE_SCHOOL_SUCCESS: 'CREATE_SCHOOL_SUCCESS',
    CREATE_SCHOOL_FAIL: 'CREATE_SCHOOL_FAIL',

    DELETE_SCHOOL_REQUEST: 'DELETE_SCHOOL_REQUEST',
    DELETE_SCHOOL_SUCCESS: 'DELETE_SCHOOL_SUCCESS',
    DELETE_SCHOOL_FAIL: 'DELETE_SCHOOL_FAIL',

    GET_SCHOOL_REQUEST: 'GET_SCHOOL_REQUEST',
    GET_SCHOOL_SUCCESS: 'GET_SCHOOL_SUCCESS',
    GET_SCHOOL_FAIL: 'GET_SCHOOL_FAIL',

    //loading
    ADD_SCHOOLS_LOADING: 'ADD_SCHOOLS_LOADING'
};
