import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllBooks({ payload }) {
    try {
        yield put(actions.addBookLoading(true));
        const result = yield call(api.getAllBooks, payload);
        yield put(actions.getAllBooksSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        console.log(error, 'error');
        yield put(actions.getAllBooksFail());
    } finally {
        yield put(actions.addBookLoading(false));
    }
}

//Edit Tables
function* editBook(formData) {
    try {
        yield put(actions.addBookLoading(true));
        const result = yield call(api.editBooks, formData.payload);
        yield put(actions.editBookSuccess(result.data));
        toast.success('Edit Successfully');
        formData?.action && formData?.action();
    } catch (error) {
        yield put(actions.editBookFail());
    } finally {
        yield put(actions.addBookLoading(false));
    }
}

// create
function* createBook(formData) {
    try {
        yield put(actions.addBookLoading(true));
        const result = yield call(api.createBooks, formData.payload);
        yield put(
            actions.createBookSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action();
    } catch (error) {
        const msg = Object.keys(error.response.data.message).join(',');
        toast.error(`${msg} is required`);
        yield put(actions.createBookFail());
    } finally {
        yield put(actions.addBookLoading(false));
    }
}

function* deleteBook({ payload }) {
    try {
        yield put(actions.addBookLoading(true));
        const result = yield call(api.deleteBook, payload);

        yield put(actions.deleteBookSuccess(payload));

        toast.success('Deleted Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteBookFail());
    } finally {
        yield put(actions.addBookLoading(false));
    }
}

export default function* bookSaga() {
    yield takeLatest(Types.GET_BOOKS_REQUEST, getAllBooks);
    yield takeLatest(Types.EDIT_BOOK_REQUEST, editBook);
    yield takeLatest(Types.CREATE_BOOK_REQUEST, createBook);
    yield takeLatest(Types.DELETE_BOOK_REQUEST, deleteBook);
}
