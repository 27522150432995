import Types from './Types';


export const getAllGovernmentsRequest = (body) => ({
    type: Types.GET_GOVERNMENTS_REQUEST,
    payload: body
});

export const getAllGovernmentsSuccess = (data) => ({
    type: Types.GET_GOVERNMENTS_SUCCESS,
    payload: data
});

export const getAllGovernmentsFail = () => ({
    type: Types.GET_GOVERNMENTS_FAIL
});


export const editGovernmentRequest = (formData, action) => ({
    type: Types.EDIT_GOVERNMENT_REQUEST,
    payload: formData,
    action: action
});

export const editGovernmentSuccess = (data) => ({
    type: Types.EDIT_GOVERNMENT_SUCCESS,
    payload: data
});

export const editGovernmentFail = () => ({
    type: Types.EDIT_GOVERNMENT_FAIL
});

//LOADING
export const addGovernmentLoading = (isLoading) => ({
    type: Types.ADD_GOVERNMENTS_LOADING,
    payload: isLoading
});

// Create Government
export const createGovernmentRequest = (formData, action) => ({
    type: Types.CREATE_GOVERNMENT_REQUEST,
    payload: { ...formData },
    action: action
});

export const createGovernmentSuccess = ({ data }) => ({
    type: Types.CREATE_GOVERNMENT_SUCCESS,
    payload: {
        data
    }
});

export const createGovernmentFail = () => ({
    type: Types.CREATE_GOVERNMENT_FAIL
});

export const deleteGovernmentRequest = (id) => ({
    type: Types.DELETE_GOVERNMENT_REQUEST,
    payload: id
});

export const deleteGovernmentSuccess = (id) => ({
    type: Types.DELETE_GOVERNMENT_SUCCESS,
    payload: id
});

export const deleteGovernmentFail = () => ({
    type: Types.DELETE_GOVERNMENT_FAIL
});
