export default {
    //get all AUTHORs
    GET_AUTHORS_REQUEST: 'GET_AUTHORS_REQUEST',
    GET_AUTHORS_SUCCESS: 'GET_AUTHORS_SUCCESS',
    GET_AUTHORS_FAIL: 'GET_AUTHORS_FAIL',

    //edit AUTHORs
    EDIT_AUTHOR_REQUEST: 'EDIT_AUTHOR_REQUEST',
    EDIT_AUTHOR_SUCCESS: 'EDIT_AUTHOR_SUCCESS',
    EDIT_AUTHOR_FAIL: 'EDIT_AUTHOR_FAIL',

    //create AUTHOR
    CREATE_AUTHOR_REQUEST: 'CREATE_AUTHOR_REQUEST',
    CREATE_AUTHOR_SUCCESS: 'CREATE_AUTHOR_SUCCESS',
    CREATE_AUTHOR_FAIL: 'CREATE_AUTHOR_FAIL',

    DELETE_AUTHOR_REQUEST: 'DELETE_AUTHOR_REQUEST',
    DELETE_AUTHOR_SUCCESS: 'DELETE_AUTHOR_SUCCESS',
    DELETE_AUTHOR_FAIL: 'DELETE_AUTHOR_FAIL',

    GET_AUTHOR_REQUEST: 'GET_AUTHOR_REQUEST',
    GET_AUTHOR_SUCCESS: 'GET_AUTHOR_SUCCESS',
    GET_AUTHOR_FAIL: 'GET_AUTHOR_FAIL',

    //loading
    ADD_AUTHORS_LOADING: 'ADD_AUTHORS_LOADING'
};
