import Types from './Types';

const INITIAL_STATE = {
    governments: [],
    government: {},
    isLoading: false,
    count: ''
};

export default function governments(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_GOVERNMENTS_SUCCESS: {
            return {
                ...state,
                governments: payload.data,
                count: payload.data.length
            };
        }

        case Types.GET_GOVERNMENT_SUCCESS: {
            return {
                ...state,
                government: payload
            };
        }

        case Types.EDIT_GOVERNMENT_SUCCESS: {
            let data = payload.data;
            const governments = state.governments;
            let idx = 0;
            governments.forEach((table, index) => {
                if (table.id === data.id) {
                    idx = index;
                    return;
                }
            });

            governments[idx] = data;
            return {
                ...state,
                governments: governments
            };
        }

        // create
        case Types.CREATE_GOVERNMENT_SUCCESS: {
            const data = payload.data.data;
            let newArray = state.governments.slice();
            let index = state.governments.length + 1;
            newArray.splice(index, 0, data);
            return {
                ...state,
                governments: newArray
            };
        }
        case Types.ADD_GOVERNMENTS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        case Types.DELETE_GOVERNMENT_SUCCESS: {
            const newGovernments = state.governments.filter((item) => item.id !== payload);
            return {
                ...state,
                governments: newGovernments
            };
        }
        default: {
            return state;
        }
    }
}
