import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllPlans({ payload }) {
    try {
        yield put(actions.addPlanLoading(true));
        const result = yield call(api.getAllPlans, payload);
        yield put(actions.getAllPlansSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        console.log(error, 'error');
        yield put(actions.getAllPlansFail());
    } finally {
        yield put(actions.addPlanLoading(false));
    }
}

function* getPlans({ payload }) {
    try {
        yield put(actions.addPlanLoading(true));
        const result = yield call(api.getPlans, payload);
        yield put(actions.getPlansSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        console.log(error, 'error');
        yield put(actions.getPlansFail());
    } finally {
        yield put(actions.addPlanLoading(false));
    }
}

//Edit Tables
function* editPlan(formData) {
    try {
        yield put(actions.addPlanLoading(true));
        const result = yield call(api.editPlans, formData.payload);
        yield put(actions.editPlanSuccess(result.data));
        toast.success('Edit Successfully');
        formData?.action && formData?.action();
    } catch (error) {
        yield put(actions.editPlanFail());
    } finally {
        yield put(actions.addPlanLoading(false));
    }
}

function* AddPlan(formData) {
    try {
        yield put(actions.addPlanLoading(true));
        const result = yield call(api.addPlan, formData.payload);

        toast.success('Edit Successfully');
        formData?.action && formData?.action();
    } catch (error) {
    } finally {
        yield put(actions.addPlanLoading(false));
    }
}
// create
function* createPlan(formData) {
    try {
        yield put(actions.addPlanLoading(true));
        const result = yield call(api.createPlans, formData.payload);
        yield put(
            actions.createPlanSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action();
    } catch (error) {
        yield put(actions.createPlanFail());
    } finally {
        yield put(actions.addPlanLoading(false));
    }
}

function* deletePlan({ payload }) {
    try {
        yield put(actions.addPlanLoading(true));
        const result = yield call(api.deletePlan, payload);

        yield put(actions.deletePlanSuccess(payload));

        toast.success('Deleted Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deletePlanFail());
    } finally {
        yield put(actions.addPlanLoading(false));
    }
}

export default function* PlanSaga() {
    yield takeLatest(Types.GET_PLANS_REQUEST, getAllPlans);
    yield takeLatest(Types.GET_PLANS_DATA_REQUEST, getPlans);

    yield takeLatest(Types.EDIT_PLAN_REQUEST, editPlan);
    yield takeLatest(Types.CREATE_PLAN_REQUEST, createPlan);
    yield takeLatest(Types.DELETE_PLAN_REQUEST, deletePlan);
    yield takeLatest(Types.ADD_PLAN_REQUEST, AddPlan);
}
