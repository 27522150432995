import Types from './Types';


export const getAllAreaVillagesRequest = (formdata) => ({
    type: Types.GET_AREAVILLAGES_REQUEST,
    payload: formdata,
});

export const getAllAreaVillagesSuccess = (data) => ({
    type: Types.GET_AREAVILLAGES_SUCCESS,
    payload: data
});

export const getAllAreaVillagesFail = () => ({
    type: Types.GET_AREAVILLAGES_FAIL
});


export const editAreaVillageRequest = (formData, action) => ({
    type: Types.EDIT_AREAVILLAGE_REQUEST,
    payload: formData,
    action: action
});

export const editAreaVillageSuccess = (data) => ({
    type: Types.EDIT_AREAVILLAGE_SUCCESS,
    payload: data
});

export const editAreaVillageFail = () => ({
    type: Types.EDIT_AREAVILLAGE_FAIL
});

//LOADING
export const addAreaVillageLoading = (isLoading) => ({
    type: Types.ADD_AREAVILLAGES_LOADING,
    payload: isLoading
});

// Create AreaVillage
export const createAreaVillageRequest = (formData, action) => ({
    type: Types.CREATE_AREAVILLAGE_REQUEST,
    payload: { ...formData },
    action: action
});

export const createAreaVillageSuccess = ({ data }) => ({
    type: Types.CREATE_AREAVILLAGE_SUCCESS,
    payload: {
        data
    }
});

export const createAreaVillageFail = () => ({
    type: Types.CREATE_AREAVILLAGE_FAIL
});

export const deleteAreaVillageRequest = (id) => ({
    type: Types.DELETE_AREAVILLAGE_REQUEST,
    payload: id
});

export const deleteAreaVillageSuccess = (id) => ({
    type: Types.DELETE_AREAVILLAGE_SUCCESS,
    payload: id
});

export const deleteAreaVillageFail = () => ({
    type: Types.DELETE_AREAVILLAGE_FAIL
});
