import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import DashboardLayout from '../../components/Layout/DashboardLayout';
import { openTeacherTypeModel } from '../../modules/models/Actions';
import { useSelector } from 'react-redux';
import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import CTable from '../../components/table/table';
import { HiDotsVertical } from 'react-icons/hi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import AuthWrapper from '../../components/Auth/AuthWrapper';
import {
    deleteTeacherTypesRequest,
    getAllTeacherTypesRequest
} from '../../modules/teachers-types/Actions';
import CreateTeacherType from '../../components/models/createTeacherType';

const TeacherType = () => {
    const dispatch = useDispatch();
    const [addEdit, setAddEdit] = useState(true);
    const types = useSelector((state) => state.types.types);
    const isLoading = useSelector((state) => state.types.isLoading);
    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [selected, setSelected] = useState({
        id: ''
    });

    useEffect(() => {
        dispatch(getAllTeacherTypesRequest());
    }, []);
    const Data = types?.map((data) => {
        return {
            ...data,
            id: data?.id,
            name: data?.name
        };
    });

    const actions = (
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<HiDotsVertical />}
                size="sm"
                fontSize="20px"
                variant="outline"
                border="none"
            />
            <MenuList>
                <MenuItem
                    onClick={() => dispatch(deleteTeacherTypesRequest(selected?.id))}
                    icon={<RiDeleteBin6Line />}>
                    حذف
                </MenuItem>
            </MenuList>
        </Menu>
    );
    const columns = [
        {
            Header: 'النوع',
            accessor: 'name'
        },

        {
            Header: '',
            accessor: 'Actions'
        }
    ];

    const viewData = (data) => {
        setSelected(data);
    };

    return (
        <AuthWrapper roles={['Super Admin']}>
            <DashboardLayout title={' انواع المدرسين'}>
                <CTable
                    selectedData={viewData}
                    Columns={columns}
                    Data={Data}
                    path={openTeacherTypeModel}
                    Actions={actions}
                    Title=" "
                    subTitle={``}
                    btnTitle="hi"
                    placeHolder=""
                    noSearchBar={true}
                    noFilter={true}
                    footerBtnTitle
                    filterList
                    addButton
                    addButtonTitle="اضافة"
                    setPage={setPage}
                    setPerPage={setPerPage}
                    currentpage={pageNumber}
                    setPageNumber={setPageNumber}
                    noSecondSearchBar={true}
                    isLoading={isLoading}
                    perPage={size}
                    totalPage={Math.ceil(types?.length / 10)}
                    setAddEdit={setAddEdit}
                />

                <CreateTeacherType data={selected} addEdit={addEdit} />
            </DashboardLayout>
        </AuthWrapper>
    );
};

export default TeacherType;
